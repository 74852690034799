import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Reason } from '../../../models/close-reason.model';
import { GlobalService } from '../../../services/global.service';
import { LanguageModel } from '../../../models/language.model';
import { concat, from, of, Subject, Subscription } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { EnrichmentModel } from 'src/app/models/enrichment.model';
import { PaginationService } from 'src/app/services/pagination.service';
import { ApiService } from 'src/app/services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { catchError, finalize } from 'rxjs/operators';
import { AnalysisModel } from 'src/app/models/analysis.model';
import { HttpEventType } from '@angular/common/http';
enum ModalType {
  analysis,
  document
}
@Component({
  selector: 'app-create-analysis',
  templateUrl: './create-analysis.pages.html',
  styleUrls: ['./create-analysis.pages.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CreateAnalysisPages implements OnInit, OnDestroy {
  @Input() settings: any;
  @ViewChild('createAnalysis') form: NgForm;

  public ModalType = ModalType;
  private onItemAdded = new Subject<AnalysisModel>();
  public modalType: ModalType;
  public options = true;
  public languageList: LanguageModel[] = [];
  public dataModelList: any = [];
  public reason = Reason;
  public loading: boolean;
  public filesList: Array<File> = [];
  public filesEnri: File;
  public showWarning: boolean = false;
  public enrichmentValidationCategory = '0';
  public headProgressCurrentValue = 0;
  public headProgressTotalValue = 0;
  public sendFilesGroup = 0;
  public prevSendFilesGroup = 0;
  public totalDocs = 0;
  public countRequest = 0;
  public failures = 0;
  public enrichmentTypesList: EnrichmentModel[] = [];
  public subscriptionEnrichmentTypes: Subscription;
  public model: any;
  private subscriptionDataModels: Subscription;
  private subscriptionLanguages: Subscription;
  public includeFiles: boolean = true;
  public includeEnri: boolean = false;
  public loadingdocs: boolean = false;
  public finishedprocess: boolean = false;
  public reUpload: boolean = false;
  public completed = '0%';
  public filesProgress: Array<{ value: number; status: boolean }> = [];

  public formValues = {
    analysisName: '',
    datamodelId: '',
    language: '',
    files: [],
    enrichment: new File([], ''),
    enrichmentTypeid: ''
  };
  public type: number = 0;

  FILE_FORMATS =
    '.csv,image/gif,image/jpeg,image/tiff,image/x-tiff,image/png,.pdf,.msg,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xml,.xlam,.xla,.xlw,.xlr,.txt,.json,.doc,.docx,.ppt,.pptx';
  FILE_FORMATS_ENRI =
    '.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.binary.macroEnabled.12,plain/text,.txt,.json';

  constructor(
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslatePipe,
    private alertService: AlertService,
    public apiService: ApiService,
    private paginationService: PaginationService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.modalType =
      this.settings.analysisname === ''
        ? ModalType.analysis
        : ModalType.document;
    if (this.modalType === ModalType.document) {
      this.includeFiles = true;
    }
    this.getDropDownSources();
  }

  public showDocumentList() {
    let element = document.getElementById('panel');

    if (element.style.display == 'none') element.style.display = 'block';
    else element.style.display = 'none';
  }

  /**
   * fetch datamodel and languages list
   */
  public getDropDownSources() {
    this.dataModelList = Array.from(
      this.globalService.getDatamodels().values()
    );
    this.languageList = Array.from(this.globalService.getLanguages().values());
    this.enrichmentTypesList = Array.from(
      this.globalService.getEnrichmentTypes().values()
    ).filter(
      type =>
        type.enrichment_category == parseInt(this.enrichmentValidationCategory)
    );

    if (
      this.dataModelList.length === 0 &&
      this.languageList.length === 0 &&
      this.enrichmentTypesList.length === 0 &&
      !this.globalService.passedWatcherDatamodels &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionDataModels = concat(
        this.globalService.watchDataModels(),
        this.globalService.watchUtils()
      ).subscribe((data: string) => {
        this.dataModelList = Array.from(
          this.globalService.getDatamodels().values()
        );
        this.languageList = Array.from(
          this.globalService.getLanguages().values()
        );
        this.enrichmentTypesList = Array.from(
          this.globalService.getEnrichmentTypes().values()
        ).filter(
          type =>
            type.enrichment_category === +this.enrichmentValidationCategory
        );
        this.loading = false;
      });
    } else if (
      this.dataModelList.length === 0 &&
      !this.globalService.passedWatcherDatamodels
    ) {
      this.subscriptionDataModels = this.globalService
        .watchDataModels()
        .subscribe((data: string) => {
          this.dataModelList = Array.from(
            this.globalService.getDatamodels().values()
          );
          this.loading = false;
        });
    } else if (
      this.languageList.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionLanguages = this.globalService
        .watchUtils()
        .subscribe((data: string) => {
          this.languageList = Array.from(
            this.globalService.getLanguages().values()
          );
          this.loading = false;
        });
    } else if (
      this.enrichmentTypesList.length === 0 &&
      !this.globalService.passedWatcherUtils
    ) {
      this.subscriptionLanguages = this.globalService
        .watchUtils()
        .subscribe((data: string) => {
          this.enrichmentTypesList = Array.from(
            this.globalService.getEnrichmentTypes().values()
          ).filter(
            type =>
              type.enrichment_category === +this.enrichmentValidationCategory
          );
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
  public filterListOfTypes(newValue) {
    this.enrichmentTypesList = Array.from(
      this.globalService.getEnrichmentTypes().values()
    ).filter(type => type.enrichment_category === +newValue);
    this.type = 0;
  }

  /**
   * saves new analysis to db
   */
  public onCreateAnalysis(form: NgForm) {
    this.formValues = {
      analysisName: form.value.analysisname,
      datamodelId: form.value.datamodel,
      language: form.value.language,
      files: this.filesList,
      enrichment: this.filesEnri,
      enrichmentTypeid: form.value.enrichmentType
    };
    if (this.modalType === ModalType.analysis && !this.includeFiles) {
      this.activeModal.close(this.formValues);
    }
    if (this.settings.level == 'analysis') {
      this.onProcessDocs();
    }
    if (this.settings.level == 'project' && this.includeFiles) {
      this.onCreateAnalysisProject();
    }
  }

  public onCreateAnalysisProject() {
    this.loadingdocs = true;
    this.finishedprocess = false;
    const analysisParams = {
      projectid: this.settings.projectid,
      analysisname: this.formValues.analysisName
    };
    this.apiService
      .post('analysis', analysisParams, 'createAnalysis.analysisCreated', false)
      .subscribe(
        (analysis: AnalysisModel) => {
          this.onItemAdded.next();
          this.paginationService.changePagination('analysis', 'page', 1);
          if (this.formValues.files?.length > 0) {
            this.getInitialValue();
            this.settings.analysisid = analysis.analysisid;
            this.getDocumentCreateRequests(
              this.formValues,
              analysis.analysisid
            );
          }
        },
        error => {
          this.loadingdocs = false;
          this.finishedprocess = true;
        }
      );
  }

  private getInitialValue() {
    this.totalDocs = this.formValues.files.length;
    this.headProgressTotalValue = this.totalDocs * 100;
    this.filesProgress = Array.from({ length: this.totalDocs }, () => ({
      value: 0,
      status: null
    }));
    this.sendFilesGroup = 0;
    this.prevSendFilesGroup;
    this.headProgressCurrentValue = 0;
    this.loadingdocs = true;
    this.countRequest = 0;
    this.failures = 0;
    this.finishedprocess = false;
  }

  private reUploadFile(index: number) {
    this.sendFilesGroup = index;
    this.finishedprocess = false;
    this.reUpload = true;
    this.loadingdocs = true;
    this.countRequest--;
    this.failures--;
    this.filesProgress[index].status = null;
    this.filesProgress[index].value = 0;
    this.formValues.files.push(this.filesList[index]);
    this.getDocumentCreateRequests(this.formValues, this.settings.analysisid);
  }
  private getDocumentCreateRequests(formValues: any, analysisid) {
    let promises = [];
    let filesubgrouped = this.divideElements();
    for (const file of filesubgrouped[0]) {
      let formData = this.getForm(
        this.settings.projectid.toString(),
        analysisid,
        formValues,
        file
      );
      if (this.formValues.enrichment) {
        formData.append(
          'enrichment',
          this.formValues.enrichment,
          this.formValues.enrichment.name
        );
        formData.append(
          'additionaldocumenttypeid',
          this.formValues.enrichmentTypeid
        );
      }
      promises.push(
        this.apiService.post('documents', formData, '', false, {
          reportProgress: true,
          observe: 'events'
        })
      );
    }
    this.prevSendFilesGroup = this.sendFilesGroup;
    this.sendFilesGroup = this.sendFilesGroup + filesubgrouped[0].length;

    promises.map((httpReq, index) => {
      httpReq
        .pipe(catchError(() => of(undefined)))
        .subscribe(event => {
          if (event === undefined) {
            this.failures++;
          } else {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                this.filesProgress[this.prevSendFilesGroup + index].value =
                  Math.round((event.loaded / event.total) * 75);
                this.completed = this.getPercentage() + '%';
                break;
              case HttpEventType.Response:
                if (event.status == 201)
                  this.filesProgress[this.prevSendFilesGroup + index].status =
                    true;

                this.filesProgress[this.prevSendFilesGroup + index].value = 100;
                this.completed = this.getPercentage() + '%';
                break;
            }
          }
        })
        .add(
          () => {
            if (!this.filesProgress[this.prevSendFilesGroup + index].status)
              this.filesProgress[this.prevSendFilesGroup + index].status =
                false;
            this.countRequest++;
            if (this.countRequest == this.totalDocs) {
              let fileArray = Array.from(this.formValues.files);
              fileArray.splice(0, filesubgrouped[0].length);
              this.formValues.files = fileArray;
              this.headProgressCurrentValue = this.headProgressTotalValue;
              this.finishedprocess = true;
              this.loadingdocs = false;
              this.completed = '100%';
              this.filesEnri = null;

              if (
                (this.failures == 0 && !this.reUpload) ||
                (this.reUpload &&
                  this.filesProgress[this.prevSendFilesGroup + index].status ===
                    true)
              ) {
                this.alertService.success(
                  this.translate.transform('analysisDetails.successfulUpload'),
                  false
                );
                this.reUpload = false;
              } else {
                this.alertService.error(
                  this.translate.transform(
                    'analysisDetails.unsuccessfulUpload'
                  ),
                  false
                );
              }
            } else if (this.countRequest == this.sendFilesGroup) {
              let fileArray = Array.from(this.formValues.files);
              fileArray.splice(0, filesubgrouped[0].length);
              this.formValues.files = fileArray;
              this.getDocumentCreateRequests(this.formValues, analysisid);
            }
          },
          catchError(() => of(undefined))
        );
    });
  }

  /**
   *
   * @returns The title of the pause/resume button
   */
  public getButtonTitle(): string {
    if (this.loadingdocs && !this.finishedprocess) {
      return this.translate.transform('createAnalysis.closeButton');
    } else {
      return '';
    }
  }

  public getPercentage() {
    this.headProgressCurrentValue = this.filesProgress.reduce(
      (prev, cur) => prev + cur.value,
      0
    );
    return Math.round(
      (this.headProgressCurrentValue / this.headProgressTotalValue) * 100
    );
  }

  getForm(projectId, analysisId, formValues, file) {
    let formData = new FormData();
    formData.append('projectid', projectId);
    formData.append('analysisid', analysisId);
    formData.append('analysisname', formValues.analysisName);
    formData.append('datamodelid', formValues.datamodelId);
    formData.append('language', formValues.language);
    formData.append('files', file, file.name);
    return formData;
  }

  public divideElements() {
    let arraygrouped = [];
    let subarray = [];
    let subgroupmax = 1;
    if (this.formValues.files.length > 4) {
      subgroupmax = 5;
    }
    for (const file of this.formValues.files) {
      if (subarray.length == subgroupmax) {
        arraygrouped.push(subarray);
        subarray = [];
        subarray.push(file);
      } else {
        subarray.push(file);
      }
    }
    arraygrouped.push(subarray);
    return arraygrouped;
  }

  /**
   * Document upload handler
   */
  public onProcessDocs() {
    if (
      this.formValues &&
      this.formValues.files &&
      this.formValues.files.length > 0
    ) {
      this.getInitialValue();
      this.getDocumentCreateRequests(this.formValues, this.settings.analysisid);
    }
  }

  /**
   * handle file upload input change
   * @param event uploadevent
   */
  public onChange(event: any) {
    this.filesList = <Array<File>>event.target.files;
    this.filesProgress = Array.from({ length: this.filesList.length }, () => ({
      value: 0,
      status: null
    }));
    this.showDocumentList();
  }

  /**
   * handle file upload input change
   * @param event uploadevent
   */
  public onChangeEnri(event: any) {
    this.filesEnri = <File>event.target.files[0];
  }

  /**
   * Triggered when the include files checkbox is modified
   */
  public onIncludeFiles(): void {
    if (!this.includeFiles) {
      this.filesList = [];
    }
    this.changeDetector.detectChanges();
  }

  /**
   * Triggered when the include files checkbox is modified
   */
  public onIncludeEnri(): void {
    if (!this.includeEnri) {
      this.filesEnri = null;
    }
    this.changeDetector.detectChanges();
  }

  public deleteFile(index: number) {
    const files = Array.from(this.filesList);
    files.splice(index, 1);
    this.filesList = <Array<File>>files;
  }

  public deleteFileEnri(index: number) {
    this.filesEnri = null;
  }
  /**
   * Checks if the user can submit depending on the case
   */
  public isSubmitDisabled(isFormInvalid: boolean): boolean {
    if (isFormInvalid) {
      return true;
    }
    if (this.loadingdocs && !this.finishedprocess) {
      return true;
    }
    switch (this.modalType) {
      case ModalType.document:
        return this.filesList.length === 0;
      case ModalType.analysis:
        return this.includeFiles ? this.filesList.length === 0 : false;
    }
  }
  /**
   *
   * @returns The title of the submit button depending
   * on the modal type and if the files are included
   */
  public getSubmitButtonTitle(): string {
    let key: string = '';
    switch (this.modalType) {
      case ModalType.document:
        key = 'createAnalysis.runDocument';
        break;
      case ModalType.analysis:
        key = this.includeFiles
          ? 'createAnalysis.runAnalysis'
          : 'createAnalysis.createAnalysis';
    }
    return this.translate.transform(key);
  }

  public ngOnDestroy() {
    this.subscriptionDataModels?.unsubscribe();
    this.subscriptionLanguages?.unsubscribe();
  }
}
