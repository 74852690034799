import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbModel } from 'src/app/models/breadcrumb.model';
import { LinkService } from 'src/app/services/link.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { DocumentModel } from 'src/app/models/document.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  public showValidation: boolean = false;
  public breadcrumbs: BreadcrumbModel[] = [];
  private dataSub: Subscription;
  public user: any;
  private datamodelsChildPaths: string[] = [
    'new-validation',
    'next-step',
    'edit',
    'datamodels-details'
  ];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public global: GlobalService,
    public link: LinkService,
    private authService: AuthenticationService
  ) {
    this.user = this.authService.getLoggedInUser();
    this.dataSub = this.route.params.subscribe((params: Params) => {
      this.parseRoute(params);
    });

    this.showValidation = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
  }

  /**
   * Parse route to breadcrumb
   * Constructs a breadcrmbs array (this.breadcrumbs) based on
   * params used in route
   */
  private parseRoute(p: Params) {
    // Navigating in query/admin/datamodels..
    this.route.data.subscribe((data: { name: string; path: string }) => {
      if (data.path && data.name) {
        // Navigating in datamodels
        if (this.datamodelsChildPaths.includes(data.path)) {
          this.addBreadcrumb({
            order: 0,
            type: '',
            name: 'Datamodels & Validations',
            route: ['datamodels-validations']
          });
          this.addBreadcrumb({
            order: 1,
            type: '',
            name: data.name,
            route: ['datamodels-validations', data.path]
          });
        } else {
          // General navigation
          this.addBreadcrumb({
            order: 0,
            type: '',
            name: data.name,
            route: [data.path]
          });
        }
      }
    });

    // Navigating in projects
    if (p.projectidname) {
      this.global
        .getProject(this.link.getIdFromSlug(p.projectidname))
        .then((d: any) => {
          // Navigating on project detail
          this.addBreadcrumb({
            order: 1,
            type: 'Project',
            name: d.projectname,
            route: ['projects', p.projectidname]
          });
        });
      // Navigating on analysis detail
      if (p.analysisidname) {
        this.global
          .getAnalysis(this.link.getIdFromSlug(p.analysisidname))
          .then((d: any) => {
            this.addBreadcrumb({
              order: 2,
              type: 'Analysis',
              name: d.analysisname,
              route: ['projects', p.projectidname, 'analysis', p.analysisidname]
            });
          });
      }
      // Navigating on document detail, push document on breadcrumbs
      // .../documents/12-document (doc does not have parent) or
      // .../documents/12-parent-document/documents/34-child-document (doc has parent)
      if (p.documentidname) {
        this.global
          .getDocument(this.link.getIdFromSlug(p.documentidname))
          .then((d: DocumentModel) => {
            const ischild: boolean = d.parentdocumentid !== undefined;
            this.addBreadcrumb({
              order: 3,
              type: 'Document',
              name: d.documentdisplayname,
              route: [
                'projects',
                p.projectidname,
                'analysis',
                p.analysisidname,
                'documents',
                ischild ? d.parentdocumentid : p.documentidname
              ]
            });
            // Doc has parent
            if (ischild) {
              this.addBreadcrumb({
                order: 4,
                type: 'Document',
                name: d.documentdisplayname,
                route: [
                  'projects',
                  p.projectidname,
                  'analysis',
                  p.analysisidname,
                  'documents',
                  d.parentdocumentid,
                  'documents',
                  p.documentidname
                ]
              });
            }
          });
      }
    }

    // Navigating in querysheet management screens
    if (p.querysheetidname) {
      // Navigating on querysheet detail
      this.addBreadcrumb({
        order: 1,
        type: '',
        name: 'Query Sheet Details',
        route: ['querysheet-management', p.querysheetidname]
      });
    }
  }

  /**
   * On destroy handler
   */
  ngOnDestroy() {
    // Unsubscribe to route change
    this.dataSub.unsubscribe();
  }

  private addBreadcrumb(breadcrumb) {
    const tenantName = this.authService.getTenantName();
    breadcrumb.route.unshift(tenantName);
    this.breadcrumbs.push(breadcrumb);
    this.breadcrumbs.sort((a, b) => a.order - b.order);
  }
}
