import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
declare var pdfjsLib: any;
declare var pdfjsViewer: any;
import {
  highlightFeedbackText,
  removeInvalidFeedbackBox,
  showHideEventOnButtonsAtPage,
  findAndHighlightText,
  enableDisableEditButtons,
  setEventListeners,
  addButtonsOnRect,
  getBoundingBox,
  createEditButton,
  showHideButtonEventOnSelectionArea,
  findManualFeedback,
  isEmpty,
  disableScrollOnViewerContainer,
  getManualFeedbackFromSelectedResults,
  getCurrentBoxObject,
  getSelectionObject,
  getSelectionObjToUpdateManualFeedback,
  setLablesAndMessage,
  getConstantFactor,
  getScaledCursor,
  getModifiedManualFeedback
} from '../utils/pdf-utils';
import { AlertService } from 'src/app/services/alert.service';
@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnInit, OnChanges {

  constructor(
    private translate: TranslatePipe,
    private apiService: ApiService,
    public alertService: AlertService,
  ) { }
  @Input() selectedResult = null;
  @Input() selectedQuery = null;
  @Output() setManualFeedbackSelections = new EventEmitter();
  @Output() setDisableDoneButtonOnFilePreview = new EventEmitter();
  @Output() disablePDFPreviewControlButtons = new EventEmitter();
  public getDataFromDBSettings: Object;
  public BASE64_MARKER = ';base64,';
  public contenttype: String = "application/pdf"
  public pdfViewer = null
  public pdfLinkService = null
  public aiResultPageNumber;
  public currentPageNumber;
  public aiResultBoundingBox;
  @Input() scale;
  public editFeedback = false;
  public currentBox = {};
  public cursorMouseDownEventAttached = {
    startCursor: false,
    endCursor: false
  };
  public lablesAndMessage = {
    'done': this.translate.transform('filePreview.done'),
    "saveBtn": this.translate.transform('filePreview.saveBtn'),
    "clearBtn": this.translate.transform('filePreview.clearBtn'),
    "copyBtn": this.translate.transform('filePreview.copyBtn'),
    "semanticBtn": this.translate.transform('filePreview.semantic'),
    "answerBtn": this.translate.transform('filePreview.answer'),
    "semanticInsideAnswerValidation": this.translate.transform('filePreview.semanticInsideAnswerValidation'),
    "clearAnswer": this.translate.transform('filePreview.clearAnswer'),
    "restoreSelection": this.translate.transform('filePreview.restoreSelection')
  }
  public manualFeedback = {
    "explorationId": null,
    "queryIds": [],
    "chunkId": null,
    "semanticResultId": null,
    "selections": [],
  };
  public enableDisableDoneButton = (flag) => {
    this.setDisableDoneButtonOnFilePreview.emit(flag);
  }
  public enableDisableControlButtons = (flag) => {
    this.disablePDFPreviewControlButtons.emit(flag);
  }
  public convertDataURIToBinary(dataURI) {
    const base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var counter = 0; counter < rawLength; counter++) {
      array[counter] = raw.charCodeAt(counter);
    }
    return array;
  }
  ngOnInit(): void {
    this.pdfViewer = null;
    this.getDataFromDBSettings = {
      getPDFDocument: this.getPDFDocument.bind(this),
    };
    pdfjsLib.GlobalWorkerOptions.workerSrc = "assets/pdfjs/pdf.worker.min.js";
    const eventBus = new pdfjsViewer.EventBus();
    this.pdfLinkService = new pdfjsViewer.PDFLinkService({
      eventBus,
    });
    const pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus,
      linkService: this.pdfLinkService,
    });
    const container = document.getElementById("viewerContainer");
    this.pdfViewer = new pdfjsViewer.PDFViewer({
      container,
      eventBus,
      linkService: this.pdfLinkService,
      findController: pdfFindController,
    });
    this.pdfLinkService.setViewer(this.pdfViewer);
    setLablesAndMessage(this.lablesAndMessage)
    eventBus.on("pagesinit", () => {
      this.pdfViewer.currentScaleValue = this.scale || "page-width";
      this.pdfViewer.currentPageNumber = this.aiResultPageNumber
    });
    eventBus.on("textlayerrendered", (evt) => {
      this.aiResultBoundingBox = getBoundingBox(this.selectedResult.USEModel.position, this.selectedResult.USEModel.size, false, this.scale);
      this.pdfViewer.currentScaleValue = this.scale
      this.bindSVGLayerToPage(this.currentPageNumber)
      if (this.aiResultBoundingBox && this.aiResultPageNumber == this.currentPageNumber) {
        const redrawBoxData = {
          drawSemanticBox: this.drawSemanticBox,
          boundingBox: this.aiResultBoundingBox,
          selectedResult: this.selectedResult,
          selectedQuery: this.selectedQuery,
          scale: this.scale,
          alertService: this.alertService,
          pageNumber: this.aiResultPageNumber,
          enableDisableDoneButton: this.enableDisableDoneButton,
          enableDisableControlButtons: this.enableDisableControlButtons
        }
        this.drawSemanticBox(this.aiResultBoundingBox, this.aiResultPageNumber, this.selectedResult.robertaModel, false)
        if(this.selectedQuery.qna){
          createEditButton(redrawBoxData, '', this.lablesAndMessage.answerBtn, false, this.editFeedback, this.getCurrentBox, this.setCurrentBox, this.cursorMouseDownEventAttached, this.mouseDownEventCB, this.addUpdateDeleteManualFeedback)
        }
        const group = document.getElementById("aiFeedback");
        showHideButtonEventOnSelectionArea(group, this.aiResultBoundingBox['x1'], this.aiResultBoundingBox['y1'])
      }
      this.showManualFeedbacks()
    })

    eventBus.on('pagechanging', async (e) => {
      if (this.currentPageNumber != e.pageNumber) {
        console.log('current page number', e.pageNumber)
        this.currentPageNumber = e.pageNumber
        setTimeout(() => {
          this.pdfViewer.currentScaleValue = this.scale
          this.bindSVGLayerToPage(this.currentPageNumber)
          this.showManualFeedbacks()
        }, 500)
      }
    })
    this.getPDFDocument()
  }

  public showManualFeedbacks = () => {
    const manualFeedback = this.selectedResult.USEModel.feedback?.selections ? [...this.selectedResult.USEModel.feedback.selections] : []
    const manualFeedbackList = []
    this.manualFeedback.selections.forEach(selection => {
      let isPresent;
      if (selection['USEModel'] && selection['USEModel'].selectionId) {
        isPresent = manualFeedback.find(fb => {
          if (selection['USEModel'] && fb.id == selection['USEModel'].selectionId) {
            return true;
          }
        })
      }
      if (!isPresent && !isEmpty(selection['USEModel'])) {
        manualFeedback.push(selection['USEModel'])
      }
    })
    manualFeedback.forEach(feedback => {
      if (feedback.boundingBox && (feedback.implicit || feedback.pageNum == this.currentPageNumber)) {
        //handling case when for previously saved feedback, where feedback is modified and changing the scale
        let isFeedbackModified = false;
        if (feedback.id) {
          this.manualFeedback.selections.find(selection => {
            //Below condition is to check if semantic feedback is modified
            if(selection.operation == 'update' 
              && selection.USEModel
              && feedback.id == selection.USEModel.selectionId 
              && selection.USEModel.pageNum == feedback.pageNum
              && selection.USEModel.text != feedback.text){
                  isFeedbackModified = true;
                  return true;
            } else if (selection.operation == 'update' 
              && !selection.USEModel
              && selection.robertaModel.pageNum == feedback.pageNum 
              && selection.robertaModel.boundingBox.position[0] == feedback.boundingBox.position[0] 
              && selection.robertaModel.boundingBox.size[0] == feedback.boundingBox.size[0]) {
                  //This condition is to check if semantic feedback is not modified but answer feedback is modified
                  isFeedbackModified = true;
                  return true;
              }
          })
        }
        let feedbackObj = feedback.id && !isFeedbackModified ? getManualFeedbackFromSelectedResults(this.selectedResult, feedback, this.scale) : getModifiedManualFeedback(this.manualFeedback.selections, feedback, this.scale)
        const qnaFeedback = feedbackObj['qnaFeedback'];
        const feedbackBoundingBox = getBoundingBox(feedbackObj.boundingBox.position, feedbackObj.boundingBox.size, true, this.scale);
        this.drawSemanticBox(feedbackBoundingBox, feedbackObj.pageNum, feedbackObj, true, false)
        if (feedbackObj['qnaFeedback']) {
          highlightFeedbackText(this.scale, [feedbackBoundingBox.x1, feedbackBoundingBox.y1, feedbackBoundingBox.x2, feedbackBoundingBox.y2], feedbackObj.pageNum, feedbackObj['qnaFeedback'], false, true, feedbackObj.text)
        }
        const x = feedbackObj.boundingBox.position[0];
        const y = feedbackObj.boundingBox.position[1];
        const w = +(feedbackBoundingBox.x2 - feedbackBoundingBox.x1).toFixed(3);
        const h = +(feedbackBoundingBox.y2 - feedbackBoundingBox.y1).toFixed(3);

        this.currentBox = {};
        this.currentBox['w'] = w;
        this.currentBox['h'] = h;
        this.currentBox['x1'] = feedbackBoundingBox.x1;
        this.currentBox['y1'] = feedbackBoundingBox.y1;
        this.currentBox['x2'] = feedbackBoundingBox.x2;
        this.currentBox['y2'] = feedbackBoundingBox.y2;
        this.currentBox['pageNo'] = feedback.pageNum;
        this.currentBox['saveButtonId'] = `saveButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['discardButtonId'] = `discardButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['copyButtonId'] = `copyButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['feedbackType'] = 'semantic';
        this.currentBox['startCursor'] = { ...feedbackObj.boundingBox.startCursor };
        this.currentBox['endCursor'] = { ...feedbackObj.boundingBox.endCursor };
        this.currentBox['feedbackText'] = feedback.text;
        if (qnaFeedback) {
          this.currentBox['qnaFeedback'] = {
            startCursor: { ...qnaFeedback.boundingBox.startCursor },
            endCursor: { ...qnaFeedback.boundingBox.endCursor },
            startIndex: qnaFeedback.boundingBox.startIndex,
            endIndex: qnaFeedback.boundingBox.endIndex,
            'feedbackText': qnaFeedback.text,
            'id': qnaFeedback.id
          }
        }
        this.currentBox['id'] = feedbackObj.id
        const redrawFeedbackBoxData = {
          drawSemanticBox: this.drawSemanticBox,
          boundingBox: feedbackBoundingBox,
          selectedResult: feedbackObj,
          selectedQuery: this.selectedQuery,
          scale: this.scale,
          alertService: this.alertService,
          pageNumber: feedbackObj.pageNum,
          enableDisableDoneButton: this.enableDisableDoneButton,
          enableDisableControlButtons: this.enableDisableControlButtons
        }
        createEditButton(redrawFeedbackBoxData, feedbackObj.id, this.lablesAndMessage.semanticBtn, false, this.editFeedback, this.getCurrentBox, this.setCurrentBox, this.cursorMouseDownEventAttached, this.mouseDownEventCB, this.addUpdateDeleteManualFeedback)
        if (this.selectedQuery.qna) {
          this.currentBox['feedbackType'] = 'answer'
          createEditButton(redrawFeedbackBoxData, feedbackObj['qnaFeedback']?.id || '', this.lablesAndMessage.answerBtn, true, this.editFeedback, this.getCurrentBox, this.setCurrentBox, this.cursorMouseDownEventAttached, this.mouseDownEventCB, this.addUpdateDeleteManualFeedback)
        }
        this.currentBox = {};
        const group = document.getElementById(`resizeID-${w}-${h}`);
        showHideButtonEventOnSelectionArea(group, x, y)
        manualFeedbackList.push(feedbackObj)
      } else if (feedback.implicit == 'True'){
        let isFeedbackModified = false;
        
        const savedAnswerFeedback = this.selectedResult.robertaModel?.feedback?.selections.find(selection => {
          return selection.parentId == feedback.id
        })
        this.manualFeedback.selections.find(selection => {
          //Below condition is to check if answer feedback is modified inside AI box
          if (selection.USEModel
            && selection.USEModel.implicit == 'True'
            && feedback.implicit == 'True'
            && selection.robertaModel.text != this.selectedResult.robertaModel.text) {
            //new answer feedback is provided
            isFeedbackModified = true;
            return true;
          } else if (feedback.implicit == 'True'
            && savedAnswerFeedback
            && selection.operation == 'update'
            && savedAnswerFeedback.id == selection.robertaModel.selectionId
            && savedAnswerFeedback.text != selection.robertaModel.text) {
            //modifying the existing answer feedback
            //answerFeedbackId is required for comparision inside getModifiedManualFeedback 
            feedback['answerFeedbackId'] = savedAnswerFeedback.id
            isFeedbackModified = true;
            return true;
          }

        })
        let answerFeedback = !isFeedbackModified ? getManualFeedbackFromSelectedResults(this.selectedResult, feedback, this.scale) : getModifiedManualFeedback(this.manualFeedback.selections, feedback, this.scale)
      
        //const answerFeedback = getManualFeedbackFromSelectedResults(this.selectedResult, feedback, this.scale)
        if (answerFeedback && answerFeedback.pageNum == this.currentPageNumber) {
          const feedbackBoundingBox = getBoundingBox(answerFeedback.boundingBox.position, answerFeedback.boundingBox.size, true, this.scale);
          highlightFeedbackText(this.scale, [feedbackBoundingBox.x1, feedbackBoundingBox.y1, feedbackBoundingBox.x2, feedbackBoundingBox.y2], answerFeedback.pageNum, answerFeedback, true, true)
        }
      }
    })
  }

  public getCurrentBox = (pageNo, x, y, feedbackType, isAiBox = false) => {
    if(!pageNo && !x && !y){
      return this.currentBox || {}
    }
    const manualFeedback = this.selectedResult.USEModel.feedback?.selections ? [...this.selectedResult.USEModel.feedback.selections] : []
    let savedManualFeedback = {}
    
    manualFeedback.some(feedback => {
      if (feedback.boundingBox || feedback.implicit == 'True') {
        const feedbackObj = getManualFeedbackFromSelectedResults(this.selectedResult, feedback, this.scale)
        if (feedbackObj
          && feedbackObj.pageNum == pageNo
          && feedbackObj.boundingBox.position[0] == x
          && feedbackObj.boundingBox.position[1] == y) {
          savedManualFeedback = window['structuredClone'](feedbackObj)
          return true;
        }
      }
    })
    const manualFeedbackConstantFactor = getConstantFactor(this.scale, true)
    const feedbackIndex = findManualFeedback(this.manualFeedback['selections'], { x1: x, y1: y, pageNo, isAiBox }, feedbackType, manualFeedbackConstantFactor);
    if (feedbackIndex > -1) {
      //Here we need to manage the logic when user modifies the saved manual feedback
      feedbackType = feedbackType =='semantic' ? 'USEModel' : 'robertaModel'
      
      //const feedbackData = this.manualFeedback['selections'][feedbackIndex][feedbackType]
      let feedbackData = this.manualFeedback['selections'][feedbackIndex]
      let qnaFeedback = {}
      if (feedbackType == 'robertaModel' && !isAiBox && feedbackData[feedbackType]) {
        qnaFeedback = { ...feedbackData[feedbackType] }
        qnaFeedback['startCursor'] = qnaFeedback['boundingBox'].startCursor
        qnaFeedback['endCursor'] = qnaFeedback['boundingBox'].endCursor
        delete qnaFeedback['boundingBox']
        feedbackData = { ...feedbackData['USEModel'] }
      } else if (feedbackType == 'robertaModel' && isAiBox) {
        let isDeleted = false;
        if (feedbackData.operation == 'delete') {
          isDeleted = true;
        }
        feedbackData = { ...feedbackData[feedbackType] }
        feedbackData['isDeleted'] = isDeleted;
      } else {
        qnaFeedback = window['structuredClone'](feedbackData['robertaModel'] || {})
        feedbackData = { ...feedbackData['USEModel'] }
        if (isEmpty(feedbackData)) {
          feedbackData = savedManualFeedback;
        }
        if (!isEmpty(qnaFeedback)) {
          qnaFeedback['startCursor'] = qnaFeedback['boundingBox'].startCursor
          qnaFeedback['endCursor'] = qnaFeedback['boundingBox'].endCursor
          if(qnaFeedback['text']){
            qnaFeedback['feedbackText'] = qnaFeedback['text']
            //to do, delete qnaFeedback['text'] if required
          }
          delete qnaFeedback['boundingBox']
        }
      }
      const boxPositionData = feedbackData['boundingBox']
      if (boxPositionData) {
        return getCurrentBoxObject(boxPositionData, feedbackData, qnaFeedback, manualFeedbackConstantFactor)
      } else {
        //this is the scenario when user deletes the manual feedback inside ai box and again clicks on edit Answer button
        return {}
      }
    } else if (Object.keys(savedManualFeedback).length > 0) {
      const boxPositionData = window['structuredClone'](savedManualFeedback['boundingBox'])
      let qnaFeedback = {}
      if (savedManualFeedback['qnaFeedback']) {
        qnaFeedback = window['structuredClone'](savedManualFeedback['qnaFeedback'])
        qnaFeedback['startCursor'] = window['structuredClone'](qnaFeedback['boundingBox'].startCursor)
        qnaFeedback['endCursor'] = window['structuredClone'](qnaFeedback['boundingBox'].endCursor)
        delete qnaFeedback['boundingBox']
      }
      return getCurrentBoxObject(boxPositionData, savedManualFeedback, qnaFeedback)
    } else {
      return this.currentBox
    }
  }
  public setCurrentBox = (currentBox) => {
    if(isEmpty(currentBox)){
      this.currentBox = {}
    }else{
      this.currentBox = {
        ...currentBox,
        'startCursor': window['structuredClone'](currentBox.startCursor),
        'endCursor': window['structuredClone'](currentBox.endCursor),
      }
      if(!isEmpty(currentBox.qnaFeedback)){
        this.currentBox['qnaFeedback'] = {
          ...currentBox.qnaFeedback,
          'startCursor': window['structuredClone'](currentBox.qnaFeedback.startCursor),
          'endCursor': window['structuredClone'](currentBox.qnaFeedback.endCursor),
        }
      }
    }
  }

  public addUpdateDeleteManualFeedback = (operationType, feedbackType, currentBox) => {
    const manualFeedbackConstantFactor = getConstantFactor(this.scale, true)
    let feedbackIndex = findManualFeedback(this.manualFeedback['selections'], currentBox, feedbackType, manualFeedbackConstantFactor);
    if (operationType == 'add') {
      if (feedbackIndex > -1) {
        const modalName = feedbackType == 'semantic' ? 'USEModel' : 'robertaModel';
        let emptyObj = { 
          "answerId": this.selectedResult?.robertaModel?.answerId,
          'text': '',
          'boundingBox': { "startCursor": null, "endCursor": null, "position": null, "size": null },
          'pageNum': currentBox.pageNo
        }
        let feedback = this.manualFeedback['selections'][feedbackIndex][modalName];
        //answerManualFeedbackFlag flag denotes that user is giving answer feedback inside manual semantic box.
        const answerManualFeedbackFlag = feedbackType == 'answer' && !currentBox.isAiBox ? true : false
        if(!feedback && answerManualFeedbackFlag){
          //user is adding answer manual feedback for the first time inside manual semantic box.
          feedback = emptyObj;
          feedback['boundingBox']['position'] = [+(currentBox.x1 / manualFeedbackConstantFactor).toFixed(3), +(currentBox.y1 / manualFeedbackConstantFactor).toFixed(3)]
          feedback['boundingBox']['size'] = [+(currentBox.h / manualFeedbackConstantFactor).toFixed(3), +(currentBox.w / manualFeedbackConstantFactor).toFixed(3)]
        }
        if (!feedback['boundingBox']) {
          feedback['boundingBox'] = {}
        }
        feedback['pageNum'] = currentBox.pageNo;
        feedback['answerId'] = this.selectedResult?.robertaModel?.answerId;
        feedback['text'] = answerManualFeedbackFlag ? currentBox.qnaFeedback.feedbackText : currentBox.feedbackText;
        feedback['boundingBox']['startCursor'] = answerManualFeedbackFlag ? getScaledCursor(currentBox.qnaFeedback.startCursor, manualFeedbackConstantFactor, true) : getScaledCursor(currentBox.startCursor, manualFeedbackConstantFactor, true);
        feedback['boundingBox']['endCursor'] = answerManualFeedbackFlag ? getScaledCursor(currentBox.qnaFeedback.endCursor, manualFeedbackConstantFactor, true) : getScaledCursor(currentBox.endCursor, manualFeedbackConstantFactor, true);
        if (answerManualFeedbackFlag) {
          feedback['boundingBox']['startIndex'] = currentBox.qnaFeedback.startIndex;
          feedback['boundingBox']['endIndex'] = currentBox.qnaFeedback.endIndex;
        } else if (currentBox.isAiBox) {
          feedback['boundingBox']['startIndex'] = currentBox.startIndex;
          feedback['boundingBox']['endIndex'] = currentBox.endIndex;
        }
        if (!feedback['boundingBox']['position']) {
          feedback['boundingBox']['position'] = [+(currentBox.x1 / manualFeedbackConstantFactor).toFixed(3), +(currentBox.y1 / manualFeedbackConstantFactor).toFixed(3)]
        }
        if (!feedback['boundingBox']['size']) {
          feedback['boundingBox']['size'] = [+(currentBox.h / manualFeedbackConstantFactor).toFixed(3), +(currentBox.w / manualFeedbackConstantFactor).toFixed(3)]
        }
        if(this.manualFeedback['selections'][feedbackIndex]['operation'] == 'delete'){
          //if user deleted the saved manual feedback and then insert it again
          this.manualFeedback['selections'][feedbackIndex]['operation'] = 'update'
        }
        this.manualFeedback['selections'][feedbackIndex][modalName] = { ...feedback }
        if(modalName == 'USEModel'
            && this.manualFeedback['selections'][feedbackIndex]['robertaModel']
            && this.manualFeedback['selections'][feedbackIndex]['robertaModel']['boundingBox']['startIndex'] != currentBox.qnaFeedback.startIndex
            && this.manualFeedback['selections'][feedbackIndex]['robertaModel']['boundingBox']['endIndex'] != currentBox.qnaFeedback.endIndex ){
          //this block is to update startIndex and endIndex of answer when semantic is modified again after providing answer feedback
          this.manualFeedback['selections'][feedbackIndex]['robertaModel']['boundingBox']['startIndex'] = currentBox.qnaFeedback.startIndex;
          this.manualFeedback['selections'][feedbackIndex]['robertaModel']['boundingBox']['endIndex'] = currentBox.qnaFeedback.endIndex;
        }
      } else {
        let modalData = {};
        if (currentBox.isAiBox || feedbackType == 'semantic') {
          modalData = {
            "text": currentBox.feedbackText,
            "boundingBox": {
              "startCursor": getScaledCursor(currentBox.startCursor, manualFeedbackConstantFactor, true),
              "endCursor": getScaledCursor(currentBox.endCursor, manualFeedbackConstantFactor, true),
              "position": [currentBox.x1, currentBox.y1].map(position => +(position / manualFeedbackConstantFactor).toFixed(3)),
              "size": [currentBox.h, currentBox.w].map(size => +(size / manualFeedbackConstantFactor).toFixed(3))
            }
          }
          if (currentBox.isAiBox) {
            modalData['boundingBox'].startIndex = currentBox.startIndex;
            modalData['boundingBox'].endIndex = currentBox.endIndex;
          }
        } else if (!currentBox.isAiBox && feedbackType == 'answer') {
          modalData = {
            "text": currentBox.qnaFeedback.feedbackText,
            "boundingBox": {
              "startCursor": getScaledCursor(currentBox.qnaFeedback.startCursor, manualFeedbackConstantFactor, true),
              "endCursor": getScaledCursor(currentBox.qnaFeedback.endCursor, manualFeedbackConstantFactor, true),
              "position": [currentBox.x1, currentBox.y1].map(position => +(position / manualFeedbackConstantFactor).toFixed(3)),
              "size": [currentBox.h, currentBox.w].map(size => +(size / manualFeedbackConstantFactor).toFixed(3))
            }
          }
        }
        const selectionObj = getSelectionObject(currentBox.pageNo, this.selectedResult?.robertaModel?.answerId, "insert")
        if (feedbackType == 'semantic') {
          selectionObj['USEModel'] = { ...selectionObj['USEModel'], ...modalData };
          delete selectionObj['robertaModel'];
        } else {
          if (currentBox.isAiBox) {
            selectionObj['USEModel']['implicit'] = 'True';
            delete selectionObj['USEModel']['text'];
            delete selectionObj['USEModel']['boundingBox'];
            delete selectionObj['USEModel']['pageNum'];
            selectionObj['robertaModel'] = { ...selectionObj['robertaModel'], ...modalData };
          } else{
            selectionObj['parentId'] = currentBox.id;
            selectionObj['robertaModel'] = { ...selectionObj['robertaModel'], ...modalData };
            delete selectionObj['USEModel'];
          }
        }
        this.manualFeedback['selections'].push(selectionObj)
      }
    } else if (operationType == 'update') {
      //This block will handle update of semantic or answer manual feedback (saved in DB)
      const {
        selectionObj,
        isSemanticTextModified,
        isAnswerTextModified
      } = getSelectionObjToUpdateManualFeedback(this.selectedResult, this.manualFeedback.selections, currentBox, feedbackType, operationType, manualFeedbackConstantFactor)
      if(isSemanticTextModified || isAnswerTextModified){
        let mfbIndex;
        this.manualFeedback['selections'].forEach((mfb, index) => {
          if ((mfb.operation == 'update' || mfb.operation == 'delete')
            && 
            (
              //if user is modifying semantic or answer feedback multiple time 
              (
                mfb.robertaModel 
                && selectionObj['robertaModel'] 
                && mfb.robertaModel?.id == selectionObj['robertaModel']?.id 
                && mfb.robertaModel?.selectionId == selectionObj['robertaModel']?.selectionId
              )
              || 
              (
                mfb.USEModel 
                && selectionObj['USEModel'] 
                && mfb.USEModel?.id == selectionObj['USEModel']?.id 
                && mfb.USEModel?.selectionId == selectionObj['USEModel']?.selectionId
              )
            )
            ) {
            mfbIndex = index
          }
        })
        if (mfbIndex > -1) {
          this.manualFeedback['selections'].splice(mfbIndex, 1);
        }
        if (selectionObj) {
          this.manualFeedback['selections'].push(selectionObj)
        }
      }
    } else if (operationType == 'delete') {
      if (!currentBox.id) {
        if (feedbackType == 'answer' && !currentBox.isAiBox) {
          delete this.manualFeedback['selections'][feedbackIndex].robertaModel
        } else {
          this.manualFeedback['selections'].splice(feedbackIndex, 1);
        }
      } else {
        const { selectionObj } = getSelectionObjToUpdateManualFeedback(this.selectedResult, this.manualFeedback.selections, currentBox, feedbackType, operationType, manualFeedbackConstantFactor)
        let fbIndex;
        this.manualFeedback['selections'].forEach((fb, index) => {
          if ((fb.operation == 'update' || fb.operation == 'delete')
            && !isEmpty(fb.robertaModel)
            && !isEmpty(selectionObj['robertaModel'])
            && fb.robertaModel.id == selectionObj['robertaModel'].id
            && fb.robertaModel.selectionId == selectionObj['robertaModel'].selectionId) {
            fbIndex = index
          }
        })
        //below statement is to handle usecase where user has already deleted the answer feedback and then deleting semantic feedback
        if (fbIndex > -1) {
          this.manualFeedback['selections'].splice(fbIndex, 1);
        }
        if(selectionObj){
          this.manualFeedback['selections'].push(selectionObj)
        }
      }
    }
    this.setManualFeedbackSelections.emit(this.manualFeedback['selections'])
  }

  public drawSemanticBox = (boundingBox, pageNo, feedbackObj, isUserCreatedBox=false, isAnswer=false) => {
    boundingBox = [boundingBox.x1, boundingBox.y1, boundingBox.x2, boundingBox.y2]
    const pdfViewer = document.querySelector(`[data-page-number="${pageNo}"]`);
    const svg = pdfViewer && pdfViewer.querySelector('.drawingPlane');
    if (!svg) {
      return
    }
    const w = +(boundingBox[2] - boundingBox[0]).toFixed(3);
    const h = +(boundingBox[3] - boundingBox[1]).toFixed(3);
    const groupElement = svg.querySelector(`[id="aiFeedback"]`)
    const feedbackGroupElement = svg.querySelector(`[id="resizeID-${w}-${h}"]`)
    if (svg 
        && (
          (!isUserCreatedBox && !groupElement) 
          ||
          (isUserCreatedBox && !feedbackGroupElement))
          ) {
      const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      
      let leftBorderRequired = true;
      if(isUserCreatedBox && !feedbackObj.id){
        leftBorderRequired = false;
      }
      let leftBorderLine;
      if (leftBorderRequired) {
        leftBorderLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        leftBorderLine.setAttribute('x1', (boundingBox[0] - 2) + '');
        leftBorderLine.setAttribute('y1', parseInt(boundingBox[1]) + '');
        leftBorderLine.setAttribute('x2', (boundingBox[0] - 2) + '');
        leftBorderLine.setAttribute('y2', (parseInt(boundingBox[3]) + 1) + '');
        leftBorderLine.setAttribute("stroke", isUserCreatedBox ? "#64AD7D" : "#B57BBA")
        leftBorderLine.setAttribute('stroke-width', '3');
      }

      rect.setAttribute('class', 'rectSelect');
      rect.setAttributeNS(null, 'x', boundingBox[0]);
      rect.setAttributeNS(null, 'y', boundingBox[1]);
      rect.setAttributeNS(null, 'width', w + '');
      rect.setAttributeNS(null, 'height', h + '');
      if (isUserCreatedBox) {
        rect.setAttribute('fill', 'white');
        rect.setAttribute('stroke-width', '1.5');
        if (feedbackObj.id) {
          rect.setAttribute('stroke', '#64AD7D');
          rect.setAttribute('fill-opacity', '0.1');
          rect.setAttribute('stroke-opacity', '1');
        } else {
          rect.setAttribute('fill-opacity', '0.2');
          rect.setAttribute('stroke-opacity', '1');
          rect.setAttribute('stroke', '#4EBEEB');
          rect.setAttribute('stroke-dasharray', '3 3');
        }
        group.setAttribute("id", `resizeID-${w}-${h}`);
        group.setAttribute("class", "svgGroup");
      } else {
        rect.setAttribute('fill', '#B57BBA');
        rect.setAttribute('fill-opacity', '0.1');
        rect.setAttribute('stroke-opacity', '1');
        rect.setAttribute('stroke', '#B57BBA');
        rect.setAttribute('stroke-width', '1');
        group.setAttribute("id", "aiFeedback"); //Set id
        rect.setAttribute('semanticBox', `semanticBox`);
      }
      rect.setAttribute('cursor', 'pointer');
      rect.setAttribute('id', `rect-${w}-${h}`);
      svg.appendChild(group);
      group.appendChild(rect);
      if (leftBorderRequired) {
        group.appendChild(leftBorderLine);
      }
      if (!isEmpty(feedbackObj)) {
        highlightFeedbackText(this.scale, boundingBox, pageNo, feedbackObj, !isUserCreatedBox, isAnswer)
      }
    }
  }

  public mouseDownEventCB = (event, svg, svgPoint, pdfViewer, pageNo) => {
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute("class", "svgGroup"); //Set id
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    const circleEnd = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    const start = svgPoint(svg, event.clientX, event.clientY);
    const drawRect = (e) => {
      const p = svgPoint(svg, e.clientX, e.clientY);
      const w = Math.abs(p.x - start.x);
      const h = Math.abs(p.y - start.y);
      if (p.x > start.x) {
        p.x = start.x;
      }

      if (p.y > start.y) {
        p.y = start.y;
      }

      rect.setAttribute('class', 'rectSelect');
      rect.setAttributeNS(null, 'x', p.x);
      rect.setAttributeNS(null, 'y', p.y);
      rect.setAttributeNS(null, 'width', Math.round(w) + '');
      rect.setAttributeNS(null, 'height', Math.round(h) + '');
      //rect.setAttribute('fill', '#87D3F2');
      rect.setAttribute('fill', 'white');
      rect.setAttribute('fill-opacity', '0.2');
      rect.setAttribute('stroke-opacity', '1');
      rect.setAttribute('stroke', '#4EBEEB');
      rect.setAttribute('stroke-width', '1.5');
      rect.setAttribute('stroke-dasharray', '3 3');
      rect.setAttribute('id', `rect-${Math.round(w)}-${Math.round(h)}`);
      rect.setAttribute('cursor', 'pointer');
      circle.setAttributeNS(null, 'cx', p.x);
      circle.setAttributeNS(null, 'cy', p.y);
      circle.setAttributeNS(null, 'r', '4');
      circle.setAttribute('fill', '#87D3F2');
      circle.setAttribute('stroke', '#87D3F2');
      circle.setAttribute('data-refIndex', '0');
      circle.setAttribute('data-rect', 'zigRect0 annotation-rect');
      circle.setAttribute('id', `startCircle-${p.x}-${p.y}`);
      svg.appendChild(group);
      group.appendChild(circle);
      group.appendChild(rect);
    }
    const endDraw = async (e) => {
      //whenever user draws new box, we need to reset cursorMouseDownEventAttached to default.
      this.cursorMouseDownEventAttached = {
        startCursor: false,
        endCursor: false
      };
      let svgGroup = svg.querySelectorAll('.svgGroup');
      svgGroup = svgGroup[svgGroup.length - 1];
      const pe = svgPoint(svg, e.clientX, e.clientY);
      const w = +Math.round(pe.x - start.x).toFixed(3);
      const h = +Math.round(pe.y - start.y).toFixed(3);
      group.setAttribute("id", `resizeID-${w}-${h}`); //Set id
      const saveButton = document.querySelectorAll(`[id*="saveButton"]`);
      const discardButton = document.querySelectorAll(`[id*="discardButton"]`);
      const copyButton = document.querySelectorAll(`[id*="copyButton"]`);

      const elementsObj = {rect, circle, svg, saveButton, discardButton, copyButton}
      if (
        (start.x < 0 || start.x > pe.x || start.y < 0 || start.y > pe.y)
        || w < 10
        || h < 10
        || (
          (saveButton && saveButton.length > 0)
          || (discardButton && discardButton.length > 0)
          || (copyButton && copyButton.length > 0)
        )
      ) {
        removeInvalidFeedbackBox(this.mouseDownEventCB, elementsObj, pageNo, this.editFeedback, this.alertService.error, svgGroup)
        showHideEventOnButtonsAtPage(pageNo)
      } else {
        this.currentBox['w'] = w;
        this.currentBox['h'] = h;
        this.currentBox['x1'] = +start.x.toFixed(3);
        this.currentBox['y1'] = +start.y.toFixed(3);
        this.currentBox['x2'] = +pe.x.toFixed(3);
        this.currentBox['y2'] = +pe.y.toFixed(3);
        this.currentBox['pageNo'] = pageNo;
        this.currentBox['saveButtonId'] = `saveButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['discardButtonId'] = `discardButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['copyButtonId'] = `copyButton-${this.currentBox['x1']}-${this.currentBox['y1']}`;
        this.currentBox['feedbackType'] = 'semantic'

        const failedSelectionProps = {
          rect,
          circle,
          svg,
          saveButton,
          discardButton,
          copyButton,
          pageNo,
          group: svgGroup,
          showMessage: true
        }
        const feedbackBoxProps = {
          pageNo,
          start,
          pe,
          w,
          h,
          svgGroup,
          currentBox : this.currentBox,
          scale: this.scale,
          cursorMouseDownEventAttached: this.cursorMouseDownEventAttached
        }
        const redrawBoxData = {
          drawSemanticBox: this.drawSemanticBox,
          boundingBox: [start.x, start.y, pe.x, pe.y],
          selectedResult: this.selectedResult,
          selectedQuery: this.selectedQuery,
          scale: this.scale,
          alertService: this.alertService,
          pageNumber: this.currentPageNumber,
          feedbackType: 'semantic',
          enableDisableDoneButton: this.enableDisableDoneButton,
          enableDisableControlButtons: this.enableDisableControlButtons
        }
        const plottingCondition = { isEditing: false, isCreating: true, isPlotting: false }
        const feedbackText = findAndHighlightText(this.addUpdateDeleteManualFeedback, this.getCurrentBox, redrawBoxData, this.setCurrentBox, this.mouseDownEventCB, this.alertService.error, this.editFeedback, feedbackBoxProps, plottingCondition, failedSelectionProps);
        if (!feedbackText) {
          removeInvalidFeedbackBox(this.mouseDownEventCB, elementsObj, pageNo, this.editFeedback, this.alertService.error, svgGroup)
          showHideEventOnButtonsAtPage(pageNo)
          return;
        }
        this.currentBox['feedbackText'] = feedbackText;
        const startCursor = document.getElementsByClassName('startCursor')[0];
        if (startCursor) {
          circleEnd.setAttributeNS(null, 'cx', pe.x);
          circleEnd.setAttributeNS(null, 'cy', pe.y);
          circleEnd.setAttributeNS(null, 'r', '4');
          circleEnd.setAttribute('fill', '#87D3F2');
          circleEnd.setAttribute('stroke', '#87D3F2');
          circleEnd.setAttribute('data-refIndex', '0');
          circleEnd.setAttribute('data-rect', 'zigRect0 annotation-rect');
          circleEnd.setAttribute('id', `endCircle-${pe.x}-${pe.y}`);
          svgGroup && svgGroup.appendChild(circleEnd);
          svgGroup && feedbackText.trim() && addButtonsOnRect(w, h, this.getCurrentBox, this.setCurrentBox, pdfViewer, this.cursorMouseDownEventAttached, this.mouseDownEventCB, this.editFeedback, redrawBoxData, this.addUpdateDeleteManualFeedback);
          //removing all events on svg
          svg && svg.parentNode && svg.parentNode.replaceChild(svg.cloneNode(true), svg);
          enableDisableEditButtons(true, this.editFeedback);
          disableScrollOnViewerContainer(true)
          this.enableDisableControlButtons(true)
        } else {
          const cir = document.querySelector('[id^="startCircle"]')
          cir && cir.remove()
          svg && svg.parentNode && svg.parentNode.replaceChild(svg.cloneNode(true), svg);
        }

      }
    }
    svg.addEventListener('mousemove', drawRect);
    svg.addEventListener('mouseup', endDraw);
  }

  public bindSVGLayerToPage = (pageNo) => {
    const bindSVGLayerToScreen = document.querySelector(`[data-page-number="${pageNo}"]`);
    const svgElement = bindSVGLayerToScreen && bindSVGLayerToScreen.querySelector('.drawingPlane');
    if (!svgElement && bindSVGLayerToScreen && bindSVGLayerToScreen['offsetWidth']) {
      const creatSVGElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      creatSVGElement.setAttribute("class", "drawingPlane"); //Set class
      creatSVGElement.setAttribute("style", `width: ${bindSVGLayerToScreen['offsetWidth']}px; height: ${bindSVGLayerToScreen['offsetHeight']}px; position: absolute; top: 0px; z-index: 2; cursor: crosshair`);
      bindSVGLayerToScreen.appendChild(creatSVGElement);
      setEventListeners(pageNo, this.mouseDownEventCB);
    }
  }

  public getPDFDocument() {
    //this.aiResultPageNumber = this.selectedResult.USEModel.page + 1;
    this.aiResultPageNumber = this.selectedResult.USEModel.page;
    this.currentPageNumber = this.aiResultPageNumber;
    this.apiService
      .get(`download/original/${this.selectedResult.documentId}/?`, '', '').subscribe(
        (data: {
          name: string;
          file: string;
          contenttype: string;
        }) => {
          const pdfFile = `data:${this.contenttype};base64,${data.file}`
          const pdfAsArray = this.convertDataURIToBinary(pdfFile);
          const loadingTask = pdfjsLib.getDocument(pdfAsArray);
          (async () => {
            const pdfDocument = await loadingTask.promise;
            this.pdfViewer.setDocument(pdfDocument);

            this.pdfLinkService.setDocument(pdfDocument, null);
          })();
        },
        () => { }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scale) {
      if (changes.scale.previousValue && changes.scale.previousValue != changes.scale.currentValue) {
        this.pdfViewer.currentScaleValue = this.scale;
        this.pdfViewer.update();
      }
    } 
  }
}
