import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ProjectModel } from '../models/project-mstr.model';
import { AnalysisModel } from '../models/analysis.model';
import { DocumentModel } from '../models/document.model';
import { KPIModel } from '../models/kpi.model';
import { LanguageModel } from '../models/language.model';
import { ReviewerModel } from '../models/reviewer.model';
import { RoleModel } from '../models/role.model';
import { StatusModel } from '../models/status.model';
import { ActivityModel } from '../models/activity.model';
import { DocumentFieldDataModel } from '../models/document-field-data.model';
import { ValidationStatusModel } from '../models/validation-status.model';
import { ValidationTypeModel } from '../models/validation-type-mstr.model';
import { ValidationModel } from '../models/validation-mstr.model';
import { ValidationExecutionModel } from '../models/validation-execution.model';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatamodelPagination } from '../models/datamodel-pagination.model';
import * as validationStatusConst from 'src/app/constants/validationStatus';
import * as documentStatusConst from 'src/app/constants/documentStatus';
import * as validationTypesConst from 'src/app/constants/validationTypes';
import * as activityConstants from 'src/app/constants/ActivityConstants';
import * as datamodelsConstants from 'src/app/constants/DatamodelsConstants';

import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';
import * as userPermissionsConst from 'src/app/constants/userPermissions';
import { EnrichmentModel } from '../models/enrichment.model';
import { ValidationSearchService } from 'src/app/components/validations-filter/validation-search.service';
import { CognitiveSearchService } from 'src/app/pages/query/cognitive-search.service';
import { PermissionModel } from '../models/permission.model';
import { DatamodelSearchService } from '../pages/datamodels/datamodel-search.service';
import { TenantModel } from '../models/tenant-mstr.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private project: ProjectModel;
  private analysis: AnalysisModel;
  private document: DocumentModel;
  private documentDetails: DocumentFieldDataModel;
  private querysheet;
  private exploration;
  private languages: LanguageModel[] = [];
  private reviewers: ReviewerModel[] = [];
  private roles: RoleModel[] = [];
  private permissions: PermissionModel[] = [];
  private status: StatusModel[];
  private globalStatus: any[] = [];
  private activities: ActivityModel[] = [];
  private fieldtypes: any[];
  private kpis = {};
  private charts = {};
  private validationStatus: ValidationStatusModel[] = [];
  private validationTypes: ValidationTypeModel[] = [];
  private consolidations;
  private enrichmentTypes: EnrichmentModel[] = [];
  private datamodels = new Map();
  private preliminaryValidations = new Map();
  private catalogValidations = new Map();
  private trainableDatamodels = new Map();
  private dataModelPagination: DatamodelPagination;
  private trainablePagination: DatamodelPagination;
  private datamodelsSub = new Subject<any>();
  private utilsSub = new Subject<any>();
  private page;
  private errorsmapping;
  private errorschecklist = [];
  private checklists = [];
  private functionsData;
  private validationExecution: ValidationExecutionModel;
  private documentValidations: any[];
  private documentValidationsId: number;
  public appTittle: string;
  public passedWatcherDatamodels = false;
  public passedWatcherUtils = false;
  public currentContent: string;
  public _documentStatus: any;
  public syncfusionLicenseKey: string;
  private currentTab;
  private selectedFeedbacks;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private router: Router,
    private validationSearchService: ValidationSearchService,
    private datamodelSearchService: DatamodelSearchService,
    private cognitiveSearchService: CognitiveSearchService
  ) {
    this._documentStatus = this.getDocumentStatusConst();
    this.page = 1;
  }

  /**
   * API query to retrieve datamodelvalidations
   * @parampage number of page to retrieve
   */
  public getDatamodelValidationsFromAPI(page) {
    let params = { page_size: 30, page: page, iseditable: '' };
    return this.apiService.get(`datamodels/`, params, '').toPromise();
  }

  /**
   * API query to retrieve datamodelvalidations
   * @parampage number of page to retrieve
   */
  public getTrainableDatamodelsFromAPI(page) {
    let params = {
      page_size: 30,
      page: page,
      iseditable: '',
      type: 'humanloop'
    };
    return this.apiService.get(`datamodels/`, params, '').toPromise();
  }

  /**
   * Observable of datamodels
   */
  public watchDataModels(): Observable<any> {
    return this.datamodelsSub.asObservable();
  }

  /**
   * Observable of utils
   */
  public watchUtils(): Observable<any> {
    return this.utilsSub.asObservable();
  }

  /**
   * Get datamodels variable
   */
  public getDatamodels() {
    return this.datamodels;
  }

  /**
   * API call to global that retrieve utils data
   */
  public getUtilsApi() {
    return this.apiService.get(`global/`, '', '').toPromise();
  }

  /**
   * Get errosmapping function
   */
  public getErrorsMapping() {
    return this.errorsmapping;
  }

  /**
   * Get errorschecklist function
   */
  public getErrorsCheckList() {
    return this.errorschecklist;
  }

  /**
   * Get checklists function
   */
  public getChecklists() {
    return this.checklists;
  }

  /**
   * set errorsmapping function
   * @param errorsmapping data to set
   */
  public setErrorsMapping(errorsmapping) {
    this.errorsmapping = errorsmapping;
    return;
  }

  /**
   * set errorschecklist function
   * @param errorsmapping data to set
   */
  public setErrorsChecklist(errorschecklist) {
    this.errorschecklist = errorschecklist;
    return;
  }

  /**
   * set errorschecklist function
   * @param checklists data to set
   */
  public setChecklists(checklists) {
    this.checklists = checklists;
    return;
  }

  /**
   * Get languages function
   */
  public getLanguages() {
    return this.languages;
  }

  /**
   * set languages function
   * @param languages data to set
   */
  public setLanguages(languages) {
    this.languages = languages;
    return;
  }

  /**
   * Get reviewers groups
   */
  public getReviewers() {
    return this.reviewers;
  }

  /**
   * set reviewers groups function
   * @param reviewers groups to store
   */
  public setReviewers(reviewers) {
    this.reviewers = reviewers;
    return;
  }

  /**
   * Get roles groups
   */
  public getRoles() {
    return this.roles;
  }

  /**
   * set roles groups function
   * @param roles groups to store
   */
  public setRoles(roles) {
    this.roles = roles;
    return;
  }

  /**
   * Get roles groups
   */
  public getPermissions() {
    return this.permissions;
  }

  /**
   * set roles groups function
   * @param permissions groups to store
   */
  public setPermissions(permissions) {
    this.permissions = permissions;
    return;
  }

  /**
   * Get errosmapping function
   */
  public getFunctions() {
    return this.functionsData;
  }

  /**
   * set functions
   * @param functionsData data to set
   */
  public setFunctions(functionsData) {
    this.functionsData = functionsData;
    return;
  }

  /**
   * Description: Retrieve validation types
   * @returns Validatio Types
   */
  public getValidationTypes() {
    return this.validationTypes;
  }

  /**
   * Description: Set global validation types
   * @param validationTypes validation types to set
   * @returns void
   */
  public setValidationTypes(validationTypes) {
    this.validationTypes = validationTypes;
  }

  /**
   * Description: Retrieve consolidations
   * @returns consolidations
   */
  public getConsolidations() {
    return this.consolidations;
  }

  /**
   * Description: Set global validation types
   * @param consolidations validation types to set
   * @returns void
   */
  public setConsolidations(consolidations) {
    this.consolidations = consolidations;
  }

  /**
   * Description: Retrieve Enrichment Types
   * @returns enrichment types
   */
  public getEnrichmentTypes() {
    return this.enrichmentTypes;
  }

  /**
   * Description: Set Enrichment Types
   * @param enrichmentTypes  to set
   * @returns void
   */
  public setEnrichmentTypes(enrichmentTypes) {
    this.enrichmentTypes = enrichmentTypes;
  }

  /**
   * Set global statuses array
   * @param status status
   */
  public setGlobalStatus(status) {
    this.globalStatus = status;
    return;
  }

  /**
   * Returns global statuses array
   */
  public getGlobalStatus() {
    return this.globalStatus;
  }

  /**
   * Set validation status data
   */
  public setValidationStatus(data: ValidationStatusModel[]) {
    this.validationStatus = data;
    return;
  }

  /**
   * Get validation status data
   */
  public getValidationStatus() {
    return this.validationStatus;
  }

  /**
   * Get activities function
   */
  public getActivities() {
    return this.activities;
  }

  /**
   * set activities function
   * @param activities data to set
   */
  public setActivities(activities) {
    this.activities = activities;
    return;
  }

  /**
   * call to API and set global cached variabled
   */
  public async getUtils() {
    let utils = await this.getUtilsApi();
    let utilsValidation = utils.validations;
    this.setFunctions(utilsValidation.functions);
    this.setValidationTypes(utilsValidation.types);
    this.setValidationStatus(utilsValidation.status);
    this.setErrorsMapping(utilsValidation.errorsmapping);
    this.setErrorsChecklist(utilsValidation.errorschecklist);
    this.setChecklists(utilsValidation.checklists);
    this.setLanguages(utils.languages);
    this.setConsolidations(utils.consolidations);
    this.setReviewers(utils.reviewers);
    this.setGlobalStatus(utils.status);
    this.setEnrichmentTypes(utils.enrichment_types);
    this.setActivities(utils.activities);
    this.setRoles(utils.roles);
    this.setPermissions(utils.permissions);
    this.syncfusionLicenseKey = utils.syncfusionLicenseKey=="0X0X-0X0X-0X0X-0X0X"? "Mgo+DSMBaFt/QHJqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5iS35XdkZmXn1aeHVRRA==;Mgo+DSMBPh8sVXJ0S0R+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31SdEVnWXxbd3ZRTmZeUA==;ORg4AjUWIQA/Gnt2VVhiQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0djXX9ddXNXQmhbVkw=;Mgo+DSMBMAY9C3t2VVhiQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQd0djXX9ddXNXQmhVUkA=" : utils.syncfusionLicenseKey;
    this.passedWatcherUtils = true;
    this.utilsSub.next();
  }

  /**
   * Return all datamodels with its validations and set all cache variables
   * @param page
   */
  public async getAllDataModels(page) {
    this.preliminaryValidations = new Map();
    this.catalogValidations = new Map();
    let datamodels_response = await this.getDatamodelValidationsFromAPI(page);
    await this.getTrainbaleDatamodels(page);
    this.dataModelPagination = datamodels_response;
    this.dataModelPagination.page_size = 10;
    datamodels_response.data.map(datamodel => {
      this.datamodels.set(datamodel.datamodelid, datamodel);
      if (datamodel['validations']['preliminary']) {
        datamodel['validations']['preliminary'].map(preliminaryValidation => {
          preliminaryValidation['catalog'] = new ValidationModel(
            0,
            undefined,
            false,
            false
          );
          this.preliminaryValidations.set(
            preliminaryValidation.validationid,
            preliminaryValidation
          );
        });
      }
      if (datamodel['validations']['catalog']) {
        datamodel['validations']['catalog'].map(catalogValidation => {
          let catalogLinkvalidation = catalogValidation.linkvalidations;
          let preliminaryValidation = {};
          this.preliminaryValidations.forEach(pv => {
            if (pv.linkvalidations === catalogLinkvalidation) {
              preliminaryValidation = pv;
            }
          });
          preliminaryValidation['catalog'] = catalogValidation;
          let preliminaryValidationId = +preliminaryValidation['validationid'];
          this.preliminaryValidations.set(
            preliminaryValidationId,
            preliminaryValidation
          );
          this.catalogValidations.set(
            catalogValidation.validationid,
            catalogValidation
          );
        });
      }
    });
    page++;
    while (page <= datamodels_response.num_pages) {
      let defaultParams = { page_size: 30, page: page, iseditable: '' };
      datamodels_response = await this.getDatamodelValidationsFromAPI(page);
      this.dataModelPagination.data.push(...datamodels_response.data);
      datamodels_response.data.map(datamodel => {
        this.datamodels.set(datamodel.datamodelid, datamodel);
        if (datamodel['validations']['preliminary']) {
          datamodel['validations']['preliminary'].map(preliminaryValidation => {
            preliminaryValidation['catalog'] = new ValidationModel(
              0,
              undefined,
              false,
              false
            );
            this.preliminaryValidations.set(
              preliminaryValidation.validationid,
              preliminaryValidation
            );
          });
        }
        if (datamodel['validations']['catalog']) {
          datamodel['validations']['catalog'].map(catalogValidation => {
            let catalogLinkvalidation = catalogValidation.linkvalidations;
            let preliminaryValidation = {};
            this.preliminaryValidations.forEach(pv => {
              if (pv.linkvalidations === catalogLinkvalidation) {
                preliminaryValidation = pv;
              }
            });
            preliminaryValidation['catalog'] = catalogValidation;
            let preliminaryValidationId =
              +preliminaryValidation['validationid'];
            this.preliminaryValidations.set(
              preliminaryValidationId,
              preliminaryValidation
            );
            this.catalogValidations.set(
              catalogValidation.validationid,
              catalogValidation
            );
          });
        }
      });
      page++;
    }
    this.passedWatcherDatamodels = true;
    this.datamodelsSub.next('');
  }

  /**
   * Returns datamodels in a way that ccan be consumed
   * get datamodels for BR
   */
  public getDataModelsFormatedBusiness() {
    const datamodelsBusiness = [];
    this.datamodels.forEach(d => {
      if (
        d.validations.business_rules &&
        d.validations.business_rules.validationid
      ) {
        datamodelsBusiness.push({
          datamodelid: d.datamodelid,
          datamodeldisplayname: d.datamodeldisplayname
        });
      }
    });
    return datamodelsBusiness;
  }

  /**
   * Returns datamodels in a way that ccan be consumed
   * get datamodels for preliminary
   */
  public getDataModelsFormatedPreliminary() {
    const datamodelsBusiness = [];
    this.datamodels.forEach(d => {
      if (d.validations.preliminary) {
        datamodelsBusiness.push({
          datamodelid: d.datamodelid,
          datamodeldisplayname: d.datamodeldisplayname
        });
      }
    });
    return datamodelsBusiness;
  }

  /**
   * returns a single datamodel
   * @param datamodelid datamodelid of datatamodel
   */
  public getDataModelById(datamodelid) {
    return this.datamodels.get(datamodelid);
  }

  /**
   * Adds or modify datamodel to work as caché for front
   * @param newDatamodel datamodel to add
   * @param isNew is new or edited
   */
  public addDatamodel(newDatamodel, isNew = false) {
    this.datamodels.set(newDatamodel.datamodelid, newDatamodel);
    if (isNew) {
      this.dataModelPagination.total_elements =
        this.dataModelPagination.total_elements + 1;
    } else {
      // pop from datamodelPagination id of datamodel and pus new
      this.dataModelPagination.data = this.dataModelPagination.data.filter(
        el => el.datamodelid !== newDatamodel.datamodelid
      );
    }
    this.dataModelPagination.data.splice(0, 0, newDatamodel);
  }

  /**
   * Update datamodel with new or edited validation
   * @param newData new data of datamodel
   */
  public async updateDatamodel(newData) {
    const datamodel = await this.getDataModelById(newData.datamodelid);
    if (newData.validationtype.validationtypeid === 1) {
      datamodel.validations.extraction = newData;
    } else if (newData.validationtype.validationtypeid === 2) {
      datamodel.validations.business_rules = newData;
    }

    this.datamodels.set(newData.datamodelid, datamodel);
  }

  /**
   * return datamodel pagination
   */
  public getDataModelDataPagination() {
    return this.dataModelPagination;
  }

  /**
   * return group of field from datamoedld by dtamodelid and groupof field id
   * @param datamodelid
   * @param groupoffieldid
   */
  public getGroupOfField(datamodelid, groupoffieldid) {
    const datamodelObj = this.getDataModelById(+datamodelid);
    return datamodelObj.groupoffields.find(
      groupoffield => groupoffield.groupoffieldsid === groupoffieldid
    );
  }

  /**
   * return fieldofinterest from datamodel by field of interestid and datamodelid
   * @param datamodelid
   * @param fieldofinterestid
   */
  public getFieldOfInterest(datamodelid, fieldofinterestid) {
    const datamodelObj = this.getDataModelById(+datamodelid);
    let fiedldOfInterestObj = { fieldofinterestdisplayname: '' };
    datamodelObj.groupoffields.map(groupoffield =>
      groupoffield.fieldsofinterest.map(fieldofinterest => {
        if (fieldofinterest.fieldofinterestid === +fieldofinterestid) {
          fiedldOfInterestObj = fieldofinterest;
        }
      })
    );
    return fiedldOfInterestObj;
  }

  /**
   * get catalog map
   */
  public getCatalogValidations() {
    return this.catalogValidations;
  }

  /**
   * get preliminary map
   */
  public getPreliminaryValidations() {
    return this.preliminaryValidations;
  }

  /**
   * Get catalog by id
   * @param id alidationid of catalog
   */
  public getCatalogValidationById(id) {
    return this.catalogValidations.get(id);
  }

  /**
   * Get preliminary by id
   * @param id validationid of preliminary
   */
  public getPreliminaryValidationById(id) {
    return this.preliminaryValidations.get(id);
  }

  /**
   * get datamodels map
   */
  public getDataModelMap() {
    return this.datamodels;
  }

  /**
   * Get decision types
   */
  public getDecisionTypes() {
    return [
      {
        eydecision: 1,
        eydecisionname: 'Recommended'
      },
      {
        eydecision: 0,
        eydecisionname: 'Not recommended'
      },
      {
        eydecision: 2,
        eydecisionname: 'Recommended or Not recommended'
      },
      {
        eydecision: 3,
        eydecisionname: 'N/A'
      }
    ];
  }

  /**
   *  update preliminary value cached variable
   * @param oldid old id from preliminary
   * @param newData  data to update
   */
  public updatePreliminary(oldid, newData) {
    if (oldid) {
      const previous = this.preliminaryValidations.get(+oldid);
      newData['catalog'] = previous['catalog'];
      this.preliminaryValidations.delete(+oldid);
    } else {
      newData['catalog'] = new ValidationModel(0, undefined, false, false);
    }

    this.preliminaryValidations.set(+newData.validationid, newData);
  }

  /**
   * update catalog value cached variable
   * @param oldid old id from catalog
   * @param newData data to update
   */
  public updateCatalog(oldid, newData) {
    if (oldid) {
      const previous = this.catalogValidations.get(+oldid);
      const catalogValidation = this.catalogValidations.get(+oldid);
      let preliminary;
      this.preliminaryValidations.forEach(pv => {
        if (pv.linkvalidations === catalogValidation.linkvalidations) {
          preliminary = pv;
        }
      });
      preliminary['catalog'] = newData;
      this.catalogValidations.delete(+oldid);
      this.catalogValidations.set(+newData.validationid, newData);
      this.preliminaryValidations.set(+preliminary.validationid, preliminary);
      // TODO: notworking cause data retrived its not valid
    } else {
      let preliminary;
      this.preliminaryValidations.forEach(pv => {
        if (pv.linkvalidations === newData.linkvalidations) {
          preliminary = pv;
        }
      });
      preliminary['catalog'] = newData;
      this.catalogValidations.set(+newData.validationid, newData);
      this.preliminaryValidations.set(+preliminary.validationid, preliminary);
    }
  }

  /**
   * delete datamodels from cached variables
   * @param datamodelsids array of datamodel ids to delete
   */
  public deleteDatamodels(datamodelsids) {
    datamodelsids.map(datamodelid => {
      this.datamodels.delete(datamodelid);
      this.dataModelPagination.data = this.dataModelPagination.data.filter(
        dmp => dmp.datamodelid !== datamodelid
      );
    });
    this.dataModelPagination.total_elements =
      this.dataModelPagination.total_elements - datamodelsids.length;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Get Validation bi validationid, this function is no longer needed
   * @param validationid
   */
  public getValidation(validationid) {
    return this.apiService
      .get(`validations/${validationid}`, null, '')
      .pipe(map((validation: ValidationModel) => validation))
      .toPromise();
  }

  /**
   * Fetch static data
   */
  private fetchData() {
    return this.apiService.get('static/', null, '').pipe(
      map(
        (data: {
          languages: any[];
          fieldtypes: any[];
          status: StatusModel[];
        }) => {
          this.setLanguages(data.languages);
          this.setFieldTypes(data.fieldtypes);
          this.setStatus(data.status);
          return data;
        }
      )
    );
  }

  /**
   * Set global project
   */

  public setProject(project: ProjectModel) {
    this.project = project;
  }

  /**
   * Get global project
   */
  public getProject(id: number | string) {
    return this.project && this.project.projectid === +id
      ? Promise.resolve(this.project)
      : this.apiService
          .get(`projects/${id}/`, null, '')
          .pipe(
            map((project: ProjectModel) => {
              this.setProject(project);
              return project;
            })
          )
          .toPromise();
  }

  /**
   * Set global analysis
   */
  public setAnalysis(analysis: AnalysisModel) {
    this.analysis = analysis;
  }

  /**
   * Get global analysis
   */
  public getAnalysis(id: number | string) {
    return this.analysis && this.analysis.analysisid === +id
      ? Promise.resolve(this.analysis)
      : this.apiService
          .get(`analysis/${id}/`, null, '')
          .pipe(
            map((analysis: AnalysisModel) => {
              this.setAnalysis(analysis);
              return analysis;
            })
          )
          .toPromise();
  }

  /**
   * Set global document
   */
  public setDocument(document: DocumentModel) {
    this.document = document;
  }

  /**
   * Get global document
   */
  public getDocument(id: number | string) {
    return this.document && this.document.documentid === +id
      ? Promise.resolve(this.document)
      : this.apiService
          .get(`documents/${id}/`, null, '')
          .pipe(
            map((document: DocumentModel) => {
              this.setDocument(document);
              return document;
            })
          )
          .toPromise();
  }

  /**
   * Set global document deatils
   */
  public emptyDocumentDetails() {
    this.documentDetails = undefined;
  }

  /**
   * Set global document deatils
   */
  public setDocumentDetails(documentDetails: DocumentFieldDataModel) {
    this.documentDetails = documentDetails;
  }

  /**
   * Get global document details
   */
  public getDocumentDetails(id: number | string) {
    return this.documentDetails && this.documentDetails.documentid === +id
      ? Promise.resolve(this.documentDetails)
      : this.apiService
          .get(`documents/${id}/`, { include_ids: 1, include_fields: 1 }, '')
          .pipe(
            map((documentDetails: DocumentFieldDataModel) => {
              this.setDocumentDetails(documentDetails);
              return documentDetails;
            })
          )
          .toPromise();
  }

  /**
   * Get global status
   */
  public getStatus() {
    return this.status
      ? Promise.resolve(this.status)
      : this.fetchData()
          .pipe(
            map(
              (data: { languages: any[]; fieldtypes: any[]; status: any[] }) =>
                data.status
            )
          )
          .toPromise();
  }

  /**
   * @param status
   */
  public setStatus(status) {
    this.status = status;
  }

  /**
   * Set validation execution data
   */
  public setValidationExecution(data: ValidationExecutionModel) {
    this.validationExecution = data;
  }

  /**
   * Set current validation type
   */
  public setCurrentValidationType(current) {
    this.currentContent = current;
  }

  /**
   * Get validation execution data
   */
  public getValidationExecution(id: number | string, update: boolean = false) {
    return this.validationExecution &&
      this.validationExecution.validationexecutionid === id &&
      !update
      ? Promise.resolve(this.validationExecution)
      : this.apiService
          .get(`validationsexecutions/${id}/`, '', '')
          .pipe(
            map((data: any) => {
              this.setValidationExecution(data);
              return data;
            })
          )
          .toPromise();
  }

  /**
   * Set global document validations (timeline data)
   */
  public setDocumentValidations(data, id) {
    this.documentValidations = data;
    this.documentValidationsId = id;
  }

  /**
   * Get global document validations (timeline data)
   */
  public getDocumentValidations(id: number | string, update: boolean) {
    return this.documentValidations &&
      this.documentValidationsId === id &&
      !update
      ? Promise.resolve(this.documentValidations)
      : this.apiService
          .get('validationsexecutions/', { documentid: id }, '')
          .pipe(
            map((documentValidations: any[]) => {
              this.setDocumentValidations(documentValidations, id);
              return documentValidations;
            })
          )
          .toPromise();
  }

  /**
   * Clean timeline variables
   */
  public emptyTimeline() {
    this.documentValidations = undefined;
    this.documentValidationsId = 0;
  }

  /**
   * Set KPIs data
   */
  public setKPIs(model: string, id: string | number, data: KPIModel) {
    this.kpis[`${model}-${id}`] = data;
    return this.kpis[`${model}-${id}`];
  }

  /**
   * Get KPIs data
   */
  public getKPIs(model: string, id: string | number) {
    return this.kpis[`${model}-${id}`]
      ? Promise.resolve(this.kpis[`${model}-${id}`])
      : this.apiService
          .get('kpis/', this.KPIsParams(model, id), '')
          .pipe(map((data: KPIModel) => data))
          .toPromise();
  }

  /**
   * Select KPIs request's params
   */
  private KPIsParams(model: string, id: string | number) {
    switch (model) {
      case 'project':
        return { projectid: id };
      case 'analysis':
        return { analysisid: id };
      case 'document':
        return { documentid: id };
      default:
        return null; // Projects list
    }
  }

  /**
   * Store chart data
   */
  public setCharts(model: string, id: string | number, data: any[]) {
    this.charts[`${model}-${id}`] = data;
    return this.charts[`${model}-${id}`];
  }

  /**
   * Return chart data
   */
  public getCharts(model: string, id: string | number) {
    return this.charts[`${model}-${id}`]
      ? Promise.resolve(this.charts[`${model}-${id}`])
      : this.apiService
          .get('overview/', this.ChartsParams(model, id), '')
          .pipe(map((data: any) => data))
          .toPromise();
  }

  /**
   * Select Charts request's params
   */
  private ChartsParams(model: string, id: string | number) {
    switch (model) {
      case 'project':
        return { projectid: id };
      case 'analysis':
        return { analysisid: id };
      case 'document':
        return { documentid: id };
    }
  }

  public getStaticData() {
    return this.fetchData().toPromise();
  }

  public getFieldTypes() {
    return this.fieldtypes
      ? Promise.resolve(this.fieldtypes)
      : this.fetchData()
          .pipe(
            map(
              (data: { languages: any[]; fieldtypes: any[]; status: any[] }) =>
                data.fieldtypes
            )
          )
          .toPromise();
  }

  public setFieldTypes(fieldtypes) {
    this.fieldtypes = fieldtypes;
  }

  /**
   * Getter for the validation status
   * constant variables
   */
  public getValidationStatusConst() {
    return validationStatusConst;
  }

  /**
   * Getter for the validation types
   * constant variables
   */
  public getValidationTypesConst() {
    return validationTypesConst;
  }

  /**
   * Getter for the current validation type
   */
  public getCurrentValidationType() {
    return this.currentContent;
  }

  /**
   * Getter for the ActivityMstr
   * constant variables
   */
  public getActivityMstrConst() {
    return activityConstants;
  }

  /**
   * Getter for the ActivityMstr
   * constant variables
   */
  public getDatamodelsConst() {
    return datamodelsConstants;
  }

  /**
   * Getter for the document status
   * constant variables
   */
  public getDocumentStatusConst() {
    return documentStatusConst;
  }

  /**
   * set app tittle
   * @param appTittle tittle to set
   */
  public setAppTittle(tenantdisplayname: string) {
    const appTittle = tenantdisplayname ? tenantdisplayname : 'SIRE';
    this.appTittle = appTittle;
  }

  /**
   * get app title
   */
  public getAppTitle() {
    return this.appTittle;
  }

  /**
   * Set global querysheet
   */

  public setQuerysheet(querysheet) {
    this.querysheet = querysheet;
  }

  /**
   * Get global querysheet
   */
  public getQuerysheet() {
    return this.querysheet;
  }

  /**
   * Set global currentTab
   */

  public setCurrentTab(currentTab) {
    this.currentTab = currentTab;
  }
  /**
   * Get global currentTab
   */
  public getCurrentTab() {
    return this.currentTab;
  }

  /**
   * Fetch query sheet details along with query tree nodes
   */
  public fetchQuerySheetDetails(id: number | string) {
    return this.apiService
      .get(`querysheets/${id}/`, null, '')
      .pipe(
        map(response => {
          this.setQuerysheet(response);
          return response;
        })
      )
      .toPromise();
  }

  /**
   * Set global value for selected exploration
   */
  public setExploration(exploration) {
    this.exploration = exploration;
  }

  /**
   * Get global value for selected querysheet
   */
  public getExploration() {
    return this.exploration;
  }

  /**
   * Set global value for selected feedbacks
   */
  public setSelectedFeedbacks(feedbacks: any) {
    this.selectedFeedbacks = JSON.parse(JSON.stringify(feedbacks));
  }

   /**
   * Get global value for selected feedbacks
   */
   public getSelectedFeedbacks() {
    return this.selectedFeedbacks;
  }

  /**
   * Fetch exploration details
   */
  public fetchExplorationDetails(id: number | string) {
    return this.apiService
      .get(`explorations/${id}/`, null, '')
      .pipe(
        map(response => {
          this.setExploration(response);
          return response;
        })
      )
      .toPromise();
  }

  /**
   * restore global variables to empty
   */
  public cleanData() {
    this.setProject(null);
    this.setAnalysis(null);
    this.setDocument(null);
    this.setDocumentDetails(null);
    this.setLanguages([]);
    this.setReviewers([]);
    this.setStatus(null);
    this.setRoles([]);
    this.setGlobalStatus([]);
    this.setFieldTypes(null);
    this.setValidationStatus([]);
    this.setValidationTypes([]);
    this.setConsolidations([]);
    this.setEnrichmentTypes([]);
    this.setErrorsMapping([]);
    this.setFunctions([]);
    this.setValidationExecution(null);
    this.setDocumentValidations([], null);
    this.setActivities([]);
    this.setAppTittle(null);
    this.getStaticData();
    this.setQuerysheet(null);

    this.kpis = {};
    this.charts = {};

    this.datamodels = new Map();
    this.preliminaryValidations = new Map();
    this.catalogValidations = new Map();
  }

  /**
   * clean and reset data for new tenant id
   */
  public resetData(tenant) {
    this.passedWatcherDatamodels = false;
    this.passedWatcherUtils = false;
    this.cleanData();
    this.validationSearchService.cleanFilters();
    this.datamodelSearchService.cleanFilters();
    this.cognitiveSearchService.setPreviousSearch(undefined);
    this.setAppTittle(tenant.tenantdisplayname);
    this.getAllDataModels(this.page);
    this.getUtils();
  }

  /**
   * Getter for the user permissions
   * constant variables
   */
  public getUserPermissionsConst() {
    return userPermissionsConst;
  }

  public async getTrainbaleDatamodels(page) {
    const trainable_datamodels_response =
      await this.getTrainableDatamodelsFromAPI(page);
    this.trainablePagination = trainable_datamodels_response;
    this.trainablePagination.page_size = 10;
    trainable_datamodels_response.data.map(datamodel => {
      this.trainableDatamodels.set(datamodel.datamodelid, datamodel);
    });
  }

  /**
   * get datamodels map
   */
  public getTrainableDatamodelsMap() {
    return this.trainableDatamodels;
  }

  /**
   * returns a single trainable datamodel
   * @param datamodelid datamodelid of datatamodel
   */
  public getTrainableById(datamodelid) {
    return this.trainableDatamodels.get(datamodelid);
  }

  /**
   * Adds or modify datamodel to work as caché for front
   * @param newDatamodel datamodel to add
   * @param isNew is new or edited
   */
  public addTrainableDatamodel(newDatamodel, isNew = false) {
    this.trainableDatamodels.set(newDatamodel.datamodelid, newDatamodel);
    if (isNew) {
      this.trainablePagination.total_elements++;
    } else {
      this.trainablePagination.data = this.trainablePagination.data.filter(
        el => el.datamodelid !== newDatamodel.datamodelid
      );
    }
    this.trainablePagination.data.splice(0, 0, newDatamodel);
  }

  /**
   * Update datamodel with new or edited validation
   * @param newData new data of datamodel
   */
  public updateTrainableDatamodel(newData) {
    const trainable = this.getTrainableById(newData.datamodelid);
    this.trainableDatamodels.set(newData.datamodelid, trainable);
  }

  /**
   * delete datamodels from cached variables
   * @param datamodelsids array of datamodel ids to delete
   */
  public deleteTrainableDatamodels(datamodelsids) {
    datamodelsids.pipe(
      map(datamodelid => {
        this.trainableDatamodels.delete(datamodelid);
        this.trainablePagination.data = this.trainablePagination.data.filter(
          dmp => dmp.datamodelid !== datamodelid
        );
      })
    );
    this.trainablePagination.total_elements -= datamodelsids.length;
  }

  /**
   * return datamodel pagination
   */
  public getTrainablePagination() {
    return this.trainablePagination;
  }

  /**
   * Function to redirect user to the correct url after change client or first login
   * tenant
   * user
   */
  public redirectUserHomeURL(tenant: TenantModel) {
    const user = this.authService.getLoggedInUser();
    if (
      this.authService.hasPermission(
        userPermissionsConst.SCREEN_CLIENT_DOCUMENTS
      ) &&
      !user.isadmin
    ) {
      this.router.navigate([`/${tenant.tenantname}/externalViewer`]);
    } else {
      this.router.navigate([`/${tenant.tenantname}/${user.homeurl}`]);
    }
  }

  public getDocumentsToConsolidate(projectid, consolidatedtypeid) {
    const params = {
      projectid: projectid,
      consolidatedtypeid: consolidatedtypeid
    };
    return this.apiService
      .get('consolidateddocuments/documents/', params, '')
      .pipe(map(res => res))
      .toPromise();
  }

  /**
   * Return the precondition to readable string
   *  @param datamodelId The datamodel ID which is the context of the precondition.
   *  @param rawPrecondition The precondition string in "foiId operator value" format.
   */
  public preconditionToString(datamodelId: String, rawPrecondition: String) {
    const splittedString = rawPrecondition.split(' ');
    const foi = splittedString[0];
    let operator = '';
    switch (splittedString[1]) {
      case 'gt':
        operator = ' greater than ';
        break;
      case 'gte':
        operator = 'greater or equal to  ';
        break;
      case 'lt':
        operator = ' less than ';
        break;
      case 'lte':
        operator = ' less or equal to ';
        break;
      case 'e':
        operator = ' equals to ';
        break;
    }
    const value = splittedString[2];
    const foiObj = this.getFieldOfInterest(datamodelId, foi);
    const displayName = foiObj ? foiObj.fieldofinterestdisplayname : '';
    let logicOperator = '';
    if (splittedString.length === 4) {
      logicOperator = splittedString[3] === 'or' ? 'Or ' : '';
    }
    return logicOperator + displayName + operator + value;
  }
}
