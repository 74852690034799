import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

import { Reason } from '../../../models/close-reason.model';
import { ApiService } from '../../../services/api.service';
import { PaginationService } from '../../../services/pagination.service';
import { PaginationModel } from '../../../models/pagination';
import { ProjectModel } from '../../../models/project-mstr.model';
import { AnalysisModel } from '../../../models/analysis.model';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { CreateExplorationStepComponent } from './create-exploration-step/create-exploration-step.component';
import { GlobalService } from 'src/app/services/global.service';
import { QuerySheetModel } from 'src/app/models/querysheet-mstr.model';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { AlertService } from 'src/app/services/alert.service';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-create-explorations',
  templateUrl: './create-explorations.component.html',
  styleUrls: ['./create-explorations.component.scss']
})
export class CreateExplorationsComponent implements OnInit {
  @Input() options;
  public addExpNameForm: FormGroup;
  public querysheetDetails: QuerySheetModel = null;
  public tenantName: any;
  public reason = Reason;
  public activeStep: number = 0;
  public searchString: string = '';
  public dataFilterInProgress: boolean = false;
  public projectData: Object = null;
  public unfilteredProjectData: Object = null;
  public analysisData: Object = null;
  public unfilteredAnalysisData: Object = null;
  public documentsData: Object = null;
  public unfilteredDocumentsData: Object = null;
  public selectedProjects: Array<number> = [];
  public selectedAnalysis: Array<number> = [];
  public selectedAnalysisObjList: Object = null;
  public selectedDocuments: Array<number> = [];
  public loading: boolean = false;
  public pagination: PaginationModel;
  public getDataFromDBSettings: Object;
  public explorationName: string = ''
  public isEditingExploration: boolean = false;
  public isExplorationModified: boolean = false;
  public items: Array<any> = [{
    step: 0,
    name: this.translate.transform("createExploration.selectProject"),
    showPrevBtn: false,
    showNextBtn: true,
    disableNextBtn: true
  },
  {
    step: 1,
    name: this.translate.transform("createExploration.selectAnalysis"),
    showPrevBtn: true,
    showNextBtn: true,
    disableNextBtn: true
  },
  {
    step: 2,
    name: this.translate.transform("createExploration.selectDocuments"),
    showPrevBtn: true,
    showNextBtn: true,
    disableNextBtn: true
  },
  {
    step: 3,
    name: this.translate.transform("createExploration.createExploration"),
    showPrevBtn: true,
    showNextBtn: false,
    isLastStep: true
  }
  ];
  public toggleSelectAllAnalysis: boolean = false;
  public setSelectedDocIds = [];
  public explorationNameValidations: Array<String> = [];
  public isSingleProjectResponse: boolean = false;

  @ViewChild(CreateExplorationStepComponent)
  refObjCreateExplorationStepComp;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private translate: TranslatePipe,
    private paginationService: PaginationService,
    private globalService: GlobalService,
    private alertService: AlertService,
    private linkService: LinkService

  ) {
    this.tenantName = this.authService.getTenantName();
  }

  ngOnInit(): void {
    // Initialize pagination to default
    this.pagination = this.paginationService.getDefaultPagination('createExploration');
    this.activeStep = 0;
    this.getProjectsFromDB();
    this.querysheetDetails = this.globalService.getQuerysheet();
    if (this.options?.explorationDetails) {
      this.explorationName = this.options?.explorationDetails?.explorationName;
      this.isEditingExploration = true;
      if (this.options?.explorationDetails.projects.length > 0) {
        this.options.explorationDetails.projects.forEach(project => {
          this.selectedProjects.push(project.projectId)
        })
        this.items[0].disableNextBtn = !this.selectedProjects.length;
      }
      if (this.options?.explorationDetails.analysis_list.length > 0) {
        this.options.explorationDetails.analysis_list.forEach(analysis => {
          this.selectedAnalysis.push(analysis.analysisId)
        })
        this.items[1].disableNextBtn = !this.selectedAnalysis.length;
      }
      if (this.options?.explorationDetails.document_list.length > 0) {
        this.options.explorationDetails.document_list.forEach(document => {
          this.selectedDocuments.push(document.documentId)
        })
        this.items[2].disableNextBtn = !this.selectedDocuments.length;
      }
    }
    
    this.getDataFromDBSettings = {
      getProjectsFromDB: this.getProjectsFromDB.bind(this),
      getAnalysisFromDB: this.getAnalysisFromDB.bind(this),
      getDocumentsFromDB: this.getDocumentsFromDB.bind(this)
    };
    this.checkIfExplorationChanged = this.checkIfExplorationChanged.bind(this)
  }

  closeModal(): void {
    this.activeModal.close(this.reason.closeBtn);
  }

  goToPrevStep() {
    this.searchString = "";
    // Clearing data for the current step, if user goes to rpevious step
    switch (this.activeStep) {
      case 1:
        //User is coming from Select Analysis to Select project
        this.setDefaultData(this.projectData, this.unfilteredProjectData);
        this.analysisData = { 'data': [] };
        this.selectedAnalysis = [];
        this.selectedAnalysisObjList = {
          'data': []
        };
        this.paginationService.changePagination('createExploration', 'order_by', 'analysisname');
        this.items[1].disableNextBtn = true;
        break;

      case 2:
        //User is coming from Select Document to Select Analysis
        //Setting default sort order to 'documentname'
        this.setDefaultData(this.analysisData, this.unfilteredAnalysisData);
        this.paginationService.changePagination('createExploration', 'order_by', 'documentname');
        this.documentsData = { 'data': [] };
        this.selectedDocuments = [];
        this.items[2].disableNextBtn = true;

        // Reset toggleAllAnalysis flag and selectedAnalysisObjList to defaults
        this.toggleSelectAllAnalysis = false;
        this.selectedAnalysisObjList = this.analysisData;
        this.selectedAnalysisObjList['data'].forEach(analysis => {
          analysis['isAnalysisRowSelected'] = this.toggleSelectAllAnalysis;
        })
        break;

      case 3:
        this.items[3].disableNextBtn = true;
        break;
    }
    this.activeStep -= 1;
  }

  setDefaultData(filteredData, unfilteredData) {
    if (filteredData && filteredData['data'] && unfilteredData && unfilteredData['data']) {
      filteredData['data'] = [...unfilteredData['data']]
    }
  }

  setFilteredData(searchString, filteredData, unfilteredData, name) {
    if (!searchString) {
      filteredData['data'] = [...unfilteredData['data']];
    } else {
      filteredData['data'] = filteredData['data'].filter(data => {
        return data[name].toLowerCase().indexOf(searchString.toLowerCase()) > - 1
      });
    }
  }

  setSearchString(event) {
    this.searchString = event.target.value;
    if (!this.searchString) {
      this.dataFilterInProgress = true;
      if (this.activeStep == 0) {
        this.setDefaultData(this.projectData, this.unfilteredProjectData);
      } else if (this.activeStep == 1) {
        this.setDefaultData(this.analysisData, this.unfilteredAnalysisData)
      } else if (this.activeStep == 2) {
        this.setDefaultData(this.documentsData, this.unfilteredDocumentsData)
      }
      setTimeout(() => {
        this.dataFilterInProgress = false;
      }, 200)
    }
  }

  searchItems() {
    this.dataFilterInProgress = true;
    if (this.activeStep == 0) {
      this.setFilteredData(this.searchString, this.projectData, this.unfilteredProjectData, 'projectname')
    } else if (this.activeStep == 1) {
      this.setFilteredData(this.searchString, this.analysisData, this.unfilteredAnalysisData, 'analysisname')
    } else {
      this.setFilteredData(this.searchString, this.documentsData, this.unfilteredDocumentsData, 'documentname')
    }
    setTimeout(() => {
      this.dataFilterInProgress = false;
    }, 200)
  }

  goToNextStep(steps?) {
    this.searchString = "";
    let caseValue = 0;

    if(steps){
      caseValue = steps;
    }else {
      this.activeStep += 1;
      caseValue = this.activeStep;
    }

    switch (caseValue) {
      case 1:
        //Setting default sort order to 'analysisname'
        this.paginationService.changePagination('createExploration', 'order_by', 'analysisname');
        this.getAnalysisFromDB(steps);
        break;

      case 2:
        //Setting default sort order to 'documentname'
        this.paginationService.changePagination('createExploration', 'order_by', 'documentname');
        this.getDocumentsFromDB();
        break;
    }
  }

  /**
  * On click of Finish button , fetch the child varible and trigger api
  */
  createExploration(): void {
    const explorationName = this.refObjCreateExplorationStepComp.addExpNameForm.value.addexplorationname;

    let createExplorationParams = {
      "explorationName": this.refObjCreateExplorationStepComp.addExpNameForm.value.addexplorationname,
      "querySheetId": this.querysheetDetails.querySheetId,
      "documentId": this.refObjCreateExplorationStepComp.selectedDocumentIds
    }

    if (explorationName !== null && explorationName !== "") {
      this.saveExplorationToDB(createExplorationParams);

    } else {
      this.refObjCreateExplorationStepComp.addExpNameForm.get('addexplorationname').markAsTouched();
    }
  }

  editExploration(): void {
    this.loading = true;
    let editExplorationParams = {
      "explorationName": this.explorationName,
      "documentId": this.selectedDocuments
    }
    this.apiService
      .put(`explorations/${this.options.explorationId}/update`, '', editExplorationParams, '',  true, {}, false, true)
      .subscribe(
        event => {
          // Edit Exploration
          if (event) {
            this.loading = false;
            setTimeout(() => {
              this.activeModal.close(this.reason.submitBtn);
              this.linkService.goToQuerySheetsManagement('explorationList');
              this.alertService.success(
                this.translate.transform(
                  'createExploration.explorationUpdatedSuccessfully'
                )
              );
            }, 1000)
          }
        },
        error => {
          const errorMsg = error.error;
          if ([400, 409, 424].includes(error.status)) {
            // 400 bad request
            // 409 - Exploration name already exists
            // 424 - FAILED DEPENDENCY (No new documents identified, Invalid document selected, )
            this.explorationNameValidations = errorMsg;
            this.refObjCreateExplorationStepComp.addExpNameForm.get('addexplorationname').setErrors({ 'apiError': true })
          }
          this.loading = false;
        }
      );
  }
  /*on unselecting project or analysis, need to remove analysisIds 
  and documentIds from this.selectedAnalysis and this.selectedDocuments*/
  removeAnalysisAndDocuments(operationType): void {
    const newDocumentList = []
    if (operationType == 'project') {
      const newAnalysisList = []
      this.options.explorationDetails.analysis_list.forEach((analysis) => {
        if (this.selectedProjects.indexOf(analysis.projectId) > -1) {
          newAnalysisList.push(analysis.analysisId)
        }
      })
      this.options.explorationDetails.document_list.forEach((document) => {
        if (newAnalysisList.indexOf(document.analysisId) > -1) {
          newDocumentList.push(document.documentId)
        }
      })
      if(this.selectedDocuments.length != newDocumentList.length){
        this.isExplorationModified = true;
      }
      this.selectedAnalysis = [...newAnalysisList];
    } else if (operationType == 'analysis') {
      this.options.explorationDetails.document_list.forEach((document) => {
        if (this.selectedAnalysis.indexOf(document.analysisId) > -1) {
          newDocumentList.push(document.documentId)
        }
      })
    }
    if(this.selectedDocuments.length != newDocumentList.length){
      this.isExplorationModified = true;
    }
    this.selectedDocuments = [...newDocumentList];
  }
  checkIfExplorationChanged(item, explorationName = ""): void {
    //Enable finish button only when documents or exploration name gets modified
    if(item == 'project'){
      const removedItem = this.options.explorationDetails.projects.find(project => this.selectedProjects.indexOf(project.projectId) == -1)

      if(removedItem || this.options.explorationDetails.projects.length != this.selectedProjects.length){
        if (removedItem) {
          this.removeAnalysisAndDocuments(item)
        }
      }
    } else if(item == 'analysis'){
      const removedItem = this.options.explorationDetails.analysis_list.find(analysis => this.selectedAnalysis.indexOf(analysis.analysisId) == -1)
      if(removedItem || this.options.explorationDetails.analysis_list.length != this.selectedAnalysis.length){
        if (removedItem) {
          this.removeAnalysisAndDocuments(item)
        }
      }
    } else if(item == 'document'){
      const removedItem = this.options.explorationDetails.document_list.find(document => this.selectedDocuments.indexOf(document.documentId) == -1)
      if(removedItem || this.options.explorationDetails.document_list.length != this.selectedDocuments.length){
        this.isExplorationModified = true;
      }
    } else if(item == 'explorationName') {
      if(explorationName != "" && explorationName != this.explorationName){
        this.explorationName = explorationName;
        this.isExplorationModified = true;
      } else if(!explorationName){
        this.isExplorationModified = false;
      }
      
    }
  }

  getSelectedSummaryDocmentIds(ids) {
    this.setSelectedDocIds = ids;
  }

  setSelectedProjects(projectIds) {
    this.selectedProjects = projectIds;
    // Managing disable/enable next button on the step
    this.items[0].disableNextBtn = !this.selectedProjects.length;
    if(this.isEditingExploration){
      this.checkIfExplorationChanged('project')
    }
  }

  setSelectedAnalysis(analysisIds) {
    this.selectedAnalysis = analysisIds;
    if (this.analysisData && Array.isArray(this.analysisData['data'])) {
      let selectedAnalysisList = this.analysisData['data'].filter(data => {
        return this.selectedAnalysis.indexOf(data.analysisid) > -1
      })
      this.selectedAnalysisObjList = {
        'data': selectedAnalysisList
      }
    }
    //Managing disable/enable next button on the step
    this.items[1].disableNextBtn = !this.selectedAnalysis.length;
    if(this.isEditingExploration){
      this.checkIfExplorationChanged('analysis')
    }
  }

  setSelectedDocuments(documentIds) {
    this.selectedDocuments = documentIds;
    // Managing disable/enable next button on the step
    this.items[2].disableNextBtn = !this.selectedDocuments.length;
    if(this.isEditingExploration){
      this.checkIfExplorationChanged('document')
    }
  }
  toggleSelectAllAnalysisCheckbox() {
    this.toggleSelectAllAnalysis = !this.toggleSelectAllAnalysis;
    return this.toggleSelectAllAnalysis;
  }

  selectAllAnalysis() {
    this.toggleSelectAllAnalysis = !this.toggleSelectAllAnalysis;
    const selectedDocumentIds = [];

    const getOnlySuccessAndInprogressDocsObj = this.documentsData['data'].filter((doc: any) => doc.status !== 'Failure');
    getOnlySuccessAndInprogressDocsObj.forEach(documentData => {
      this.toggleSelectAllAnalysis && selectedDocumentIds.push(documentData.documentid);
      documentData.isRowSelected = this.toggleSelectAllAnalysis
    })
    this.setSelectedDocuments(selectedDocumentIds);
    this.selectedAnalysisObjList['data'].forEach(analysis => {
      analysis['isAnalysisRowSelected'] = this.toggleSelectAllAnalysis;
    })
    this.selectedAnalysisObjList['data'].forEach(selectedAnalysis => {
      selectedAnalysis.tableSettings.allRowsSelected = this.toggleSelectAllAnalysis;
    })
  }


  /**
   * Fetching data for projects from DB
   */
  public getProjectsFromDB() {
    this.loading = true;
    // Fetching all the project records from DB
    const allRecordPagination = { ...this.pagination, page_size: 'max' };
    this.apiService.get('projects/', allRecordPagination, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: ProjectModel[];
      }) => {
        this.pagination = this.paginationService.setPagination('createExploration', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.projectData = data;
        this.unfilteredProjectData = { ...data };
        this.unfilteredProjectData['data'] = [...data['data']];

        if (data.data.length <= 1) {
          this.isSingleProjectResponse = true;
          this.selectedProjects[0] = data.data[0].projectid;
          this.goToNextStep(1);

        } else {
          this.loading = false;
        }

      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Fetching data for analysis from DB
   */
  public getAnalysisFromDB(isAutoSwitch?) {
    this.loading = true;
    const allRecordPagination = { ...this.pagination, page_size: 'max', order_by: 'projectid' };
    // TO DO: Send selected project ids once BE api is ready
    let params = { ...allRecordPagination, 'projectid': this.selectedProjects.length > 1 ? this.selectedProjects.join(',') : this.selectedProjects[0] };
    this.apiService
      .get('analysis/', params, '').subscribe(
        (data: {
          total_elements: number;
          page: number;
          page_size: number;
          num_pages: number;
          order_by: string;
          desc: number;
          data: AnalysisModel[];
        }) => {
          this.pagination = this.paginationService.setPagination('createExploration', {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          });
          this.analysisData = data;
          this.unfilteredAnalysisData = { ...data };
          this.unfilteredAnalysisData['data'] = [...data['data']];
          this.loading = false;

          //check for single project and single analysis
          if (this.isSingleProjectResponse && data.data.length == 1 && isAutoSwitch) {
            this.selectedAnalysis[0] = data.data[0].analysisid;
            this.selectedAnalysisObjList = {
              'data': data.data
            }
            this.goToNextStep(2);
          } else {
            this.activeStep = 1;
          }

        },
        () => {
          this.loading = false;
        }
      );
  }

  /**
   * Fetching data for documents from DB
   */
  public getDocumentsFromDB() {
    this.loading = true;
    const allRecordPagination = { ...this.pagination, page_size: 'max' };
    // TO DO: Send selected analysis ids once BE api is ready
    let params = { ...allRecordPagination, 'analysisid': this.selectedAnalysis.length > 1 ? this.selectedAnalysis.join(',') : this.selectedAnalysis[0] };
    this.apiService
      .get('documents/', params, '').subscribe(
        (data: {
          total_elements: number;
          page: number;
          page_size: number;
          num_pages: number;
          order_by: string;
          desc: number;
          data: any[];
        }) => {
          this.pagination = this.paginationService.setPagination('createExploration', {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          });
          if (this.options?.explorationDetails && this.options?.explorationDetails.document_list.length > 0) {
            //below if condition block is for scenario of edit exploration. When user is in select document phase,
            //and all the documents are selected, then select the select all analysis checkbox
            if(this.selectedDocuments.length == data.total_elements){
              this.toggleSelectAllAnalysis = true;
            }
          }
          this.documentsData = data;
          this.unfilteredDocumentsData = { ...data };
          this.unfilteredDocumentsData['data'] = [...data['data']];
          this.loading = false;
          this.activeStep = 2;
        },
        () => {
          this.loading = false;
        }
      );
  }


  /**
   * Save Exploration data to exploration DB
   */
  public saveExplorationToDB(param) {
    this.loading = true;

    // TO DO: Send selected documentId ids once BE api is ready
    this.apiService
      .post('explorations', param, '', true, {}, false, true)
      .subscribe(
        event => {
          // Create Exploration has been created successfully
          if (event) {
            this.alertService.success(
              this.translate.transform(
                'createExploration.explorationCreatedSuccessfully'
              )
            );

            this.loading = false;
            setTimeout(() => {
              this.activeModal.close(this.reason.submitBtn);
              this.linkService.goToQuerySheetsManagement('explorationList');
            }, 1000)
          }
        },
        error => {
          const errorMsg = error.error;
          if ([400, 409].includes(error.status)) {
            // 400 bad request
            // 409 - Exploration name already exists
            this.explorationNameValidations = errorMsg;
            this.refObjCreateExplorationStepComp.addExpNameForm.get('addexplorationname').setErrors({ 'apiError': true })
          }
          this.loading = false;
        }
      );
  }
}

