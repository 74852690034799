import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { GlobalService } from 'src/app/services/global.service';
import { LinkService } from 'src/app/services/link.service';
import { DocumentFieldDataModel } from 'src/app/models/document-field-data.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { ValidationTypeModel } from 'src/app/models/validation-type-mstr.model';
import { ValidationService } from '../datamodels/datamodel-validation-list/validation/new-validation/common-components/validation.service';
import { Subscription } from 'rxjs';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { EventTrackerService } from 'src/app/services/event-tracker.service';
import { DocumentValidationService } from './document-validation/document-validation.service';
import { PagesSelection } from 'src/app/components/modals/update-children/pages-selection';

class NavigationMenuItem {
  content: string;
  label: string;
}

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.pages.html',
  styleUrls: ['./document-details.pages.scss']
})
export class DocumentDetailsPages implements OnInit, OnDestroy {
  public user: any = {};

  public documentId: string;
  public documentDetails: any = {
    documentname: 'Document',
    documentdisplayname: ' ',
    extracteddatalist: [],
    datamodel: {},
    status: null,
    statusmessage: null
  };
  public currentValidation: any;
  public currentContent: string;
  private autoChangeContent: boolean = true;
  public navigationMenu: NavigationMenuItem[];
  public showValidations: boolean = false;
  public showDashboard: boolean = false;
  public dashboardModel: string;
  public isLoadingDocumentFields: boolean = true;
  public loadingTimeline: boolean = true;
  public loadingCollapsible: boolean = true;
  public isExternal: boolean = false;
  public onlyOverview: boolean = false;
  public loadingChecklist: boolean = true;
  public currentContentGenAI: string | null = null;

  public tests: any;
  public validationsTypes: ValidationTypeModel[];
  public validationExecutions: any[];
  public elements: Array<any>;
  public subscriptionUtils: Subscription;
  public _validationStatus: any;
  public _validationTypes: any;
  public _userPermissions: any;
  public _documentStatus: any;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private documentRenderService: DocumentRenderService,
    private router: Router,
    private route: ActivatedRoute,
    private global: GlobalService,
    private validationService: ValidationService,
    public link: LinkService,
    private translate: TranslatePipe,
    private trackerService: EventTrackerService,
    private validationStatusService: DocumentValidationService
  ) {
    // Check user permission to use this component
    this.user = authService.getLoggedInUser();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );

    this._validationStatus = this.global.getValidationStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this._documentStatus = this.global.getDocumentStatusConst();

    this.global.setCurrentValidationType(this._validationTypes.EXTRACTION);

    this.controlPermissionAccess();
  }

  ngOnInit() {
    this.validationStatusService.cleanCollapsibleState();
    this.validationsTypes = this.global.getValidationTypes();
    if (this.validationsTypes.length === 0 && !this.global.passedWatcherUtils) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsTypes = this.global.getValidationTypes();
          this.initDocumentDetails();
        });
    } else {
      this.initDocumentDetails();
    }
  }

  public initDocumentDetails() {
    this.documentRenderService.emptyAll();

    this.route.params.subscribe((params: Params) => {
      const documentId = this.link.getIdFromSlug(params.documentidname);
      this.documentId = documentId;
      this.getElementsTimeline(documentId, false);
      this.getDocumentFromDB(documentId);
      this.documentRenderService.setDocumentId(documentId);
      this.documentRenderService.setMainDocumentId(documentId);
      this.trackerService.sendEventTracked(
        this.router.url,
        4,
        'Click timeline: ' + this._validationTypes.EXTRACTION
      );
    });
  }

  public controlPermissionAccess() {
    const url = this.router.parseUrl(this.router.url);
    if (url.root.children.primary) {
      const segments = url.root.children.primary.segments;
      const parentRouting = segments[1].path;

      if (
        parentRouting === 'externalViewer' &&
        (this.authService.hasPermission(
          this._userPermissions.SCREEN_CLIENT_DOCUMENTS
        ) ||
          this.user.isadmin)
      ) {
        this.isExternal = true;
      }
    }
  }

  /**
   * Get document details (document and fields) from global service
   */
  public getDocumentFromDB(documentId) {
    this.global
      .getDocumentDetails(documentId)
      .then((document: DocumentFieldDataModel) => {
        // TODO: Check if document slug is correct. If not, redirect
        // this.link.checkDocumentSlug(document.documentname, document);
        this.documentDetails = document;
        this.setDocumentViewContent();
        this.loadingTimeline = false;
        this.loadingChecklist = false;
        if (this.autoChangeContent) {
          if (this.checkStatus() && !this.isExternal) {
            this.goToContent('overview');
          } else {
            this.goToContent(this._validationTypes.EXTRACTION);
          }
        }
        this.documentRenderService.setPages(document.numpages);
        this.documentRenderService.updateStartDocumentThumbnailsProvider();
        if (this.checkIsElectronic()) {
          this.documentRenderService.getDocumentImagesFromDB().then(
            () => {},
            error => {
              console.log(error);
            }
          );
          this.documentRenderService.getDocumentChunksFromDB();
        }
        this.isLoadingDocumentFields = false;
        this.loadingChecklist = false;
      });
  }

  /**
   * Set which content is displayed.
   */
  private setDocumentViewContent(): void {
    if (this.canViewDashboard()) {
      this.showDashboard = true;
      this.currentContent = 'dashboard';
      this.autoChangeContent = false;

      this.navigationMenu = [
        {
          content: 'dashboard',
          label: 'documentDetails.seeDashboard'
        },
        {
          content: 'overview',
          label: 'documentDetails.seeDetails'
        },
        {
          content: this._validationTypes.EXTRACTION,
          label: 'documentDetails.seeValidations'
        }
      ];
    } else {
      this.currentContent = this._validationTypes.EXTRACTION;
    }
  }

  /**
   * Check if user can view dashboard.
   *
   * Set dashboard model.
   */
  private canViewDashboard(): boolean {
    if (
      !this.authService.userCanViewModule(
        this.user,
        'DocumentDashboardsFunctionality'
      )
    ) {
      return false;
    }
    const datamodel = this.global.getDataModelById(
      this.documentDetails.datamodel.datamodelid
    );
    try {
      const datamodelDashboards = datamodel.dashboards;
      if (!datamodelDashboards) {
        return false;
      }
      this.dashboardModel = datamodelDashboards;
    } catch (e) {
      return false;
    }
    return true;
  }

  public checkStatus() {
    if (
      [
        this._documentStatus.FAILURE,
        this._documentStatus.IN_PROGRESS,
        this._documentStatus.PENDING
      ].includes(this.documentDetails.status_data.class)
    ) {
      this.onlyOverview = true;
    }
    return this.onlyOverview;
  }

  /**
   * Retrieve title for link to the other content.
   */
  public getLinkToContentDescription() {
    if (!this.documentDetails) {
      return '';
    }
    return this.currentContent === 'overview'
      ? !this.documentDetails.isparentdocument
        ? this.translate.transform('documentDetails.seeValidations')
        : this.translate.transform('documentDetails.seeChildList')
      : this.translate.transform('documentDetails.seeDetails');
  }

  /**
   * Retrieve document detail status message.
   * Translate will be done if has translation.
   */
  public getDocumentsDetailStatusMessage() {
    if (!this.documentDetails) {
      return '';
    }
    if (
      this.documentDetails.statusmessage.split('.').length > 1 &&
      this.translate.transform(
        `errors.${this.documentDetails.statusmessage}`
      ) === this.documentDetails.statusmessage.split('.')[0]
    ) {
      return this.documentDetails.statusmessage;
    }
    return this.translate.transform(
      `errors.${this.documentDetails.statusmessage}`
    );
  }

  /**
   * Change validation tab.
   * Called when user clicks on a timeline's point.
   */
  public onChangeTimeline(point) {
    if (this.autoChangeContent) {
      this.currentContent = point.label;
    }
    this.global.setCurrentValidationType(point.label);
    this.currentValidation = point;

    if (
      [
        this._validationTypes.PRELIMINARY,
        this._validationTypes.CATALOG
      ].includes(this.currentContent)
    ) {
      this.currentValidation.validations = this.validationExecutions.filter(
        e => e.validationtypeid === this.currentValidation.validationtypeid
      );
    }
    this.currentValidation.validationtypename = this.currentContent;
  }

  public isValidationContent() {
    return (
      [
        this._validationTypes.EXTRACTION,
        this._validationTypes.BUSINESS_RULES,
        this._validationTypes.PRELIMINARY,
        this._validationTypes.CATALOG
      ].indexOf(this.currentContent) > -1
    );
  }

  /**
   * Called when user clicks tab button in navigation menu.
   */
  public onClickNavigationTab(content: string): void {
    if (content !== this.currentContent) {
      this.goToContent(content);
    }
  }

  /**
   * Switch displayed content.
   */
  public goToContent(content: string): void {
    if(content === 'genaiqna'){
      this.currentContentGenAI = content;
    } else {
      if (this.currentContent === content) {
        this.currentContent = this._validationTypes.EXTRACTION;
        this.documentRenderService.emptyImages();
        this.documentRenderService.emptyPages();
        this.documentRenderService.setDocumentId(this.documentDetails.documentid);
        this.documentRenderService.setPages(this.documentDetails.numpages);
        this.documentRenderService.updateStartDocumentThumbnailsProvider();
        //check if it is electronic before sending this request to docpictures
        if (this.checkIsElectronic()) {
          this.documentRenderService.getDocumentImagesFromDB();
        }
      } else {
        this.currentContent = content;
      }
      this.autoChangeContent = true;
      this.currentContentGenAI = null;
    }
  }

  /**
   * True if timeline is enabled.
   */
  public isTimelineEnabled() {
    return (
      this.currentContent !== 'overview' && this.currentContent !== 'dashboard'
    );
  }

  /**
   * Set default timeline data with defined Extraction status
   */
  public setBaseTimeline(status: string = this._validationStatus.PENDING) {
    this.elements = [
      {
        label: this._validationTypes.EXTRACTION,
        validationtypeid: this._validationTypes.ID_EXTRACTION,
        status: status,
        statusPoint: status
      }
    ];
  }

  /**
   * Gets document validations from database
   */
  public getElementsTimeline(documentId, update) {
    if (this.showValidations) {
      this.global
        .getDocumentValidations(documentId, update)
        .then((validations: any[]) => {
          if (validations.length > 0) {
            this.elements = this.serializeValidationExecution(validations);
            this.updateStatusPoint();
            this.updateFailuresCatalog();
            this.validationExecutions = this.elements;
          } else {
            this.setBaseTimeline();
            this.currentValidation = this.elements[0];
          }
        });
    } else {
      this.setBaseTimeline();
    }
  }

  /**
   * Gets document validations from database
   */
  public onUpdateChecklist() {
    this.updateTimeline();
  }

  /**
   * Update the status point for the timeline
   * If one of the validation is IN PROGRESS, the next ones have to be PENDING
   * If preliminary fail, catalog must show a failure status on timeline
   */
  public updateStatusPoint() {
    const validationInProgress = this.elements.find(
      e => e.statusPoint === this._validationStatus.IN_PROGRESS
    );

    if (validationInProgress) {
      let changedStatus = false;
      this.elements
        .sort((a, b) => a.validationtypeid - b.validationtypeid)
        .forEach(e => {
          if (e.statusPoint === this._validationStatus.IN_PROGRESS) {
            changedStatus = true;
          } else if (
            changedStatus &&
            validationInProgress.validationtypeid !== e.validationtypeid
          ) {
            e.statusPoint = this._validationStatus.PENDING;
          } else if (
            validationInProgress.validationtypeid === e.validationtypeid
          ) {
            e.statusPoint = this._validationStatus.IN_PROGRESS;
          }
        });
    } else {
      const preliminary = this.elements.find(
        e => e.validationtypeid === this._validationTypes.ID_PRELIMINARY
      );
      const catalog = this.elements.filter(
        e => e.validationtypeid === this._validationTypes.ID_CATALOG
      );

      if (
        preliminary &&
        catalog &&
        preliminary.statusPoint === this._validationStatus.FAILURE
      ) {
        catalog.forEach(c => (c.statusPoint = this._validationStatus.FAILURE));
      }
    }
  }

  /**
   * Function to add to every preliminary
   * if it has a linked catalog with failure status
   */
  public updateFailuresCatalog() {
    const preliminaries = this.elements.filter(
      e => e.validationtypeid === this._validationTypes.ID_PRELIMINARY
    );
    const catalog = this.elements.filter(
      e => e.validationtypeid === this._validationTypes.ID_CATALOG
    );

    preliminaries.forEach(v => {
      const linkedCatalog = catalog.find(
        c => c.validationlink === v.validationlink
      );
      v.hasFailureCatalog = linkedCatalog
        ? linkedCatalog.status === this._validationStatus.FAILURE
        : false;
    });
  }

  /**
   * This function is a serializer for the timeline
   * points. If there are preliminaries or catalog validations
   * the point's validation's status is failure if some of
   * the execution is failure.
   * @param data
   */
  private serializeValidationExecution(data) {
    return data.map(valExc => {
      let someFailure = false;
      let allSkipped = false;
      const d = { ...valExc };
      d.totalConditions = valExc.conditionsstatus.total;
      d.failureConditions = valExc.conditionsstatus.failure;
      d.label = this.validationsTypes.filter(
        t => t.validationtypeid === valExc.validationtypeid
      )[0].validationtypename;
      if (
        [
          this._validationTypes.PRELIMINARY,
          this._validationTypes.CATALOG
        ].includes(d.label)
      ) {
        someFailure = data
          .filter(val => val.validationtypeid === d.validationtypeid)
          .some(
            r =>
              r.validationstatusid.validationstatusname ===
              this._validationStatus.FAILURE
          );
        allSkipped = data
          .filter(val => val.validationtypeid === d.validationtypeid)
          .every(
            r =>
              r.validationstatusid.validationstatusname ===
              this._validationStatus.SKIPPED
          );

        d.statusPoint = someFailure
          ? this._validationStatus.FAILURE
          : allSkipped
          ? this._validationStatus.SKIPPED
          : this._validationStatus.SUCCESS;
      } else {
        d.statusPoint
          ? (d.statusPoint = this._validationStatus.FAILURE)
          : (d.statusPoint = valExc.validationstatusid.validationstatusname);
      }

      d.status = valExc.validationstatusid.validationstatusname;
      return d;
    });
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public updateTimeline() {
    this.getElementsTimeline(this.documentDetails.documentid, true);
  }

  ngOnDestroy(): void {
    this.global.emptyDocumentDetails();
    this.global.emptyTimeline();
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
  }

  /**
   * Return CSS Class for Status Colors
   */
  public getStatusClass(classname: string): string {
    if (classname.includes(this._documentStatus.SUCCESS)) {
      return 'text-noball-status--success';
    } else if (classname.includes(this._documentStatus.FAILURE)) {
      return 'text-noball-status--failure';
    } else if (classname.includes(this._documentStatus.WARNING)) {
      return 'text-noball-status--warning';
    } else if (classname.includes(this._documentStatus.IN_PROGRESS)) {
      return 'text-noball-status--in-progress';
    } else if (classname.includes(this._documentStatus.PENDING)) {
      return 'text-noball-status--pending';
    }
  }

  /**Check if the document is electronic or if it is an electronic child. If that is the case
   * the request to docpictures and docchunks should not be sent
   */
  public checkIsElectronic(): boolean {
    if (
      this.documentDetails.isparentdocument &&
      this.documentDetails.datamodel.iselectronic
    ) {
      return false;
    }

    if (this.documentDetails.parentdocumentdatamodel !== undefined) {
      if (
        !this.documentDetails.isparentdocument &&
        this.documentDetails.parentdocumentdatamodel['datamodel'].iselectronic
      ) {
        return false;
      }
    }

    return true;
  }
}
