import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { registerLicense } from '@syncfusion/ej2-base';

import { AlertService } from '../services/alert.service';
import { Alert } from '../models/alert.model';
import { AuthenticationService } from '../security/authentication.service';
import { Subject, Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { GlobalService } from '../services/global.service';
import * as _userPermissions from '../constants/userPermissions';
import * as _rolesConst from '../constants/userRoles';
import { EventTrackerService } from '../services/event-tracker.service';
import { ExpirationDateService } from '../security/expiration-date.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  public expandedSidebar: boolean = false;
  public expandedUserMenu: boolean = false;
  public pagesRouterPaths: string[];
  public user: any;
  public showPrivacyNote: any;

  notFound: boolean;
  title = 'Sire-frontend';
  currentRoute = '';
  alerts: Alert[] = [];
  loading = true;
  year: number = new Date().getFullYear();
  tenant: String = '';
  subscription: Subscription;
  _routerHub: Subscription;
  loginDisplay = false;
  clientViewer = false;

  // Route tracking
  currentPage: string;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private layoutService: LayoutService,
    private globalService: GlobalService,
    private trackerService: EventTrackerService,
    public expirationService: ExpirationDateService,
  ) {
    const user = this.authService.getLoggedInUser();
    this.clientViewer = user.roleid == _rolesConst.ID_GROUP_BENEFIT_VIEWER;
    const eyadmin = this.authService.getTenantFromLocalStorage();
    if (user && user.iseyadmin && eyadmin && eyadmin['tenantid']) {
      this.tenant = eyadmin['tenantname'];
    }
    this.pagesRouterPaths = this.router.config
      .find(obj => obj.path === '')
      .children.find(obj => obj.path === ':tenantname')
      .children.map(r => r.path);
    this.manageTenant();
    // Registering Syncfusion license key
    registerLicense(this.globalService.syncfusionLicenseKey);
  }

  ngOnInit() {
    this.alertService.getValue().subscribe(alert => {
      if (!alert || !alert.message || !alert.type) {
        this.alerts = [];
        return;
      }
      this.alerts.push(alert);
    });

    if (!this.authService.getLoggedInUser()) {
      this.subscription = this.authService.userLoggedIn.subscribe(user => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

    this.layoutService.sidebarChange.subscribe(isExpanded => {
      this.expandedSidebar = isExpanded;
    });

    this.layoutService.userMenuChange.subscribe(isExpanded => {
      this.expandedUserMenu = isExpanded;
    });

    this.sendRouteTracking(this.router.url);

    // this.alertService.success('success');
    // this.alertService.error('error');
    // this.alertService.info('info');
    // this.alertService.warn('warn');
  }


  public sendRouteTracking(url): void {
    const currentPage = this.trackerService.getCurrentPage();
    let urlPath = url.split('/').splice(2, 1)[0];
    if (urlPath === undefined) {
      urlPath = '';
    }
    if (currentPage !== url && this.pagesRouterPaths.includes(urlPath)) {
      this.trackerService.sendEventTracked(url, 3);
    }
  }

  public controlAccessByURL(url, user): void {
    const segments = url.root.children.primary.segments;
    const tenantName = segments[0].path;
    if (tenantName) {
      const tenant = user.tenants.find(
        tenant =>
          tenant.tenantname.toLowerCase() === tenantName.toLocaleLowerCase()
      );
      // If the segment is one of the user's tenant
      if (tenant) {
        if (
          tenant.tenantid.toString() !== this.authService.getIdTenantHeader()
        ) {
          this.setTenantHeader(tenant);
          if (segments.length === 1 && user.homeurl) {
            this.globalService.redirectUserHomeURL(tenant);
          }
        }
      } else {
        if (this.authService.getIdTenantHeader() === undefined) {
          const user = this.authService.getLoggedInUser();
          this.setTenantHeader(user.tenants[0]);
        }
        this.router.navigate(['/']);
      }
    }
  }

  public manageTenant(): void {
    const user = this.authService.getLoggedInUser();
    const url = this.router.parseUrl(this.router.url);
    if (url.root.hasChildren()) {
      this.controlAccessByURL(url, user);
    } else {
      const tenant = user.tenants[0];
      if (this.authService.getIdTenantHeader() === undefined) {
        this.setTenantHeader(tenant);
      }
      if (
        this.authService.hasPermission(_userPermissions.SCREEN_CLIENT_DOCUMENTS)
      ) {
        this.globalService.redirectUserHomeURL(tenant);
      }
    }
  }

  public setTenantHeader(tenant): void {
    this.authService.setIdTenantHeader(tenant.tenantid);
    this.globalService.setAppTittle(tenant.tenantdisplayname);
    this.globalService.resetData(tenant);
  }

  public closeMenus(): void {
    this.layoutService.closeMenus();
  }

  /**
   * Closes an alert
   * @param alert
   */
  public close(alert: Alert): void {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }


}
