import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TranslatePipe } from '../../../../pipes/translate.pipe';

@Component({
  selector: 'app-select-documents',
  templateUrl: './select-documents.component.html',
  styleUrls: ['./select-documents.component.scss']
})
export class SelectDocumentsComponent implements OnInit {

  @Output() setSelectedDocuments = new EventEmitter();
  @Output() setSelectedAnalysis = new EventEmitter();
  @Output() toggleSelectAllAnalysisCheckbox = new EventEmitter();
  @Input() selectedDocuments = [];
  @Input() selectedAnalysis = [];
  @Input() analysisData = null; 
  @Input() selectedAnalysisObjList = null;
  @Input() data: { data: []} = null;
  @Input() setting;
  @Input() isSingleProjectResponse;
  @Input() dataFilterInProgress: boolean = null;
  public selectedAnalysisForAllDoc: Array<number> = [];
  public analysisDocumentsMapping = {};
  public columns: Array<any> = [];
  public docsData:  { data: Array<any>} ;

  constructor(
    private translate: TranslatePipe
  ) { }

  ngOnInit(): void {
       
    
    // trigger and expand only if project, analyses and document only one. 
    if (this.selectedAnalysisObjList.data.length == 1 && this.data.data.length <= 1) {
      this.expandCollapseAnalysis(this.selectedAnalysisObjList.data[0]);
    }

    // Mapping analysis and documents for showing the documents on expanded analysis
    this.selectedAnalysis.forEach(analysisid => {
      if (!this.analysisDocumentsMapping[analysisid]) {
        this.analysisDocumentsMapping[analysisid] = { data: [] };
      }
      this.analysisDocumentsMapping[analysisid].data = this.data.data.filter((doc: any) => doc.analysis?.analysisid == analysisid);
    });

    const selectedDocumentIds = []
    if (this.data && this.data.data && Array.isArray(this.data.data) && this.data.data.length == Number(1)) {
      const documentList = this.data.data;
      selectedDocumentIds.push(documentList[documentList.length - 1]['documentid']);
      this.setSelectedDocuments.emit(selectedDocumentIds);
      this.data.data.forEach((obj: any) => {
        obj['isRowSelected'] = true
      })

      // If only one document present, select analysis as well
      this.selectedAnalysisForAllDoc.push(this.selectedAnalysis[0]);
      this.analysisData.data.forEach((obj: any) => {
        obj['isAnalysisRowSelected'] = true
      })
      this.toggleSelectAllAnalysisCheckbox.emit();
    } else if( this.selectedDocuments.length > 0){
      this.data.data.forEach((document: any) => {
        if (this.selectedDocuments.indexOf(document.documentid) > -1) {
          document.isRowSelected = true;
        }
      })
      this.setSelectedDocuments.emit(this.selectedDocuments);

      this.selectedAnalysisObjList.data.forEach(analysis => {
        let allDocsOfAnalysisSelected = true
        analysis.docsData.data.forEach(doc => {
          if (this.selectedDocuments.indexOf(doc.documentid) == -1) {
            allDocsOfAnalysisSelected = false
          }
        })
        if (allDocsOfAnalysisSelected) {
          analysis.isAnalysisRowSelected = true;
        }
      })
    }
    this.getColumns(); 
    this.getTableSettings();
  }

  ngOnChanges() {
    // When searching on documents, its not getting synced with the object selectedAnalysisObjList.docsData
    // Whenever data array updates, update this object as well
    this.analysisData?.data.forEach((analysis) => {
      if (this.selectedAnalysis.indexOf(analysis.analysisid) > -1) {
        analysis.docsData = { data: []}
        const analysisDocs = this.data.data.filter((doc: any) => doc.analysis.analysisid == analysis.analysisid);
        analysis.docsData.data = analysisDocs.filter((doc: any) => doc.status !== 'Failure');
        analysis.validDocCount = analysis.docsData.data.length;
        analysis.totalCount = analysisDocs.length;
      }
    })
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'createExploration.tableColumns.document',
        name: 'documentname',
        orderBy: 'documentname'
      },
      {
        header: 'createExploration.tableColumns.project',
        name: 'project',
        second: 'projectname'
      },
      {
        header: 'createExploration.tableColumns.date',
        name: 'createddate',
        orderBy: 'createddate',
        type: 'date',
        format: 'll'
      },
      {
        header: 'createExploration.tableColumns.uploader',
        name: 'createdbyuser',
        second: 'username'
      }
    ];
  }

  public getTableSettings(): void {
    const documentList = this.data?.data;
    //We are showing multiple tables at analysis levels. So table settings also needs to be different at analysis level
    this.selectedAnalysisObjList.data.forEach(obj => {
      const isRowUnselected = documentList.find((row)=> {
        return (row['analysis']['analysisid'] == obj.analysisid) && !row['isRowSelected']
      });

      obj.tableSettings = {
        dataId: 'documentid',
        hasSelect: 'false',
        hasEditPermissions: false,
        hasDownloadOptions: false,
        getDataFromDB: this.setting['getDocumentsFromDB'],
        hasRowSelectOptions: true,
        onRowSelection: this.selectDocument.bind(this),
        hidePagination: true,
        allRowsSelected: !isRowUnselected,
        responsiveTitle: {
          label: 'documentname'
        }
      };
    })
  }

  expandCollapseAnalysis(analysis) {
    analysis.isExpanded = !analysis.isExpanded;
    if(analysis.isExpanded){
      analysis.docsData = { data: []};
      const analysisDocs = this.data.data.filter((doc: any) => doc.analysis.analysisid == analysis.analysisid);
      analysis.docsData.data = analysisDocs.filter((doc: any) => doc.status !== 'Failure');
      analysis.validDocCount = analysis.docsData.data.length;
      analysis.totalCount = analysisDocs.length;
    }
  }

  selectAnalysis(event, analysis) {    
    const documentsUnderAnalysis = this.data.data.filter((doc: any) => (doc.status !== 'Failure' && doc.analysis.analysisid == analysis.analysisid) );
    const isAnalysisAlreadySelected = this.selectedAnalysisForAllDoc.indexOf(analysis.analysisid);

    const selectedAnalysis = this.selectedAnalysisObjList.data.filter(selectedAnalysis => {
      return selectedAnalysis.analysisid == analysis.analysisid
    })
    // Remove/add analysis in selction list
    if (isAnalysisAlreadySelected > -1) {
      selectedAnalysis[0].tableSettings.allRowsSelected = false;
      this.selectedAnalysisForAllDoc.splice(isAnalysisAlreadySelected, 1);
      analysis.isAnalysisRowSelected = false
    } else {
      selectedAnalysis[0].tableSettings.allRowsSelected = true;
      this.selectedAnalysisForAllDoc.push(analysis.analysisid);
      analysis.isAnalysisRowSelected = true
    }
    // Updating isRowSelected flag on documents based on analysis selection status
    documentsUnderAnalysis.forEach((doc: any) => doc.isRowSelected = !(isAnalysisAlreadySelected > -1));
    const selectedDocumentIds = this.selectedDocuments;
    documentsUnderAnalysis.forEach(analysisDocument => {
      if(isAnalysisAlreadySelected > -1){
        const documentIndex = selectedDocumentIds.indexOf(analysisDocument['documentid']);
        documentIndex > -1 && selectedDocumentIds.splice(documentIndex, 1);
      }else{
        selectedDocumentIds.indexOf(analysisDocument['documentid']) == -1 && selectedDocumentIds.push(analysisDocument['documentid'])
      }  
    })
    this.setSelectedDocuments.emit(selectedDocumentIds);
    event.stopPropagation()
  }

  selectDocument(document, areAllRowsSelected) {
    const analysisId = document && document.data && document.data.length > 0 ? document.data[0].analysis.analysisid : document.analysis.analysisid
    const selectedAnalysis = this.selectedAnalysisObjList.data.filter(obj => {
      return analysisId == obj.analysisid
    })
    const analysisObj = this.selectedAnalysisObjList.data.filter(analysisObj => {
      return analysisObj.analysisid == analysisId
    })
    const tableSettings = selectedAnalysis[0].tableSettings
    if (areAllRowsSelected) {
      tableSettings.allRowsSelected = !tableSettings.allRowsSelected;
      
      const selectedDocumentIds = document.data.map(item => {
        item.isRowSelected = tableSettings.allRowsSelected;
        return item.documentid;
      });
      analysisObj[0].isAnalysisRowSelected = tableSettings.allRowsSelected;
      this.setSelectedDocuments.emit(tableSettings.allRowsSelected ? selectedDocumentIds : []);
      if (tableSettings.allRowsSelected) {
        this.selectedAnalysisForAllDoc.push(analysisId)
      } else {
        const analysisIndex = this.selectedAnalysisForAllDoc.indexOf(analysisId);
        if (analysisIndex > -1) {
          this.selectedAnalysisForAllDoc.splice(analysisIndex, 1)
        }
      }
    } else {
      const selectedDocumentIds = this.selectedDocuments;
      document.isRowSelected = !document.isRowSelected;
      if (!document.isRowSelected) {
        // If any row gets unselected, make allRowsSelected flag false
        tableSettings.allRowsSelected = false;
        const removedDocumentIndex = selectedDocumentIds.indexOf(document.documentid);
        selectedDocumentIds.splice(removedDocumentIndex, 1);
      } else {
        selectedDocumentIds.push(document.documentid);
        tableSettings.allRowsSelected = (this.data?.data?.length == this.selectedDocuments.length);
      }
      this.setSelectedDocuments.emit(selectedDocumentIds);
      //Below logic is to uncheck AnalysisLevel check box if any document under that analysis is unselected by user
      let allDocumentsInAnalysis;
      for (const analysisId in this.analysisDocumentsMapping) {
        if (analysisId == document.analysis.analysisid) {
          allDocumentsInAnalysis = this.analysisDocumentsMapping[analysisId].data
        }
      }
      let allDocumentsInAnalysisSelected = true;
      allDocumentsInAnalysis.forEach(docObj => {
        if (!docObj.isRowSelected) {
          allDocumentsInAnalysisSelected = false;
        }
      })
      if (allDocumentsInAnalysisSelected) {
        tableSettings.allRowsSelected = true;
        this.selectedAnalysisForAllDoc.push(document.analysis.analysisid);
        analysisObj[0].isAnalysisRowSelected = true;
      } else {
        const analysisIndex = this.selectedAnalysisForAllDoc.indexOf(document.analysis.analysisid);
        analysisIndex > -1 && this.selectedAnalysisForAllDoc.splice(analysisIndex, 1)
        analysisObj[0].isAnalysisRowSelected = false;
      }
    }
  }

  getTooltipMsg(validCount, totalCount) {
    let msg = this.translate.transform('createExploration.outOf') + ' ' + totalCount + ' ' 
                + this.translate.transform('createExploration.documents') + ' ' 
                + validCount + ' ' + this.translate.transform('createExploration.areAvailableToSelect');
    if (validCount && validCount !== totalCount) {
      msg += ' ' +  this.translate.transform('createExploration.restAreNotAvailableDueToTheFailedStatus');
    }
    return msg;
  }
}


