import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ITreeOptions,
  IActionMapping,
  TREE_ACTIONS,
  TreeNode
} from '@circlon/angular-tree-component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { QuerySheetModel } from '../../models/querysheet-mstr.model';
import { restructureQueryTreeNodes } from './queryTreeUtils';
import { GlobalService } from '../../services/global.service';
import { LinkService } from '../../services/link.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ADMINISTRATOR, PROJECT_MANAGER } from '../../constants/userRoles';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { DeleteModalComponent } from '../../components/modals/delete/delete.component';
import { UsersTableComponent } from 'src/app/components/users-table/users-table.component';
import { QuerysheetUsersComponent } from './querysheet-users/querysheet-users.component';

@Component({
  selector: 'app-querysheet-details',
  templateUrl: './querysheet-details.component.html',
  styleUrls: ['./querysheet-details.component.scss']
})
export class QuerysheetDetailsComponent implements OnInit {
  @ViewChild(UsersTableComponent)
  private usersTable: UsersTableComponent;
  public querysheetDetails: QuerySheetModel = null;
  private querySheetId = null;
  public selectedQuery = {};
  public isLoading: boolean = false;
  private dataSub: Subscription;
  public qsAllowedRoles:  Array<String> = [ ADMINISTRATOR, PROJECT_MANAGER ];
  public user: any;

  actionMapping: IActionMapping = {
    mouse: {
      expanderClick: (tree, node: TreeNode, $event) => {
        TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
      },
      click: (tree, node, $event) => {
        // For leaf nodes i.e. queries, show the details
        if (!node.hasChildren) {
          this.selectedQuery = node.data;
        }
      }
    }
  };

  options: ITreeOptions = {
    allowDrag: true,
    actionMapping: this.actionMapping
  };

  nodes = [];

  constructor(
    private globalService: GlobalService,
    public link: LinkService,
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private translate: TranslatePipe,
    private authService: AuthenticationService,
  ) {
    this.user = authService.getLoggedInUser();
  }

  ngOnInit(): void {
    this.querysheetDetails = this.globalService.getQuerysheet();

    this.dataSub = this.route.queryParamMap.subscribe((params: Params) => {
      /* Scenario: Query sheet details Page refresh,
                  taking id from route and fetching all the details */
      this.querySheetId = params.get('querysheetId');
    });

    this.fetchQueryTreeDetails();
  }

   /**
   * Destroy event handler
   */
   ngOnDestroy() {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  public fetchQueryTreeDetails() {
    this.isLoading = true;
    this.globalService
      .fetchQuerySheetDetails(this.querysheetDetails?.querySheetId || this.querySheetId)
      .then(response => {
        this.nodes = restructureQueryTreeNodes(response.querytree);
        this.querysheetDetails = this.globalService.getQuerysheet();
        this.isLoading = false;
        // Resetting selected Query
        this.selectedQuery = {};
      })
      .catch(error => {
        this.isLoading = false;
      });
  }

  public deleteQuerySheet() {
    const url = 'querysheets/delete';
    const params = { 'ids': [this.querysheetDetails?.querySheetId]};

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: 'querySheet',
      customMessage: "querysheetManagement.querySheetDeleteConfirmation",
      notAllowed: !this.querysheetDetails?.querySheetId
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.isLoading = true;
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              this.isLoading = false;
              // Redirecting to query sheets management/list page
              this.link.goToQuerySheetsManagement();

              // Querysheet/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.querysheetDeletedSuccessfully'
                )
              );
            }
          );
        }
      }
    );
  }

  /**
    * Load modal to manage users
    */
  public manageExplorer() {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const options: any = {};
    options.data = {
      querySheetId: this.querySheetId || this.querysheetDetails?.querySheetId
    };
    const modalWindowRef = this.modalService.open(
      QuerysheetUsersComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(role => {});
  }

  public setIsLoadingFlag(flag: boolean) {
    this.isLoading = flag;
  }
}
