// Roles codes
export const ID_ADMINISTRATOR = 1;
export const ID_PROJECT_MANAGER = 2;
export const ID_ANALYSIS_MANAGER = 3;
export const ID_DOCUMENT_MANAGER = 4;
export const ID_VIEWER = 5;
export const ID_EXTERNAL_VIEWER = 6;
export const ID_GROUP_BENEFIT_VIEWER = 7;

// Roles names
export const ADMINISTRATOR = 'administrator';
export const PROJECT_MANAGER = 'project_manager';
export const ANALYSIS_MANAGER = 'analysis_manager';
export const DOCUMENT_MANAGER = 'document_manager';
export const VIEWER = 'viewer';
export const EXTERNAL_VIEWER = 'external_viewer';
export const GROUP_BENEFIT_VIEWER = 'group_benefit_viewer';
