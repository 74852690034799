import { Component, OnInit, Input } from '@angular/core';

import {
  ITreeState,
  ITreeOptions,
  TreeModel,
  TreeNode
} from '@circlon/angular-tree-component';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() nodes;
  @Input() options: ITreeOptions;
  @Input() menuActions;
  @Input() selectedQuery;
  @Input() disableNodes: boolean = false;
  // Temporarily hiding the option on tree nodes, until implmentation for those actions
  public tempHideNodeActions = false;

  state: ITreeState = {
    expandedNodeIds: {},
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  constructor() {}

  ngOnInit(): void {}

  public searchAndHighlightNodes(
    searchText: string,
    treeModel: TreeModel
  ): void {
    // Resetting the tree to collapse and reset highlight flag for all nodes
    treeModel.collapseAll();
    treeModel.doForAll((node: TreeNode) => {
      node.data.highlight = false;
    });

    if (searchText) {
      treeModel.doForAll((node: TreeNode) => {
        if (node.data.name.toLowerCase().includes(searchText.toLowerCase())) {
          node.data.highlight = true;
          node.setActiveAndVisible();
        }
      });
    }
  }
}
