import { Component, OnInit, DoCheck, Input, Output, ViewChild, EventEmitter, OnChanges } from '@angular/core';
import { SpreadsheetComponent, getCellIndexes, getCell } from '@syncfusion/ej2-angular-spreadsheet';

import { ApiService } from '../../../services/api.service';
import { getTextWidth, createUnselectedArea, createSVGRects, getPartialSelectedTextForExcel,
  createCursorElements, removeCursorElements, createDestroyAnswerErrorHighlight } from '../utils/util';
import { addDragableEventOnCursor, removeHighlightRects } from '../utils/excel-utils';
import { feedbackTypes } from '../../exploration-details/utils/constants';

@Component({
  selector: 'app-excel-preview',
  templateUrl: './excel-preview.component.html',
  styleUrls: ['./excel-preview.component.scss']
})
export class ExcelPreviewComponent implements OnInit, OnChanges {

  @ViewChild('spreadsheet') spreadsheetObj: SpreadsheetComponent | undefined;
  @Input() selectedResult: any = null;
  @Input() usecaseLevels: any = null;
  @Output() setManualFeedbackSelections = new EventEmitter();
  @Output() setDisableDoneButtonOnFilePreview =  new EventEmitter();
  public level1_1: any = null;
  public level2_1: any = null;
  public excelLoading: boolean = false;
  public selectedCellPosition: any = null;
  public isAiBox: boolean = false; // Flag to denote if the cell is a AI returned result
  public selectedFeedbacks: any = {}; // Object holds all the local + db saved feedbacks
  public dbSavedFeedbacks: any = {}; // Object holds all the db saved feedbacks
  public currentFeedback: any = {}; // Object holds feedback data being edited by the user
  public feedbackBeforeValidationErr: any = null; // Saving currentFeedback object before we get validation error, using it to while restoring the selection
  public openCellTextPopup: boolean = false; // Flag to display popup on excel cell click
  private selectedCellData: any = null;
  private metaDataOfPopup: any = {};
  private feedbackInAction: string = null; // It gives which feedback is being edited by the user Semantic/QnA
  public showActionBtns: boolean = false; // Flag to display save, copy, clear buttons on popup
  public disableSaveBtn: boolean = false; // Flag to enable/disable save btn, disabling it if no text is selected
  public showLevel2_1Btn: boolean = false;
  public enableSavaAndCloseBtn: boolean = false; // Flag to enable/disable Savve and close button on popup
  private initialScrollPositions: any = null;
  private isLevel1InsideLevel2Selection: any = false; // Flag for validation if Level1_1 selection going inside Level2_1 selection
  private fontSize = 12;
  private fontFamily = 'aerial';

  private target: any = null;
  private clickPoint: any = null;
  private lastMove: any = null;
  private currentMove: any = null;
  private handleEvent: any = null;
  private move: any = null;
  private endMove: any = null;
  private dummyNonExistentCell = 'A0';

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.level1_1 = this.usecaseLevels.level1_1;
    this.level2_1 = this.usecaseLevels.level1_1?.level2_1;

    // Keeping all the db seaved selected feedbacks in dbSavedFeedbacks list, and all the local updated feedbacks in selectedFeedbacks list
    // selectedFeedbacks/dbSavedFeedbacks = { [cellPosition-page]: {USEModel: {}, robertaModel: {}} }
    // based on this data we can identify th type of operation
    this.selectedResult?.[this.level1_1.modelKey]?.feedback?.selections.forEach((selection: any) => { 
      // Checking for implicit flag, cause this level1_1 record is added for sake of the existence of level2_1 record
      if (!selection.implicit) {
        const selectionKey = `${selection.boundingBox?.position.join('-')}-${selection.pageNum}`;
        !this.selectedFeedbacks[selectionKey] && (this.selectedFeedbacks[selectionKey] = {});
        !this.dbSavedFeedbacks[selectionKey] && (this.dbSavedFeedbacks[selectionKey] = {});
        this.selectedFeedbacks[selectionKey][this.level1_1.modelKey] = JSON.parse(JSON.stringify(selection));
        this.dbSavedFeedbacks[selectionKey][this.level1_1.modelKey] = JSON.parse(JSON.stringify(selection));
      }
    });

    if (this.level2_1) {
      this.selectedResult?.[this.level2_1.modelKey]?.feedback?.selections.forEach((selection: any) => {
        const selectionKey = `${selection.boundingBox.position.join('-')}-${selection.pageNum}`;
        !this.selectedFeedbacks[selectionKey] && (this.selectedFeedbacks[selectionKey] = {});
        !this.dbSavedFeedbacks[selectionKey] && (this.dbSavedFeedbacks[selectionKey] = {});
        this.selectedFeedbacks[selectionKey][this.level2_1.modelKey] = JSON.parse(JSON.stringify(selection));
        this.dbSavedFeedbacks[selectionKey][this.level2_1.modelKey] = JSON.parse(JSON.stringify(selection));
      });
    }
  }

  ngOnChanges() { 
    if (this.usecaseLevels) {
      this.level1_1 = this.usecaseLevels.level1_1;
      this.level2_1 = this.usecaseLevels.level1_1?.level2_1;
    }
  }

  bindSVGLayerToPage = () => {
    const creatSVGElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    creatSVGElement.setAttribute("class", "drawingPlane");
    const cellTextPopupContainer = document.getElementById('cellTextPopup');
    cellTextPopupContainer && creatSVGElement.setAttribute("style", `width: 100%; height: ${(cellTextPopupContainer.scrollHeight) - 55}px; min-height: ${cellTextPopupContainer.scrollHeight - 55}px; position: absolute; top: 0px; z-index: 2;`);
    cellTextPopupContainer && cellTextPopupContainer.appendChild(creatSVGElement);
    setTimeout(() => this.setEventListeners());
  }

  setEventListeners = () => {
    const svg = document.querySelector('.drawingPlane');
    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    group.setAttribute("class", "svgGroup");
    svg.appendChild(group);

    // Creating unselectionAreas for the cell text
    this.createUnselectedAreaForCellText(group);

    // Highlight saved manual feedbacks on popup open, setting up currentFeedback on cell selection
    if (this.currentFeedback && Object.keys(this.currentFeedback).length) {
        const level2_1Feedback = this.level2_1 ? this.currentFeedback?.[this.level2_1.modelKey] : null;
        // Highlighting level1_1 feedback first
        if (this.currentFeedback[this.level1_1.modelKey]) {
          const { text, boundingBox = {} } = this.currentFeedback?.[this.level1_1.modelKey];
          const { startIndex, endIndex, startCursor = null, endCursor = null, position } = boundingBox;
          const selectionKey = `${position.join('-')}-${this.spreadsheetObj?.activeSheetIndex + 1}`;
          if (startCursor && endCursor) {
              const filteredWordSpans = this.findSpanWithInGivenIndices(startIndex, endIndex);
              this.createSelectionArea(group, svg, filteredWordSpans);
          } else if (startIndex || endIndex) {
              this.highlightFeedbackOnPopup(startIndex, endIndex, text);
              // Make sure to save cursor positions in selectedFeedbacks for further usage
              this.selectedFeedbacks[selectionKey][this.level1_1?.modelKey].boundingBox = JSON.parse(JSON.stringify(this.currentFeedback?.[this.level1_1.modelKey].boundingBox));
          }

          // Display level2_1 btn if level1_1 feedback present
          this.level2_1 && (this.showLevel2_1Btn = true);
        }

        // Now check and highlight level2_1 feedback if present
        if (level2_1Feedback) {
            const { text = '', boundingBox } = level2_1Feedback;
            const { startIndex = null, endIndex = null, startCursor = null, endCursor = null, position } = boundingBox;
            const selectionKey = `${position.join('-')}-${this.spreadsheetObj?.activeSheetIndex + 1}`;
            if (startCursor && endCursor) {
                const level2_1FilteredWordSpans = this.findSpanWithInGivenIndices(startIndex, endIndex);
                this.createSelectionArea(group, svg, level2_1FilteredWordSpans, true);
            } else {
                this.highlightFeedbackOnPopup(startIndex, endIndex, text, true);
                // Make sure to save cursor positions in selectedFeedbacks for further usage
                this.selectedFeedbacks[selectionKey][this.level2_1?.modelKey].boundingBox = JSON.parse(JSON.stringify(this.currentFeedback?.[this.level2_1.modelKey].boundingBox));
            }
        }
    } else if ((this.spreadsheetObj?.activeSheetIndex + 1) == this.selectedResult.USEModel.page && this.metaDataOfPopup.aiAnswerSpans) {
        // AI highlight in yellow color
        this.highlightAiAnswer(group, svg);
    }
  }

  createUnselectedAreaForCellText = (group: any) => {
    // Creating unSelectionAreas for all the wordspans as well
    // Added setTimeout to get offsetTop of the spans correctly, cause sometimes the semantic/Answer btn appears a little later
    setTimeout(() => {
        let currLineNo = -1;
        this.metaDataOfPopup.allWordSpans.forEach((spanEl) => {
            const lineNo = spanEl.parentElement.getAttribute('lineNo');
            if (currLineNo !== lineNo) {
                spanEl.addLeftPadding = true;
                currLineNo = lineNo;
            }

            if (this.metaDataOfPopup.allLineSpans.length - 1 == lineNo) {
                spanEl.addBottomPadding = true;
            }
            createUnselectedArea(spanEl, group);
        });
    }, 100)
  }

  createCursorsAndAddEvent() {
    const group = document.getElementsByClassName("svgGroup")[0];
    const { startCursor, endCursor } = this.currentFeedback[this[this.feedbackInAction].modelKey].boundingBox;
    createCursorElements(startCursor.y1, endCursor.y1, group, startCursor.x1, startCursor.y1, startCursor.x2, startCursor.y2, endCursor.x1, endCursor.y1, endCursor.x2, endCursor.y2);
    const params = {
      currentFeedback: this.currentFeedback, 
      feedbackInAction: this.feedbackInAction,
      createSelectionArea: this.createSelectionArea,
      setCurrentFeedback: this.setCurrentFeedback,
      highlightAiAnswer: this.highlightAiAnswer,
      metaDataOfPopup: this.metaDataOfPopup,
      selectedCellPosition: this.selectedCellPosition,
      disableSaveBtn: this.disableSaveBtn,
      updateIsLevel1InsideLevel2SelectionFlag: this.updateIsLevel1InsideLevel2SelectionFlag.bind(this),
      level1_1: this.level1_1,
      level2_1: this.level2_1
    }
    addDragableEventOnCursor(params);
  }

  updateIsLevel1InsideLevel2SelectionFlag(flagVal: boolean, feedbackBeforeValidationErr: any) {
    this.isLevel1InsideLevel2Selection = flagVal;
    flagVal && (this.enableSavaAndCloseBtn = !flagVal); // disable saveandclose button on popup if there is validation error
    !flagVal && (this.feedbackBeforeValidationErr = feedbackBeforeValidationErr);
  }

  clearAnswerOnValidation = (event) => {
    event.stopPropagation();
    // Clear answer feedback from currentBox
    this.setCurrentFeedback({}, true, true);
    removeHighlightRects(this[this.feedbackInAction], this.level2_1.highlightType);
    // Since we have changed the approach, clear answer is now only possible if answer cursors are moved out of semantic
    // So need to remove the answer selction cursors and remove action buttons as well
    removeCursorElements();
    this.showActionBtns = false;
    this.isLevel1InsideLevel2Selection = false;
    this.enableSavaAndCloseBtn = true;
  }

  restoreSelectionOnValidation = (event) => {
    event.stopPropagation();
    // Restore previous feedback selection
    const selectionKey = `${this.selectedCellPosition.join('-')}-${this.spreadsheetObj?.activeSheetIndex + 1}`;
    const savedFeedback = this.feedbackBeforeValidationErr || this.selectedFeedbacks[selectionKey];

    removeCursorElements();
    const level1_1Feedback = savedFeedback[this.level1_1.modelKey];
    const level2_1Feedback = (this.level2_1 && savedFeedback[this.level2_1.modelKey]) || null;

    // Remove currently changed feedback rects, draw previously saved feedback for level1_1
    if(this.feedbackInAction !== 'level2_1') {
      const { boundingBox = {}, text = '' } = level1_1Feedback;
      const { startIndex = null, endIndex = null } = boundingBox;
      removeHighlightRects(this[this.feedbackInAction], this.level1_1.highlightType);
      this.highlightFeedbackOnPopup(startIndex, endIndex, text);
      this.setCurrentFeedback(level1_1Feedback);
    }

    // Remove currently changed feedback rects, draw previously saved feedback for level2_1 if present
    if (level2_1Feedback) {
      const { text = '', boundingBox = {} } = level2_1Feedback;
      const { startIndex = null, endIndex = null } = boundingBox;
      removeHighlightRects(this[this.feedbackInAction], this.level2_1.highlightType);
      this.highlightFeedbackOnPopup(startIndex, endIndex, text, true);
      this.setCurrentFeedback(level2_1Feedback, false, true);
    } else if (this.feedbackInAction == 'level2_1') {
      // If level2_1 feedback not present and level2_1 feedback is in action, Set level2_1 feedback as same as leve1_1 feedback
      const { text = '', boundingBox = {} } = level1_1Feedback;
      const { startIndex = null, endIndex = null } = boundingBox;
      removeHighlightRects(this[this.feedbackInAction], this.level2_1.highlightType);
      this.highlightFeedbackOnPopup(startIndex, endIndex, text, true);
      this.setCurrentFeedback(level1_1Feedback, false, true);
    }

    this.createCursorsAndAddEvent();

   /*  // Removing red border around the answer selection areas, and highlight it back
    const group = document.getElementsByClassName("svgGroup")[0];
    group && group.querySelectorAll(`[highlightType="QnA"`).forEach((highlightedRect) => {
        createDestroyAnswerErrorHighlight(highlightedRect, this.feedbackInAction == 'level2_1');
    }); */

    this.isLevel1InsideLevel2Selection = false;
  }

  createSelectionArea = (group: any, svg: any, spans: any, isLevel2_1: boolean = false) => {
    const svgPos = svg.getBoundingClientRect();
    const startCursorElement = group.querySelector(".startCursor");
    let stichedText = '';
    let startIndex = 0;
    let endIndex = 0;
    let lineNo = 0;
    const isItLevel2_1 = isLevel2_1 || this.feedbackInAction == 'level2_1';
    const { startCursor, endCursor } = isItLevel2_1 ? this.currentFeedback[this.level2_1.modelKey].boundingBox : this.currentFeedback[this.level1_1.modelKey].boundingBox;

     /* Create selection area for highlights */
     spans.forEach((spanEl: any, index: number) => {
        const spanPos = spanEl.getBoundingClientRect();
        const x1 = spanPos.left - svgPos.left;
        const y1 = spanPos.top - svgPos.top;
        const x2 = spanPos.right - svgPos.left;
        const y2 = y1 + this.fontSize;
        let wordWidth = x2 - x1;
        const wordHeight = y2 - y1;
        let { textContent } = spanEl;
        let spanStartIndex = Number(spanEl.getAttribute("startIndex"));
        let spanEndIndex = Number(spanEl.getAttribute("endIndex"));
        let wordBoundingBox = [x1, y1, x2, y2];
        spanEl.setAttribute('boundingBox', wordBoundingBox)

        // Taking care of partial selected words, considering partial word either can be the first word or the last word in selected text
        if ([0, spans.length - 1].includes(index)) {
            const { partialText: partialSelectedWord, startOfPartialText } = getPartialSelectedTextForExcel({ text: textContent, boundingBox: wordBoundingBox, wordWidth }, parseInt(startCursor.x1), parseInt(endCursor.x1), parseInt(startCursor.y1), parseInt(endCursor.y1));
            if (partialSelectedWord !== textContent) {
                wordWidth = spans.length == 1 ? (endCursor.x2 - startCursor.x1) : (index == 0 ? x2 - startCursor.x1 : endCursor.x1 - x1);
                spanStartIndex = (index == 0 && startCursor.x1 > x1) ? (spanStartIndex + startOfPartialText) : spanStartIndex;
                spanEndIndex = spanStartIndex + partialSelectedWord.length - 1;
                textContent = partialSelectedWord;
                wordBoundingBox = [index == 0 ? startCursor.x1 : x1, y1, index == spans.length - 1 ? endCursor.x1 : x2, y2]
            }
        }

        if (textContent) {
            const isQnAFeedbackMode = (isItLevel2_1 ? this.level2_1.feedbackType : this.level1_1.feedbackType)  === feedbackTypes.QNA;
            const highlightRect = createSVGRects(wordBoundingBox, wordWidth, wordHeight, textContent, false, [], isQnAFeedbackMode);
            highlightRect.setAttribute("startIndex", spanStartIndex.toString());
            highlightRect.setAttribute("endIndex", spanEndIndex.toString());
            startCursorElement ? group.insertBefore(highlightRect, startCursorElement) : group.appendChild(highlightRect);
            stichedText += textContent;
        }
        index == 0 && (startIndex = spanStartIndex);
        index == 0 && (lineNo = spanEl.parentElement.getAttribute('lineNo'));
        index == spans.length - 1 && (endIndex = spanEndIndex);
    })

    // Saving these values in currentBox
    return { text: stichedText, startIndex, endIndex, lineNo };
  }

  findSpanWithInGivenIndices = (startIndex: number, endIndex: number) => {
    const filteredSpans = [];
    this.metaDataOfPopup.allWordSpans.forEach((wordSpanEl: any) => {
        const wordSpanStartIndex = wordSpanEl.getAttribute("startIndex");
        const wordSpanEndIndex = wordSpanEl.getAttribute("endIndex");
        const wordLength = wordSpanEndIndex - wordSpanStartIndex + 1;
        // Conditions for complete word and partially selected word spans
        if ((wordSpanStartIndex >= startIndex
                || (startIndex > wordSpanStartIndex && startIndex <= wordSpanEndIndex))
            && (wordSpanEndIndex <= endIndex
                || (wordSpanEndIndex >= endIndex && (wordSpanEndIndex - endIndex) < wordLength))) {
            filteredSpans.push(wordSpanEl);
        }
    })

    return filteredSpans;
  }

  setOpenCellTextPopup(event: any, flagVal: boolean) {
    event?.preventDefault();
    event?.stopPropagation();
    this.openCellTextPopup = flagVal;
    this.setDisableDoneButtonOnFilePreview.emit(flagVal);
    this.feedbackInAction = null;
    this.showActionBtns = false;
    this.metaDataOfPopup = {};
    this.showLevel2_1Btn = false;
    this.enableSavaAndCloseBtn = false;
    this.isAiBox = false;
    this.feedbackBeforeValidationErr = null;
    this.isLevel1InsideLevel2Selection = false;
    this.spreadsheetObj.selectRange(this.dummyNonExistentCell); // deselect the cell after closing popup, using just a dummy cell here
    !flagVal && this.setCurrentFeedback({}, true);
  }

  // Utility function written to highlight saved feedback on preview, answer highlight/manual selected feedback
  highlightPartOfTextOnCell = (cellElement: any, cellText: string, start: number, end: number, color: string, level2_1WithInlevel1_1Feedback: any = {}) => {

    const rgbaCol = color == 'inherit' ? 'inherit' : 'rgba(' + parseInt(color.slice(-6,-4),16)
        + ',' + parseInt(color.slice(-4,-2),16)
        + ',' + parseInt(color.slice(-2),16)
        +',0.4)';


    // Setting the maximum height of the row, using excel files properties as while adding these HTML tags heights was getting messed up
    cellElement.style.maxHeight = cellElement.offsetHeight;
    cellElement.style.minHeight = cellElement.offsetHeight;
    cellElement.style.minWidth = cellElement.offsetWidth;
    cellElement.style.overflow = 'hidden';
    const childElementClass = cellElement?.children?.[0] && cellElement.children[0].getAttribute('class');
    cellElement.innerHTML = '';

    start = start || 0;
    end = end >= 0 ? end : cellText?.length;
    const { level2_1StartIndex, level2_1EndIndex, level2_1FeedbackText } = level2_1WithInlevel1_1Feedback;

    const parentSpan = document.createElement("span");
    parentSpan.setAttribute('class', `${childElementClass}`);
    cellElement.appendChild(parentSpan);

    const p1Tag = document.createElement("span");
    p1Tag.innerHTML = cellText.substring(0, start).replace(/[<]/g, '&lt');
    parentSpan.appendChild(p1Tag);

    const markLevel1_1StartTag = document.createElement("mark");
    markLevel1_1StartTag.style.backgroundColor = rgbaCol;
    markLevel1_1StartTag.innerHTML = cellText.substring(start, ((typeof level2_1StartIndex == 'number' && level2_1StartIndex >= 0) ? level2_1StartIndex : (end + 1))).replace(/[<]/g, '&lt');
    parentSpan.appendChild(markLevel1_1StartTag);

    if (level2_1FeedbackText?.length) {
        // Highlighting answer feedback within semantic feedback
        const markLevel2_1Tag = document.createElement("mark");
        const rgbaColLevel2 = 'rgba(' + parseInt(this.level2_1.highlightColor.slice(-6,-4),16)
        + ',' + parseInt(this.level2_1.highlightColor.slice(-4,-2),16)
        + ',' + parseInt(this.level2_1.highlightColor.slice(-2),16)
        +',0.6)';
        markLevel2_1Tag.style.backgroundColor = rgbaColLevel2;
        markLevel2_1Tag.innerHTML = cellText.substring(level2_1StartIndex, (level2_1EndIndex + 1)).replace(/[<]/g, '&lt');
        parentSpan.appendChild(markLevel2_1Tag);

        const markLevel1_1EndTag = document.createElement("mark");
        markLevel1_1EndTag.style.backgroundColor = rgbaCol;
        markLevel1_1EndTag.innerHTML = cellText.substring((level2_1EndIndex + 1), (end + 1)).replace(/[<]/g, '&lt');
        parentSpan.appendChild(markLevel1_1EndTag);
    }

    const p2Tag = document.createElement("span");
    p2Tag.innerHTML = cellText.substring(end + 1).replace(/[<]/g, '&lt');
    parentSpan.appendChild(p2Tag);
  }

  // Function to find out start and end index of the answer in the formatted text (considering extra spaces and new lines)
  // Note: AIML results misses the characters that adds up during the cell formatting
  getAnswerIndicesOnFormattedText = (formattedCellText = '') => {
    const formattedText = formattedCellText || this.selectedCellData?.value;
    const { text, startIndex: resultStart, endIndex: resultEnd } = this.selectedResult.robertaModel;

    if (text && formattedText) {
        const lineTextArr = formattedText.split('\n');
        let startLine = 0;
        let endLine = 0;
        let lineStartIndex = 0;
        let lineEndIndex = 0;
        let spacesAtStartAndEndOfLines = 0;
        lineTextArr.forEach((line, lineNo) => {
            const extraSpacesBeforeLine = line.length - line.trimStart().length;
            if (extraSpacesBeforeLine) {
                spacesAtStartAndEndOfLines += extraSpacesBeforeLine;
            }
            lineStartIndex = lineNo == 0 ? lineStartIndex : lineEndIndex + 1 + 1; // plus one cause its started from new line and one for next character from last endIndex
            lineEndIndex += line.length; // plus one for newline

            if (resultStart >= lineStartIndex && resultStart <= lineEndIndex) {
                startLine = spacesAtStartAndEndOfLines;
            }

            if (resultEnd >= lineStartIndex && resultEnd <= lineEndIndex) {
                endLine = spacesAtStartAndEndOfLines;
            }

            const extraSpacesAfterLine = line.length - line.trimEnd().length;
            if (extraSpacesAfterLine) {
                spacesAtStartAndEndOfLines += extraSpacesAfterLine;
            }

        });

        // Adjusting extra spaces before answer string starts
        const answerStart = resultStart + startLine;

        // Adjusting extra spaces in between answer string
        const answerEnd = resultEnd + (endLine || startLine) - 1;
        return { answerStart, answerEnd };
    }

    return { answerStart: null, answerEnd: null };
  }

  setCurrentFeedback = (updateCurrentBox = {}, clearCurrentBox = false, isLevel2_1 = false) => {
    // Find a better and flexible way to assign arg values
    const updateFeedbackType = isLevel2_1 ? 'level2_1' : this.feedbackInAction || 'level1_1';
    switch (updateFeedbackType) {
        case 'level1_1': {
          if (clearCurrentBox) {
            this.currentFeedback = {};
          } else if (updateCurrentBox && Object.keys(updateCurrentBox).length) {
            !this.currentFeedback && (this.currentFeedback = {});
            this.currentFeedback[this.level1_1.modelKey] = { ...this.currentFeedback?.[this.level1_1.modelKey], ...updateCurrentBox };
          } else {
            !this.currentFeedback && (this.currentFeedback = {});
          }
          break;
        }

        case 'level2_1': {
          if (clearCurrentBox) {
            this.currentFeedback[this.level2_1.modelKey] && delete this.currentFeedback[this.level2_1.modelKey];
          } else if (updateCurrentBox && Object.keys(updateCurrentBox).length) {
            !this.currentFeedback && (this.currentFeedback = {});
            this.currentFeedback[this.level2_1.modelKey] = { ...this.currentFeedback?.[this.level2_1.modelKey], ...updateCurrentBox };
          }
          break;
        }

        default:
          this.currentFeedback = {};
          break;
    }
  }

  highlightAiAnswer = (group: any, svg: any, removeHighlight = false) => {
    if (removeHighlight) {
        group && group.querySelectorAll(`[highlightType="aiAnswerHighlight"`).forEach((highlightedRect) => {
            highlightedRect.remove();
        });
    } else {
        const svgPos = svg.getBoundingClientRect();
        const { answerStart = -1, answerEnd = -1 } = this.getAnswerIndicesOnFormattedText();

        this.metaDataOfPopup?.aiAnswerSpans.forEach((answerSpan, index) => {
            const spanPos = answerSpan.getBoundingClientRect();
            let x1 = spanPos.left - svgPos.left;
            const y1 = spanPos.top - svgPos.top;
            let x2 = spanPos.right - svgPos.left;
            const y2 = y1 + this.fontSize;
            const wordHeight = y2 - y1;
            const wordStartIndex = parseInt(answerSpan.getAttribute('startIndex'));
            const wordEndIndex = parseInt(answerSpan.getAttribute('endIndex'));
            if (index == 0 && wordStartIndex < answerStart) {
                    const widthOfLeftIgnoredText = getTextWidth(answerSpan.textContent.substr(0, answerStart - wordStartIndex), `${this.fontSize}px ${this.fontFamily}`);
                    x1 += widthOfLeftIgnoredText;
            }
            if (index == this.metaDataOfPopup?.aiAnswerSpans.length - 1 && wordEndIndex > answerEnd) {
                    const widthOfRightIgnoredText = getTextWidth(answerSpan.textContent.substr(answerEnd - wordEndIndex), `${this.fontSize}px ${this.fontFamily}`);
                    x2 -= widthOfRightIgnoredText;
            }

            const wordBoundingBox = [x1, y1, x2, y2];
            const wordWidth = x2 - x1;
            const highlightRect = createSVGRects(wordBoundingBox, wordWidth, wordHeight, answerSpan.textContent, true);
            highlightRect.setAttribute('fill', this.level1_1.aiBox?.highlightColor);
            highlightRect.setAttribute('highlightType', 'aiAnswerHighlight');
            group.appendChild(highlightRect);
        });
    }
  }

  // A Reusable function for highlighting text part and creating cursors between startIndex and endIndex for popup
  highlightFeedbackOnPopup = (startIndex: number, endIndex: number, text: string, isLevel2_1: boolean = false) => {
    const group = document.getElementsByClassName("svgGroup")[0]
    const svg = document.querySelector('.drawingPlane');
    const svgPos = svg.getBoundingClientRect();

    const filteredWordSpans = this.findSpanWithInGivenIndices(startIndex, endIndex);
    /* Getting positions to place start cursor */
    const firstSpanPos = filteredWordSpans[0].getBoundingClientRect();
    const firstWord = filteredWordSpans[0].textContent;
    // Calculating relative position of the first line span with respect to svg layer
    let startX1 = firstSpanPos.left - svgPos.left;
    const startY = firstSpanPos.top - svgPos.top;
    const wordStartIndex = parseInt(filteredWordSpans[0].getAttribute('startIndex'));
    // Check for partial word selection
    if (wordStartIndex < startIndex) {
        const widthOfLeftIgnoredText = getTextWidth(firstWord.substr(0, startIndex - wordStartIndex), `${this.fontSize}px ${this.fontFamily}`);
        startX1 += widthOfLeftIgnoredText;
    }

    /* Getting positions to place end cursor */
    const lastSpanPos = filteredWordSpans[filteredWordSpans.length - 1].getBoundingClientRect();
    const lastWord = filteredWordSpans[filteredWordSpans.length - 1].textContent;
    // Calculating relative position of the last line span with respect to svg layer
    let endX = lastSpanPos.right - svgPos.left;
    const endY = lastSpanPos.top - svgPos.top;
    const wordEndIndex = parseInt(filteredWordSpans[filteredWordSpans.length - 1].getAttribute('endIndex'));
    // Check for partial word selection
    if (wordEndIndex > endIndex) {
        const widthOfRightIgnoredText = getTextWidth(lastWord.substr(endIndex - wordEndIndex), `${this.fontSize}px ${this.fontFamily}`);
        endX -= widthOfRightIgnoredText;
    }

    const updateCurrentBox = {
            text,
            boundingBox: {
              startIndex,
              endIndex,
              startCursor: { x1: startX1, y1: startY, x2: startX1, y2: startY + this.fontSize },
              endCursor: { x1: endX, y1: endY, x2: endX, y2: endY + this.fontSize },
              position: this.selectedCellPosition
            },
            pageNum: (this.spreadsheetObj?.activeSheetIndex + 1)
        }
    this.setCurrentFeedback(updateCurrentBox, false, isLevel2_1);

    // Creating highlight rects
    this.createSelectionArea(group, svg, filteredWordSpans, isLevel2_1);
  }

  // Function to highlight manual/answer result on the file load
  highlightCellTextOnLoad() {
    // After data loaded on excel, draw aibox and answer hightlights
    let aiBoxDrawnOnLoad = false;
    const aiBoxModelKey = this.level1_1?.aiBox?.modelKey;
    // Highlighting all the saved feedbacks
    if (Object.keys(this.selectedFeedbacks).length) {
      Object.values(this.selectedFeedbacks).forEach((feedback: any) => {
          // Highlight aiBox and set borders to this cell
          const pageNo = feedback[this.level1_1.modelKey]?.pageNum >= 0 ? feedback[this.level1_1.modelKey]?.pageNum : feedback[this.level2_1.modelKey]?.pageNum;
          if ((this.spreadsheetObj?.activeSheetIndex + 1) == pageNo) {
              // Checking here of the record is on ai box, for such records feedback will only have qnaFeedback
              if (this.selectedResult[this.level1_1.modelKey]?.position && this.selectedResult[this.level1_1.modelKey].position.toString() == feedback[aiBoxModelKey]?.boundingBox.position.toString()) {
                  // Highlighting answer feedback on AiBox in dark green
                  const { position, startIndex, endIndex } = feedback[aiBoxModelKey].boundingBox;
                  const cellElement = this.spreadsheetObj.getCell(position[0], position[1]);
                  // Styling the cell containing AI result with dotted borders as we have in PDF/images
                  cellElement.classList.add('aiBoxStyle')
                  this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, startIndex, endIndex, this.level2_1?.highlightColor);
                  aiBoxDrawnOnLoad = true;
              } else {
                  const { position, startIndex, endIndex } = feedback[this.level1_1.modelKey].boundingBox;
                  const { boundingBox = {}, text: level2_1FeedbackText = '' } = (this.level2_1 && feedback[this.level2_1.modelKey]) || {};
                  const { startIndex: level2_1StartIndex = null, endIndex: level2_1EndIndex = null } = boundingBox || {};
                  const level2_1FeedbackParam = { level2_1StartIndex, level2_1EndIndex, level2_1FeedbackText };
                  const cellElement = this.spreadsheetObj.getCell(position[0], position[1]);
                  // Styling cell to highlight the manual semantic feedback text provided by User
                  cellElement.classList.add('manualFeedbackBoxStyleExcel')
                  this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, startIndex, endIndex, this.level1_1.highlightColor, level2_1FeedbackParam);
              }
          }
      });
    }

    // Drawing ai boc and highlighting the answer if no manual feedback present on it
    if (!aiBoxDrawnOnLoad && (this.spreadsheetObj?.activeSheetIndex + 1) == this.selectedResult[this.level1_1.modelKey].page && this.selectedResult[this.level1_1.modelKey]?.position) {
      const cellElement = this.spreadsheetObj.getCell(this.selectedResult[this.level1_1.modelKey].position[0], this.selectedResult[this.level1_1.modelKey].position[1]);
      cellElement && cellElement.classList.add('aiBoxStyle');
      if (cellElement && this.selectedResult[aiBoxModelKey]?.text) {
          // Highlighting ai answer on AiBox in purple
          const { answerStart, answerEnd } = this.getAnswerIndicesOnFormattedText(cellElement.textContent);
          this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, answerStart, answerEnd, this.level1_1.aiBox?.highlightColor);
      }
    }
  }

  // Function gets triggered once the spreadsheet component is ready
  async created() {
    try {
      this.excelLoading = true;
      const result = await this.apiService.get(`download/original/${this.selectedResult.documentId}/`, { type: 'excel-parsing' }, '').toPromise();

      // Setting the activesheet to 0 i.e first sheet
      const activePageNumber = (this.selectedResult.USEModel.page - 1) || 0;
      result.Workbook.activeSheetIndex = activePageNumber;

      // Removing by default selected range from all the sheets
      result.Workbook.sheets.forEach(sheet => sheet.selectedRange = null);

      // Syncfusion uses these attributes 'paneTopLeftCell' and 'topLeftCell' to position the cell assigned to them on top left corner
      const activeSheet = result.Workbook.sheets[activePageNumber];
      const attrName = activeSheet?.frozenRows ? 'paneTopLeftCell' : 'topLeftCell';
      const boundingBox = this.selectedResult.USEModel.position;
      //***TO DO: test a scenario where we get the cell address of AA9 
      activeSheet[attrName] = `${String.fromCharCode(boundingBox[1] + 65)}${boundingBox[0] + 1}`;

      // removing by default selected range from all the sheets
      result.Workbook.sheets.forEach((sheet: any) => sheet.selectedRange = this.dummyNonExistentCell);

      this.spreadsheetObj?.openFromJson({ file: result });
    } catch (error) {
      console.log('error occured', error);
    } finally {
      this.excelLoading = false;
    }
  }

  onActionComplete = (args) => {
    switch (args.action) {
        case "gotoSheet": {
            // Getting called when user switch between sheets
            // Focusing on aiBox after sheet changes using initial scroll positions
            if (this.selectedResult.pageNumber == (this.spreadsheetObj?.activeSheetIndex + 1)) {
                setTimeout(() => {
                    // vertical scroll container
                    const sheetPanelComponent = document.getElementsByClassName('e-main-panel')[0];
                    // horizontal scroll container
                    const scrollerComponent = document.getElementsByClassName('e-scroller')[0];
                    // scroll to the top
                    sheetPanelComponent && sheetPanelComponent.scrollTo({ top: this.initialScrollPositions.top, behavior: 'smooth' });
                    // scroll to the left
                    scrollerComponent.scrollTo({ left: this.initialScrollPositions.left, behavior: 'smooth' });
                })
            }
            break;
        }
        default:
            break;
    }
  }

  dataBoundToSheet = () => {
      // This funciton gets called whenever a sheet gets loaded with data
      setTimeout(() => {
        // vertical scroll container
        const sheetPanelComponent: any = document.getElementsByClassName('e-main-panel')[0];
        // horizontal scroll container
        const scrollerComponent: any = document.getElementsByClassName('e-scroller')[0];

        // Saving initial scrollbar positions for aibox, and using it on sheet change
        (sheetPanelComponent.scrollTop || scrollerComponent.scrollLeft)
          && !(this.initialScrollPositions?.top || this.initialScrollPositions?.left)
          && (this.initialScrollPositions = { top: sheetPanelComponent.scrollTop, left: scrollerComponent.scrollLeft });

        // Adjusting heights for main panel element, as its messing with scrollbar
        // header panel container
        const headerPanelHeight: any = document.getElementsByClassName('e-header-panel')[0];
        sheetPanelComponent.style.height = `calc(100% - ${headerPanelHeight.offsetHeight + scrollerComponent.offsetHeight}px)`;

        // Highlighting manual feedbacks, answer and adding borders to aiBox
        this.highlightCellTextOnLoad();
      }, 200)
  }

  cellBeingEdited() {   
    // Restricting user from editing any cell here, while keeping allowEditing flag to true
    // allowEditing flag when set to false it was disabling editing cells, but was not displaying any formula calculated values.
    this.spreadsheetObj.closeEdit();
  }

  handleSelect(args: any) {
    this.spreadsheetObj.getData(args.range).then(data => {
      // args.range gives us a cell range e.g. - "B2:B2", and we want to convert and save the range as [1, 1]
      const cellRange = args.range.split(':')[0];
      // If condition will check if the cell has text in it, works in case of images in cell
      const cellPosition = getCellIndexes(cellRange);
      const cell = getCell(cellPosition[0], cellPosition[1], this.spreadsheetObj.getActiveSheet());
      const displayText = this.spreadsheetObj.getDisplayText(cell);
      if (displayText?.length) {
        const resultLevel1_1 = this.selectedResult[this.level1_1.modelKey];
        const isItAiBox = ((this.spreadsheetObj?.activeSheetIndex + 1) == resultLevel1_1.page && resultLevel1_1.position && cellPosition.toString() == resultLevel1_1.position.toString());

        this.setOpenCellTextPopup(null, true);
        const selectionKey = `${cellPosition.join('-')}-${this.spreadsheetObj?.activeSheetIndex + 1}`;
        const savedFeedback = this.selectedFeedbacks?.[selectionKey];
        this.isAiBox = isItAiBox;
        this.selectedCellPosition = cellPosition;
        // Setting default values for currentBox if present otherwise an empty object
        if (savedFeedback) {
          this.setCurrentFeedback(savedFeedback[this.level1_1.modelKey] || {});
          this.level2_1 && savedFeedback[this.level2_1?.modelKey] && this.setCurrentFeedback(savedFeedback[this.level2_1.modelKey], false, true);
        }

        this.selectedCellData = {
            ...data?.get(cellRange),
            value: displayText
        }

        // Create svg layer once the popup is open and we have selectedCellData
        setTimeout(() => this.bindSVGLayerToPage(), 100);
      }
    });
  }

  // Creating span elemenents for cell text to the popup
  getSelectedTextInSpans() {
    // Popup has width of 420px, considering 40px buffer calculation done on 380pixels
    const popupTextElement = document.getElementById('popupTextElement');
    const popupWidth = popupTextElement ? (popupTextElement.offsetWidth - 40) : 380;
    const allLineSpans = [];
    const allWordSpans = [];
    const aiAnswerSpans = [];
    let text = this.selectedCellData.value;
    let lineNo = 0;
    let startIndex = 0;
    let spaceEndedButTextInLine = false; // this flag represents if there is no space left on UI line but the line text is still remaining
    let newLineFromText;
    if (text && !popupTextElement?.children?.length) {
        const newLinesFromTextArr = text.split('\n'); // to represent newlines from excel cell, took out all the lines
        // eslint-disable-next-line no-param-reassign, no-return-assign
        newLinesFromTextArr.forEach((newLine, index) => index > 0 && (newLinesFromTextArr[index] = `\n${newLine}`));
        // Adding a check to see if its a aiBox
        const { answerStart = -1, answerEnd = -1 } = this.isAiBox ? this.getAnswerIndicesOnFormattedText() : {};
        while (text) {
            const newLineSpan = document.createElement('span');
            newLineSpan.setAttribute('lineNo', `${lineNo}`); // Giving every span a line no from text
            newLineFromText = !spaceEndedButTextInLine ? newLinesFromTextArr[lineNo] : newLineFromText; // in this case, continue with remaining text from line
            let lineCompleted = false; // represents that there is no space left on UI line
            let spaceRemainingInLine = popupWidth;
            while (text && newLineFromText && !lineCompleted && spaceRemainingInLine) {
                const wordArray = newLineFromText.split(/(?:(?!\n)(\s+))/);
                // if there are extra spaces in start or end of the sentence, remove '' elements created in the array
                if (!wordArray[0]) {
                    wordArray.splice(0, 1);
                }
                if (!wordArray[wordArray.length - 1]) {
                    wordArray.splice(wordArray.length - 1, 1);
                }

                const isItLastWordFromLineText = wordArray.length == 1; // checking if its a last word before new line in text
                if (newLineFromText && spaceRemainingInLine >= getTextWidth(wordArray[0], `${this.fontSize}px ${this.fontFamily}`)) {
                    const wordSpan = document.createElement('span');
                    wordSpan.innerHTML = `${wordArray[0]}`.replace(/[<]/g, '&lt');
                    const endIndex = startIndex + wordArray[0].length - 1;
                    wordSpan.setAttribute('startIndex', startIndex.toString());
                    wordSpan.setAttribute('endIndex', endIndex.toString());
                    // Highlight answer in yellow in aibox cell if presents
                    if (this.isAiBox && (this.level2_1 && this.selectedResult[this.level2_1.modelKey].text) && answerStart >= 0 && answerEnd >= 0
                        && ((startIndex >= answerStart || (answerStart > startIndex && answerStart <= endIndex))
                        && (endIndex <= answerEnd || (endIndex >= answerEnd && (endIndex - answerEnd) <= (endIndex - startIndex))))) {
                        aiAnswerSpans.push(wordSpan);
                    }
                    startIndex += wordArray[0].length;
                    newLineSpan.appendChild(wordSpan);
                    allWordSpans.push(wordSpan);
                    spaceRemainingInLine -= getTextWidth(wordArray[0], `${this.fontSize}px ${this.fontFamily}`);
                    text = text.substr(wordArray[0].length);
                    newLineFromText = isItLastWordFromLineText ? '' : newLineFromText.substr(wordArray[0].length);
                } else {
                    if (newLineFromText) {
                        spaceEndedButTextInLine = true;
                    }
                    lineCompleted = true;
                }
            }
            !newLineFromText && lineNo++; // when the text is finished from line increase line count
            !newLineFromText && (spaceEndedButTextInLine = false); // when all line text rendered, clear the flag
            popupTextElement.appendChild(newLineSpan);
            allLineSpans.push(newLineSpan);
            popupTextElement.appendChild(document.createElement('br'));
        }

        // Saving lineSpans and wordSpans for further usage, so we don't need to access DOM to get wordSpans
        this.metaDataOfPopup.allLineSpans = allLineSpans;
        this.metaDataOfPopup.allWordSpans = allWordSpans;
        this.metaDataOfPopup.text = allWordSpans.map((wordSpan) => wordSpan.textContent).join('');
        this.metaDataOfPopup.aiAnswerSpans = aiAnswerSpans;
    }
  }

  saveAndClosePopup(event) {

    // Highlight the selected feedback and style the borders
    const cellElement = this.spreadsheetObj.getCell(this.selectedCellPosition[0], this.selectedCellPosition[1]);
    const { boundingBox = {}, text } = this.currentFeedback[this.level1_1.modelKey] || {};
    const { startIndex, endIndex } = boundingBox;
    const level2_1Feedback = this.level2_1 ? this.currentFeedback[this.level2_1.modelKey] : null;
    const { boundingBox: level2_1BoundingBox = {}, text: level2_1FeedbackText = '' } = level2_1Feedback || {};
    const { startIndex: level2_1StartIndex = null, endIndex: level2_1EndIndex = null } = level2_1BoundingBox;
    const level2_1FeedbackParams = { level2_1StartIndex, level2_1EndIndex, level2_1FeedbackText };
    // Styling cell to highlight the manual semantic feedback text provided by User
    if (!text && !level2_1FeedbackText) {
        cellElement.classList.remove('manualFeedbackBoxStyleExcel');
    }
    text && !this.isAiBox && cellElement.setAttribute('class', `${cellElement.className} manualFeedbackBoxStyleExcel`)

    if (this.isAiBox) {
        if (level2_1StartIndex || level2_1EndIndex) {
            // if any manual feedback saved then highlight in green
            this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, level2_1StartIndex, level2_1EndIndex, '#008000');
        } else {
            // Else highlighting ai answer on AiBox in purple
            const { answerStart, answerEnd } = this.getAnswerIndicesOnFormattedText(cellElement.textContent);
            this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, answerStart, answerEnd, this.level1_1.aiBox?.highlightColor);
        }
    } else {
        this.highlightPartOfTextOnCell(cellElement, cellElement.textContent, startIndex, endIndex, (startIndex || endIndex) ? '#008000' : 'inherit', level2_1FeedbackParams);
    }

    this.updateSelectedFeedbacks();
    this.setOpenCellTextPopup(event, false);
  }

  checkIfHighlightPresent = () => {
    const svgGroup = document.getElementsByClassName("svgGroup")[0];
    const highlightType = this?.[this.feedbackInAction].highlightType;
    return !!(svgGroup && svgGroup.querySelector(`[highlightType="${highlightType}"]`));
  }

  feedbackButtonClicked(event, feedbackBtn: string) {
    event?.preventDefault();
    event?.stopPropagation();
    this.feedbackInAction = feedbackBtn;
    this.showActionBtns = true;

    if (!this.checkIfHighlightPresent()) {
      // Checking if there is saved feedback or its a new feedback
      const takeValuesFromFeedback = this.currentFeedback && this.currentFeedback[this.level1_1.modelKey];
      const startIndex = takeValuesFromFeedback?.boundingBox ? takeValuesFromFeedback.boundingBox.startIndex : 0;
      const endIndex = takeValuesFromFeedback?.boundingBox ? takeValuesFromFeedback.boundingBox.endIndex : this.selectedCellData?.value?.length - 1;
      const text = takeValuesFromFeedback ? takeValuesFromFeedback.text : this.selectedCellData?.value;
      this.highlightFeedbackOnPopup(startIndex, endIndex, text);
    }

    this.createCursorsAndAddEvent();
  }

  saveBtnClicked(event) {
    event.stopPropagation();
    switch (this.feedbackInAction) {
        case 'level1_1': {
            if (this.level2_1) {
                // Show Level2_1 button once level1_1 feedback given
                this.showLevel2_1Btn = true;
            }
            break;
        }

        case 'level2_1': {
            break;
        }

        default:
            break;
    }

    this.enableSavaAndCloseBtn = true;
    this.showActionBtns = false;
    // Scenario: User clicks on save button wihtout moving the cursors
    this.feedbackBeforeValidationErr = JSON.parse(JSON.stringify(this.currentFeedback));
    removeCursorElements();
  }

  copyBtnClicked = () => {
    const { text } = this.feedbackInAction == 'level2_1' ? this.currentFeedback[this.level2_1.modelKey] : this.currentFeedback[this.level1_1.modelKey];
    navigator.clipboard.writeText(text);
  }

  updateSelectedFeedbacks() {
    // Saving copies of selected feedbacks in store, need these in multi-sheet excel feedbacks
    // selectionKey === cellpositionX-cellPositionY-page e.g. 45-6-1
    const selectionKey = `${this.selectedCellPosition.join('-')}-${this.spreadsheetObj?.activeSheetIndex + 1}`;
    this.selectedFeedbacks[selectionKey] = JSON.parse(JSON.stringify(this.currentFeedback));

    // Calculate these selection operations everytime 
    const selections = [];
    Object.keys(this.selectedFeedbacks).forEach((feedbackKey: any) => {
      const selectionPayload = JSON.parse(JSON.stringify(this.selectedFeedbacks[feedbackKey]));
      // Adding a condition to check if level1_1 present, because it won't be present in case of AI box
      selectionPayload[this.level1_1.modelKey] && delete selectionPayload[this.level1_1.modelKey].boundingBox.startCursor;
      selectionPayload[this.level1_1.modelKey] && delete selectionPayload[this.level1_1.modelKey].boundingBox.endCursor;

      if (this.level2_1 && selectionPayload[this.level2_1.modelKey]) {
        delete selectionPayload[this.level2_1.modelKey].boundingBox.startCursor;
        delete selectionPayload[this.level2_1.modelKey].boundingBox.endCursor;
      }

      const aiBoxModelKey = this.level1_1?.aiBox?.modelKey;
      const isItAiBox = ((this.spreadsheetObj?.activeSheetIndex + 1) == this.selectedResult[this.level1_1.modelKey].page && this.selectedResult[this.level1_1.modelKey].position 
        && (selectionPayload[aiBoxModelKey] || this.dbSavedFeedbacks[feedbackKey]?.[aiBoxModelKey])?.boundingBox.position.toString() == this.selectedResult[this.level1_1.modelKey].position.toString());

      // In case of delete - selectionPayload is getting blank 
      if (this.dbSavedFeedbacks[feedbackKey]) {
        // If the feedbackKey present in dbSavedFeedbacks object, it means its either a update or delete operation

        // Delete cases
        if (this.dbSavedFeedbacks[feedbackKey][this.level1_1.modelKey] && !selectionPayload[this.level1_1.modelKey]) {
          // level1_1 feedback delete case, delete level2_1 feedback as well
          const deletePayload: any = {};
          deletePayload.operation = 'delete';
          deletePayload[this.level1_1.modelKey] = {};
          deletePayload[this.level1_1.modelKey].id = this.selectedResult[this.level1_1.modelKey].feedback._id;
          deletePayload[this.level1_1.modelKey].selectionId = this.dbSavedFeedbacks[feedbackKey][this.level1_1.modelKey].id;

          if (this.level2_1 && this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey]) {
            deletePayload[this.level2_1.modelKey] = {};
            deletePayload[this.level2_1.modelKey].id = this.selectedResult[this.level2_1.modelKey].feedback._id;
            deletePayload[this.level2_1.modelKey].selectionId = this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey].id;
          }

          selections.push(deletePayload);
        } else {
          // only level2_1 feedback delete case
          if (this.level2_1 && this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey] && !selectionPayload[this.level2_1.modelKey]) {
            const deletePayload: any = {};
            deletePayload.operation = 'delete';

            if (isItAiBox) {
              deletePayload[this.level1_1.modelKey] = {};
              deletePayload[this.level1_1.modelKey].id = this.selectedResult[this.level1_1.modelKey].feedback._id;
              deletePayload[this.level1_1.modelKey].selectionId = this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey].parentId;
            }

            deletePayload[this.level2_1.modelKey] = {};
            deletePayload[this.level2_1.modelKey].id = this.selectedResult[this.level2_1.modelKey].feedback._id;
            deletePayload[this.level2_1.modelKey].selectionId = this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey].id;
            selections.push(deletePayload);
          }

          // Update cases
          // Level1_1 feedback got updated
          if (JSON.stringify(this.dbSavedFeedbacks[feedbackKey][this.level1_1.modelKey]) !== JSON.stringify(selectionPayload[this.level1_1.modelKey])) {
            const updatePayload: any = {};
            updatePayload.operation = 'update';
            updatePayload[this.level1_1.modelKey] = JSON.parse(JSON.stringify(selectionPayload[this.level1_1.modelKey]));
            updatePayload[this.level1_1.modelKey].id = this.selectedResult[this.level1_1.modelKey].feedback._id;
            updatePayload[this.level1_1.modelKey].selectionId = selectionPayload[this.level1_1.modelKey].id || this.dbSavedFeedbacks[feedbackKey][this.level1_1.modelKey].id;

            if (this.level2_1 && selectionPayload[this.level2_1.modelKey] && this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey]
               && JSON.stringify(this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey]) !== JSON.stringify(selectionPayload[this.level2_1.modelKey])) {
              // level2_1 feedback has also been updated with level1_1
              updatePayload[this.level2_1.modelKey] = JSON.parse(JSON.stringify(selectionPayload[this.level2_1.modelKey]));
              updatePayload[this.level2_1.modelKey].id = this.selectedResult[this.level2_1.modelKey].feedback._id;
              // In case user clears the answer feedback on validation popup, and then updates it, taking th selection id from db saved feedbacks
              updatePayload[this.level2_1.modelKey].selectionId = selectionPayload[this.level2_1.modelKey].id || this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey].id;
            }
            selections.push(updatePayload);
          } else if (this.level2_1 && selectionPayload[this.level2_1.modelKey] && this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey] 
            && JSON.stringify(this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey]) !== JSON.stringify(selectionPayload[this.level2_1.modelKey])) {
            // Only level2_1 feedback got updated
            const updatePayload: any = {};
            updatePayload.operation = 'update';
            updatePayload[this.level2_1.modelKey] = JSON.parse(JSON.stringify(selectionPayload[this.level2_1.modelKey]));
            updatePayload[this.level2_1.modelKey].id = this.selectedResult[this.level2_1.modelKey].feedback._id;
            updatePayload[this.level2_1.modelKey].selectionId = selectionPayload[this.level2_1.modelKey].id;
            selections.push(updatePayload);
          }

          // Level1_1 feedback already exists and user adding level2_1 feedback on it
          if (this.level2_1 && selectionPayload[this.level2_1.modelKey] && !this.dbSavedFeedbacks[feedbackKey][this.level2_1.modelKey] ) {
            const insertLevel2_1Feedback: any = {};
            insertLevel2_1Feedback.operation = 'insert';
            insertLevel2_1Feedback.parentId = selectionPayload[this.level1_1.modelKey].id; // Adding parentId
            selectionPayload[this.level2_1.modelKey][this.level2_1.idName] = this.selectedResult[this.level2_1.modelKey][this.level2_1.idName]; // Adding answerId
            insertLevel2_1Feedback[this.level2_1.modelKey] = JSON.parse(JSON.stringify(selectionPayload[this.level2_1.modelKey]));
            selections.push(insertLevel2_1Feedback);
          }
        }
      } else if (selectionPayload[this.level1_1.modelKey] || selectionPayload[aiBoxModelKey]) {
        if (isItAiBox) {
          // Adding inplicitFlag into level1_1 in case of aiBox
          selectionPayload[this.level1_1.modelKey] = {
            implicit: true
          }
        }

        // Insert the new feedback
        if (this.level2_1 && selectionPayload[this.level2_1.modelKey]) {
          // Adding answerId in level2_1 feedback
          selectionPayload[this.level2_1.modelKey][this.level2_1.idName] = this.selectedResult[this.level2_1.modelKey][this.level2_1.idName];
        }
        selections.push({ operation: 'insert', ...selectionPayload });
      }
    });

    // Maintaining the global copy with only the selections with insert/update/delete operation, calculating selections evrytime
    this.setManualFeedbackSelections.emit(selections);
  }

  clearBtnClicked = (event) => {
    event.stopPropagation();
    this.disableSaveBtn = false;
    switch (this.feedbackInAction) {
        case 'level1_1': {
          // Take it out in common for both the cases
            // Purple highlighted ai answer should re-highlighted back, on clearing level1_1 feedback
            if (this.metaDataOfPopup?.aiAnswerSpans?.length) {
                // Highlight ai answer back in yellow color
                const svg = document.querySelector('.drawingPlane');
                const group = document.getElementsByClassName("svgGroup")[0];
                this.highlightAiAnswer(group, svg);
            }

            // Don't show Level2 highlights and button once lvel1_1 feedback is cleared
            this.showLevel2_1Btn = false;
            this.level2_1 && removeHighlightRects(this[this.feedbackInAction], this.level2_1.highlightType);
            break;
        }

        case 'level2_1': {
          // Purple highlighted ai answer should re-highlighted back, on clearing level1_1 feedback
          if (this.metaDataOfPopup?.aiAnswerSpans?.length) {
              // Highlight ai answer back in yellow color
              const svg = document.querySelector('.drawingPlane');
              const group = document.getElementsByClassName("svgGroup")[0];
              this.highlightAiAnswer(group, svg);
          }
            break;
        }

        default:
            break;
    }

    this.setCurrentFeedback({}, true);
    this.enableSavaAndCloseBtn = true;
    this.showActionBtns = false;
    removeHighlightRects(this[this.feedbackInAction]);
    removeCursorElements();
  }

  showEditButton(modelKey: string) {
    return this.currentFeedback?.[modelKey] && Object.keys(this.currentFeedback[modelKey]).length;
  }
}
