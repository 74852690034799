import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { LinkService } from '../../../services/link.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';
import { Subject } from 'rxjs';
import { QuerysheetManagementComponent } from '../querysheet-management.component';
import { GlobalService } from 'src/app/services/global.service';
import { ValidationSearchService } from '../../../components/validations-filter/validation-search.service';
import { AnalysisFailedDocumentsModalComponent } from 'src/app/components/modals/analysis-failed-documents-modal/analysis-failed-documents-modal.component'


@Component({
  selector: 'app-exploration-list-view',
  templateUrl: './querysheet-exploration-list-view.component.html',
  styleUrls: ['./querysheet-exploration-list-view.component.scss']
})

export class ExplorationListViewComponent implements OnInit {
  @Input() explorationLoading;
  @Input() data;
  @Input() settings;
  public columns: Array<any> = [];
  public tableSettings: any;
  public extraColumns: string[] = [];
  public statusList = [];
  @Input() analysisList = [];
  public rows: {};
  @Input() totalExplorations: number = 0;
  // Filter variables
  public filters: any = {};
  public labelFilters: any[];
  public showFilters: boolean = false;

  public itemsSelected = [];
  public countItemsSelected: number;
  public responseTableData = {};
  public reRunDocumentDisabled = [];

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  //Property to emit refresh results 
  @Output() getExplorationsFromDB = new EventEmitter();

  public backgroundLoading: boolean;
  constructor(
    private translate: TranslatePipe,
    private link: LinkService,
    private querysheetManagementComponent: QuerysheetManagementComponent,
    private global: GlobalService,
    private modalService: NgbModal,
    private apiService: ApiService,
    private alertService: AlertService,
    public validationSearchService: ValidationSearchService
  ) { }

  ngOnInit(): void {
    this.getTableSettings();
    this.getColumns();
    this.statusList = this.global.getGlobalStatus();
    this.filters = {};
  }

  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'explorationList.explorationNameTableHeader',
        name: 'explorationName',
        orderBy: 'explorationName',
        title: 'explorationList.explorationNameTableHeader',
        type: 'custom',
        namefunc: (rowData: any) => {
          if (rowData.isOutdated && !rowData.querysheet.isActive) {
            // If a mapped querysheet gets deleted
            return `<img src='/assets/img/icons/warning-yellow.svg' class="icon-info"
                    title="${this.translate.transform('explorationList.mappedQuerysheetDeletedMsg')}">
                  <span title="${rowData.explorationName}">
                    <a class="ellipsis-text" >${rowData.explorationName}</a>
                  </span>`;
          } else if (rowData.isOutdated && (!(rowData.faileddocumentcount || rowData.processingdocumentcount || rowData.pendingdocumentcount) 
                                              && !['processing', 'pending', 'failed'].includes(rowData.querysheet?.querySheetStatus)
                                              && !['in-progress'].includes(rowData.statusdetails?.statusname))) {
            // If documents from the exploration gets deleted, added or un-mapped
            return `<img src='/assets/img/icons/warning-yellow.svg' class="icon-info"
                    title="${this.translate.transform('explorationList.explorationOutdatedMsg')}&#13;&#10;${this.translate.transform('explorationList.generateLatestResultsMsg')}">
                  <span title="${rowData.explorationName}">
                    <a class="ellipsis-text" >${rowData.explorationName}</a>
                  </span>`;
          } else {
            return `<span title="${rowData.explorationName}">
                      <a class="ellipsis-text" >${rowData.explorationName}</a>
                    </span>`;
          }
        },
        event: rowData => {
          return this.gotToExploration(rowData)
        }
      },
      {
        header: 'explorationList.explorationDateTableHeader',
        name: 'createdDate',
        orderBy: 'createdDate',
        type: 'date',
        format: 'lll'
      },
      {
        header: 'explorationList.querysheetTableHeader',
        name: 'querysheet',
        orderBy: 'querySheetName',
        title: 'explorationList.querysheetTableHeader',
        type: 'custom',
        namefunc: (rowData: any) => {
            return `<span class="ellipsis-text" title="${rowData.querysheet.querySheetName}">${rowData.querysheet.querySheetName}</span>`;
        },
      },
      {
        header: 'explorationList.analysisTableHeader',
        title: 'explorationList.analysisTableHeader',
        type: 'custom',
        namefunc: (rowData: any) => {
          let text = "";
          let analysisList = []
          let count = 0;
          rowData.analyses.forEach((analysis, index) => {
            if (index == 0) {
              if (analysis.analysisName.length > 24) {
                text = analysis.analysisName.slice(0, 24)
                text += '...'
              } else {
                text = analysis.analysisName
              }

            } else {
              count++;
            }
            analysisList.push(analysis.analysisName)
          })
          let additionalCount = count > 0 ? `, +${count}` : '';
          text = `${text}${additionalCount}`

            return `<span title="${analysisList.join(', ')}">${text}</span>`;
        }

      },
      {
        header: 'explorationList.numberOfDocumentTableHeader',
        title: 'explorationList.numberOfDocumentTableHeader',
        type: 'custom-exploration-doc',
        name: 'status_data',
        namefunc: (rowData: any) => {
          /*
           Below class Name are Identifier to trigger the events and API Methods            
           *** explorationDocFailure, reRunDocument and failureDocumentInformation
           */
          if (rowData.faileddocumentcount) {
            return `<span class="nodoc-reRunDocument-width">
                      <span class="error-text"><a class="failedDocuments" id="failedDoc"><span class="icon-status"><span class="material-icons close">close</span></span> ${rowData.faileddocumentcount} </a></span><span> / ${rowData.documentcount}</span></span>         
                    ${rowData.enablererun ? 
                      `<span title="${this.translate.transform('explorationListFilter.reRunDocument')}" class=${this.reRunDocumentDisabled?.indexOf(rowData.explorationId)>=0?"reRunDocumentDisabled":"reRunDocument"}>${this.translate.transform('explorationListFilter.reRunDocument')}</span>`
                    :`<span class="failureDocumentInformation" title="${this.translate.transform('explorationListFilter.noOfDocumentFailureMsg')}" >${this.translate.transform('explorationListFilter.failure')}
                        <img src="/assets/img/info-icon.svg" class="fa fa-info" />
                      </span>`
                    }`;
          } else if (rowData.processingdocumentcount || rowData.pendingdocumentcount) {
            return `<span class="nodoc-expoDocFailure-width"><span class="icon-status"><span class="material-icons sync">sync</span></span> ${rowData.processingdocumentcount + rowData.pendingdocumentcount} / ${rowData.documentcount} </span>`;
          } else if (rowData.successdocumentcount) {
            return `<span class="nodoc-expoDocFailure-width"><span class="icon-status"><span class="material-icons check">check</span></span>  ${rowData.successdocumentcount} </span>
            </span>
            `;
          }
        },
        event: (event, rowData) => {
          if (event.target.className === 'reRunDocument') {
            this.reRunDocument(rowData);

          } else if (event.target.className === 'failedDocuments') {
            this.analysisListFailedDocumentModal(rowData);
          }
        }
      }
    ];
  }

  public reRunDocument(data): void {
    const url= 'explorations/rerun';
    const params={
      "explorationid":data.explorationId
    }
    this.reRunDocumentDisabled.push(data.explorationId)
    this.apiService.post(url, params, '').subscribe(
      (data) => {
        if(!data) {
          this.alertService.warn(
            this.translate.transform(
              'querysheetManagement.noFailedDocumentsFound'
            )
          );
          this.reRunDocumentDisabled=[]; 
        }
        else{
          this.alertService.success(
          this.translate.transform(
            'querysheetManagement.documentsRerunSuccessfully'
          ));
          this.reRunDocumentDisabled=[]; 
          this.settings['getDataFromDB']();
        }
      },
      (error:any)=>{
        this.alertService.error(
          this.translate.transform(
            'querysheetManagement.documentsRerunFailed'
          ));
        this.reRunDocumentDisabled=[];  
        this.settings['getDataFromDB']();
      }
    );
  }

  public getTableSettings(): void {
    this.tableSettings = {
      dataId: 'explorationId',
      hasSelect: 'true',
      hasEditPermissions: true,
      getDataFromDB: this.settings['getDataFromDB'],
      hasDownloadOptions: false,
      extraColumns: this.extraColumns,
      actionsOnSelected: [
        {
          click: this.deleteSelectedExplorations.bind(this),
          class: 'fa fa-trash',
          title: 'explorationList.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteExploration.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'explorationName',
        value: 'createdDate',
        formatDate: 'lll'
      }
    };
  }

  /**
   * Delete Single/multiple explorations
   */
  public deleteSelectedExplorations(selectedExplorations): void {
    this.deleteExplorationsModal(selectedExplorations);
  }

  /**
   * Small screen - Delete Single/multiple explorations
   */
  public deleteExploration(selectedExploration): void {
    this.deleteExplorationsModal(selectedExploration);
  }

  private deleteExplorationsModal(explorationids: number[]) {
    if (explorationids.length < 1) {
      // Break if array is empty
      return;
    }

    const url = 'explorations/delete';
    const params = { 'ids': explorationids };

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: explorationids.length > 1 ? 'explorations' : 'exploration',
      message: this.translate.transform('querysheetManagement.explorationDeleteConfirmation')
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.explorationLoading = true;
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              // Exploration/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.explorationDeletedSuccessfully'
                )
              );

              this.settings['getDataFromDB']();
            }
          );
        }
      }
    );
  }

  /**
   * Go to exploration
   */
  public gotToExploration(exploration): void {
    this.filters = {};
    this.validationSearchService.setPreviousSearch(
      this.filters,
      'explorations'
    );
    this.link.gotToExploration(exploration);
  }
  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }
  public cleanIndividualFilter(filter: string) {
    this.filterEvent.next(filter);
    if (filter === 'explorationName') {
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'explorationdisplayname_logic');
    }
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    const filteredItem = {}
    Object.keys(this.filters).forEach(f => {
      const filtersArr = [];
      if (filter === 'Analysis Name') {
        filter = "analysisids";
      }
      filtersArr.push(filter);
      if (filter === 'explorationName') {
        filtersArr.push("explorationdisplayname_logic");
      } else if (filter == 'status_name') {
        filtersArr.push("statusId");
      }

      if (!filtersArr.includes(f)) {
        filteredItem[f] = this.filters[f]
      }
    })
    this.filters = { ...filteredItem }
    this.validationSearchService.setPreviousSearch(
      this.filters,
      'explorations'
    );
    this.onBackgroundLoadingChange(true);
    this.querysheetManagementComponent.getExplorationsFromDB(this.filters, () => this.onBackgroundLoadingChange(false));
  }

  public onSearch(filters) {
    if (Object.keys(filters).length == 1 && filters['analysisids'].length == 0) {
      this.filters = {}
    } else {
      Object.keys(filters).forEach(key => {
        if (key == 'start_date' || key == 'end_date' || key == 'explorationName' || key == 'analysisids' || key == 'status_name' || key == 'statusId' || key == 'explorationdisplayname_logic') {
          if (key == 'start_date' || key == 'end_date') {
            const dateObj = new Date(filters[key]);
            const filterDate = dateObj.getFullYear() + ' ' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + ' ' + ('0' + (dateObj.getDate())).slice(-2)
            let dateToCompare = new Date();
            if (key == 'start_date') {
              dateToCompare.setFullYear(dateToCompare.getFullYear() - 10);
            }
            let dateToCompareString = dateToCompare.getFullYear() + ' ' + ('0' + (dateToCompare.getMonth() + 1)).slice(-2) + ' ' + ('0' + (dateToCompare.getDate())).slice(-2)
            if (dateToCompareString == filterDate) {
              delete this.filters[key];
            } else {
              this.filters[key] = filterDate;
            }
          } else {
            if (filters[key] && (key !== 'analysisids' || (key == 'analysisids' && filters[key].length > 0))) {
              // In case of analysisids, if it has length > 0 then only update otherwise delete the filter key
              this.filters[key] = filters[key]
            } else {
              delete this.filters[key]
            }
          }
        }
      });
    }
    let labelFilters = [];
    Object.keys(this.filters).forEach(key => {
      if (key == 'analysisids') {
        let name = [];
        this.analysisList.forEach(analysis => {
          if (this.filters[key].indexOf(analysis.analysisid) > -1) {
            name.push(analysis.analysisname_processed)
          }
        });
        labelFilters.push({
          'name': key,
          'value': name.join(', ')
        })
      } else if (key == 'start_date' || key == 'end_date') {
        let dateObj = new Date(this.filters[key])
        labelFilters.push({
          'name': key,
          'value': `${dateObj.getFullYear()}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}-${('0' + (dateObj.getDate() + 1)).slice(-2)}`
        })
      } else if (this.filters[key] && key != "statusId") {
        labelFilters.push({
          'name': key,
          'value': this.filters[key]
        })
      }
    })
    this.labelFilters = [...labelFilters];
    if (!(this.labelFilters?.length == 1 && this.labelFilters[0].name == 'explorationdisplayname_logic')) {
      this.onBackgroundLoadingChange(true);
      this.querysheetManagementComponent.getExplorationsFromDB(this.filters, () => this.onBackgroundLoadingChange(false));
    }
  }

  /**
  * Load modal-popup for Analysis List Failed Documents 
  */
  private analysisListFailedDocumentModal(explorationData) {

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };

    const options: any = {};
    options.data = explorationData;

    const modalWindowRef = this.modalService.open(
      AnalysisFailedDocumentsModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
  }
}
