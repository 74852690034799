import { LogoutModalComponent } from './modals/logout/logout.component';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCardComponent } from './project-card/project-card.component';
import { PipeModule } from '../pipes/pipe.module';
import { KPIComponent } from './kpi/kpi.component';
import { PagesRouting } from '../pages/pages.routing';
import { TranslateModule } from '../translate/translate.module';
import { ExportButtonComponent } from './renderer/export-button/export-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteModalComponent } from './modals/delete/delete.component';
import { UserModalComponent } from './modals/user-modal/user-modal.component';
import { UpdateDatamodelModalComponent } from './modals/update-document-datamodel/update-document-datamodel.component';
import { SubmitFilterModalComponent } from './modals/submit-filter/submit-filter-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateDocsComponent } from './modals/update-docs/update-docs.component';
import { RenameModalComponent } from './modals/rename-modal/rename-modal.component';
import { SpinnerBallComponent } from './spinners/spinner-ball/spinner-ball.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { D3BarChartComponent } from './graphs/barchart/barchart.component';
import { D3BarChartBrushedComponent } from './graphs/barchart-brushed/barchart-brushed.component';
import { D3SunburstComponent } from './graphs/sunburst/sunburst.component';
import { D3RadarChartComponent } from './graphs/radarchart/radarchart.component';
import { D3LineChartComponent } from './graphs/linechart/linechart.component';
import { D3PieChartComponent } from './graphs/piechart/piechart.component';
import { D3HeatMapComponent } from './graphs/heatmap/heatmap.component';
import { D3SankeyComponent } from './graphs/sankey/sankey.component';
import { D3HistogramComponent } from './graphs/histogram/histogram.component';
import { ChipsAutocompleteComponent } from './chips-autocomplete/chips-autocomplete.component';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { ColumnResponsiveTableComponent } from './column-responsive-table/column-responsive-table.component';
import { SelectionViewerComponent } from './selection-viewer/selection-viewer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { ErrorModalComponent } from './modals/error/error.component';
import { TimelineComponent } from './timeline/timeline.component';
import { NewCommentComponent } from './modals/new-comment/new-comment.component';
import { DocumentRenderComponent } from './document-render/document-render.component';
import { DocumentButtonsComponent } from './document-buttons/document-buttons.component';
import { AssignReviewerComponent } from './modals/assign-reviewer/assign-reviewer.component';
import { ValidationOverrideComponent } from './validation-override/validation-override.component';
import { DocumentRelatedTableComponent } from './document-related-table/document-related-table.component';
import { CreateConsolidatedComponent } from './modals/create-consolidated/create-consolidated.component';
import { DocumentEvidencesTableComponent } from './document-evidences-table/document-evidences-table.component';
import { DocumentsTableComponent } from './documents-table/documents-table.component';
import { ValidationsFilterComponent } from './validations-filter/validations-filter.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { ClientManagementComponent } from './client-management/client-management.component';
import { ResponsiveTableModalComponent } from './modals/responsive-table-modal/responsive-table-modal.component';
import { TriStateCheckboxComponent } from './tri-state-checkbox/tri-state-checkbox.component';
import { DirectivesModule } from '../directives/directive.module';
import { LogTrackingComponent } from './log-tracking/log-tracking.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AddCommentValidationComponent } from './modals/add-comment-validation/add-comment-validation.component';
import { DocumentCommentsTableComponent } from './document-comments-table/document-comments-table.component';
import { EditRoleModalComponent } from './modals/edit-view-rol/edit-view-role.component';
import { UpdateReviewCountdownComponent } from './modals/update-review-countdown/update-review-countdown.component';
import { ReviewTrackingComponent } from './review-tracking/review-tracking.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { AssignChecklistComponent } from './modals/assign-checklist/assign-checklist.component';
import { RefreshTokenComponent } from './modals/refresh-token/refresh-token.component';
import { DocumentThumbnailsComponent } from './document-thumbnails/document-thumbnails.component';
import { DocumentDashboardComponent } from './document-dashboards/document-dashboard.component';
import { LifeUnderWritingDashboardComponent } from './document-dashboards/lifeunderwriting-dashboard/lifeunderwriting-dashboard.component';
import { LifeUnderWritingPanelComponent } from './document-dashboards/lifeunderwriting-dashboard/lifeunderwriting-panel/lifeunderwriting-panel.component';
import { DisabilityDashboardComponent } from './document-dashboards/disability-dashboard/disability-dashboard.component';
import { DisabilityPanelComponent } from './document-dashboards/disability-dashboard/disability-panel/disability-panel.component';
import { ConfirmChangeDocumentModalComponent } from './modals/confirm-change-document-modal/confirm-change-document-modal.component';
import { OpenaiInfoComponent } from './modals/openai-info/openai-info.component';
import { CreateQuerysheetComponent } from './modals/create-querysheet/create-querysheet.component';
import { QuerysheetCardComponent } from './querysheet-card/querysheet-card.component';
import { TreeComponent } from './tree/tree.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatMenuModule } from '@angular/material/menu';
import { CreateExplorationsComponent } from './modals/create-explorations/create-explorations.component';
import { SelectProjectsComponent } from './modals/create-explorations/select-projects/select-projects.component';
import { SelectDocumentsComponent } from './modals/create-explorations/select-documents/select-documents.component';
import { SelectAnalysisComponent } from './modals/create-explorations/select-analysis/select-analysis.component';
import { CreateExplorationStepComponent } from './modals/create-explorations/create-exploration-step/create-exploration-step.component';
import { DocumentItemsComponent } from './modals/create-explorations/create-exploration-step/document-items/document-items.component';
import { AnalysisFailedDocumentsModalComponent } from './modals/analysis-failed-documents-modal/analysis-failed-documents-modal.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PipeModule,
    PagesRouting,
    TranslateModule,
    //NgxDatatableModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    //NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    NgbModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTooltipModule,
    //MatOptionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatNativeDateModule,
    MatRippleModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    DirectivesModule,
    NgxJsonViewerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatProgressBarModule,
    MatDialogModule,
    TreeModule,
    MatMenuModule
  ],
  declarations: [
    ProjectCardComponent,
    KPIComponent,
    ExportButtonComponent,
    DeleteModalComponent,
    AddCommentValidationComponent,
    EditRoleModalComponent,
    UserModalComponent,
    UpdateDatamodelModalComponent,
    LogoutModalComponent,
    UpdateDocsComponent,
    RenameModalComponent,
    D3BarChartComponent,
    D3BarChartBrushedComponent,
    D3RadarChartComponent,
    D3SunburstComponent,
    D3LineChartComponent,
    D3PieChartComponent,
    D3HeatMapComponent,
    D3SankeyComponent,
    D3HistogramComponent,
    SpinnerBallComponent,
    BreadcrumbComponent,
    ChipsAutocompleteComponent,
    ResponsiveTableComponent,
    ColumnResponsiveTableComponent,
    SelectionViewerComponent,
    TimelineComponent,
    NewCommentComponent,
    ErrorModalComponent,
    SubmitFilterModalComponent,
    DocumentRenderComponent,
    DocumentButtonsComponent,
    AssignReviewerComponent,
    AssignChecklistComponent,
    ValidationOverrideComponent,
    DocumentRelatedTableComponent,
    CreateConsolidatedComponent,
    DocumentEvidencesTableComponent,
    DocumentsTableComponent,
    ValidationsFilterComponent,
    UsersTableComponent,
    ConfirmModalComponent,
    ResponsiveTableModalComponent,
    TriStateCheckboxComponent,
    ClientManagementComponent,
    LogTrackingComponent,
    DocumentCommentsTableComponent,
    UpdateReviewCountdownComponent,
    ReviewTrackingComponent,
    RefreshTokenComponent,
    DocumentThumbnailsComponent,
    DocumentDashboardComponent,
    LifeUnderWritingDashboardComponent,
    LifeUnderWritingPanelComponent,
    DisabilityDashboardComponent,
    DisabilityPanelComponent,
    ConfirmChangeDocumentModalComponent,
    OpenaiInfoComponent,
    CreateQuerysheetComponent,
    QuerysheetCardComponent,
    TreeComponent,
    CreateExplorationsComponent,
    SelectProjectsComponent,
    SelectDocumentsComponent,
    SelectAnalysisComponent,
    CreateExplorationStepComponent,
    DocumentItemsComponent,
    AnalysisFailedDocumentsModalComponent
  ],
  exports: [
    ProjectCardComponent,
    KPIComponent,
    DeleteModalComponent,
    AddCommentValidationComponent,
    EditRoleModalComponent,
    ConfirmModalComponent,
    ExportButtonComponent,
    UserModalComponent,
    UpdateDatamodelModalComponent,
    LogoutModalComponent,
    D3BarChartComponent,
    D3BarChartBrushedComponent,
    D3RadarChartComponent,
    D3SunburstComponent,
    D3LineChartComponent,
    D3PieChartComponent,
    D3HeatMapComponent,
    D3SankeyComponent,
    D3HistogramComponent,
    SpinnerBallComponent,
    BreadcrumbComponent,
    ChipsAutocompleteComponent,
    ResponsiveTableComponent,
    ColumnResponsiveTableComponent,
    SelectionViewerComponent,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    TimelineComponent,
    DocumentRenderComponent,
    DocumentButtonsComponent,
    DocumentRelatedTableComponent,
    ValidationOverrideComponent,
    DocumentEvidencesTableComponent,
    DocumentsTableComponent,
    ValidationsFilterComponent,
    UsersTableComponent,
    TriStateCheckboxComponent,
    LogTrackingComponent,
    NgxJsonViewerModule,
    DocumentCommentsTableComponent,
    ReviewTrackingComponent,
    MatProgressBarModule,
    DocumentThumbnailsComponent,
    DocumentDashboardComponent,
    LifeUnderWritingDashboardComponent,
    LifeUnderWritingPanelComponent,
    DisabilityDashboardComponent,
    DisabilityPanelComponent,
    ConfirmChangeDocumentModalComponent,
    QuerysheetCardComponent,
    TreeComponent
  ],
  entryComponents: [
    ExportButtonComponent,
    DeleteModalComponent,
    AddCommentValidationComponent,
    EditRoleModalComponent,
    ConfirmModalComponent,
    UserModalComponent,
    UpdateDatamodelModalComponent,
    LogoutModalComponent,
    UpdateDocsComponent,
    SpinnerBallComponent,
    BreadcrumbComponent,
    ResponsiveTableComponent,
    ColumnResponsiveTableComponent,
    SelectionViewerComponent,
    TimelineComponent,
    NewCommentComponent,
    ErrorModalComponent,
    SubmitFilterModalComponent,
    AssignReviewerComponent,
    AssignChecklistComponent,
    CreateConsolidatedComponent,
    ResponsiveTableModalComponent,
    TriStateCheckboxComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ComponentWsModule {}
