import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CreateQuerysheetComponent } from '../../../components/modals/create-querysheet/create-querysheet.component';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { LinkService } from '../../../services/link.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';

@Component({
  selector: 'app-querysheet-list-view',
  templateUrl: './querysheet-list-view.component.html',
  styleUrls: ['./querysheet-list-view.component.scss']
})
export class QuerysheetListViewComponent implements OnInit {
  @Input() data;
  @Input() settings;
  @Input() loading;
  @Input() user;
  @Input() qsAllowedRoles;
  @Output() loadingChange = new EventEmitter();
  @Output() getQuerySheetsFromDB = new EventEmitter();
  public columns: Array<any> = [];
  public tableSettings: any;
  public extraColumns: string[] = [];
  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private alertService: AlertService,
    private link: LinkService,
    private translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.getTableSettings();
    this.getColumns();
  }

  public getColumns(): void {
    this.columns = [
      {
        header: 'querysheetList.querysheetNameTableHeader',
        name: 'querySheetName',
        orderBy: 'querySheetName',
        type: 'link',
        clickLink: this.goToQuerysheet.bind(this),
        title: 'querySheetName'
      },
      //  commenting this below line of code Temporary hidding
      //{
      //   header: 'querysheetList.projectTableHeader',
      //   name: 'project'
      // },
      {
        header: 'querysheetList.createdByTableHeader',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'querysheetList.dateTableHeader',
        name: 'createdDate',
        orderBy: 'createdDate',
        type: 'date',
        format: 'lll'
      },
      {
        header: 'querysheetList.queriesTableHeader',
        name: 'querycount'
      },
      {
        header: 'querysheetList.state',
        name: 'isPublishedStatus',
        title: 'isPublishedStatus'
      },
      {
        header: 'querysheetList.processingStatus',
        name: 'status_data',
        type: 'one-span-status',
        orderBy: 'statusid',
        title: 'status_data',
        second: 'value'
      },
    ];
    this.setConditionalColumns();
  }
   /**
   * Function to add additional columns
   * to the table depending on which
   * user roles
   */
   private setConditionalColumns() {
    if(this.qsAllowedRoles.includes(this.user.role.rolename)) {
      const editOption = [
        {
          type: 'link-fa-icon',
          header: ' ',
          link: 'external',
          title: 'responsiveTable.edit',
          clickLink: this.editQuerysheet.bind(this),
          namefunc: rowData => {
            return '<i class="fa fa-pencil-square-o"></i>';
          }
        }
      ]
      this.columns.splice(this.columns.length,0,...editOption);
    }
  }

  public getTableSettings(): void {
    this.tableSettings = {
      dataId: 'querySheetId',
      hasSelect: 'true',
      hasEditPermissions:  this.qsAllowedRoles.includes(this.user.role.rolename) ? true : false,
      getDataFromDB: this.settings['getDataFromDB'],
      hasDownloadOptions: false,
      extraColumns: this.extraColumns,
      actionsOnSelected: [
        {
          click: this.deleteSelectedQuerysheets.bind(this),
          class: 'fa fa-trash',
          title: 'querysheetList.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteQuerysheet.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'querySheetName',
        value: 'createdDate',
        formatDate: 'lll'
      }
    };
  }

  /**
   * Edit querysheet, open up the modal for editing querysheet
   */
  public editQuerysheet(querysheet): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    };
    const modalWindowRef = this.modalService.open(
      CreateQuerysheetComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      querysheet,
      title: this.translate.transform('editQuerysheet.title'),
      action: 'edit'
    };

    modalWindowRef.result.then(result => {
      if (result) {
        this.getQuerySheetsFromDB.emit();
      }
    });
  }

  /**
   * Delete multiple querysheet
   */
  public deleteSelectedQuerysheets(selectedQuerysheets): void {
    this.deleteQuerysheetModal(selectedQuerysheets);
  }

  /**
   * Delete single querysheet
   */
  public deleteQuerysheet(selectedQuerysheet: any): void {
    this.deleteQuerysheetModal([selectedQuerysheet.querySheetId]);
  }

  /**
   * Load modal to confirm project(s) removal
   */
  private deleteQuerysheetModal(querysheetids: number[]) {
    if (querysheetids.length < 1) {
      // Break if array is empty
      return;
    }

    const url = 'querysheets/delete';
    const params = { 'ids': querysheetids};

    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };

    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );

    modalWindowRef.componentInstance.options = {
      type: querysheetids.length > 1 ?  'querySheets' : 'querySheet',
      customMessage: querysheetids.length > 1 ?
                        "querysheetManagement.multiQuerySheetDeleteConfirmation"
                        : "querysheetManagement.querySheetDeleteConfirmation",
      notAllowed: querysheetids.length < 1
    };

    modalWindowRef.result.then(
      result => {
        if (result) {
          this.onLoadingChange(true);
          this.apiService.post(url, params, '').subscribe(
            (data) => {
              // Querysheet/s has been deleted successfully
              this.alertService.success(
                this.translate.transform(
                  'querysheetManagement.querysheetDeletedSuccessfully'
                )
              );

              this.settings['getDataFromDB']();
            }
          );
        }
      }
    );
  }

  /**
   * Go to querysheet
   */
  public goToQuerysheet(querysheet): void {
    if (this.isAccessQuerySheet(querysheet)){
      this.link.goToQuerysheet(querysheet);
    }
  }


  /**
   * Verify QS Access based on status and roles
   */
  public isAccessQuerySheet(querysheet) {
    return (querysheet.querySheetStatus === 'success' || this.qsAllowedRoles.includes(this.user.role.rolename))
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loadingChange.emit(bool);
  }
}
