import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() showFilePreview: boolean = false;
  @Input() explorationQueryData: any = null;
  @Input() selectedResult: any = null;
  @Input() results: any = null;
  @Output() setShowFilePreviewFlag = new EventEmitter();
  @Output() fetchResultsForSelectedQuery = new EventEmitter();
  @Input() selectedQuery:any = null;
  @Input() usecaseLevels: any = null;
  public scale = 0.75;
  public manualFeedbackSelections: any = [];
  public disableDoneButtonOnPreview: boolean = false;
  public pdfContolButtonsDisabled: boolean = false;
  public zoomLevelsDDOptions = ['25', '50', '75', '100', '200', '400', '800', '1600', '3200', '6400']
  public selectedZoomLevel = '100'
  constructor(
    private apiService: ApiService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {}

  closeFilePreview() {
    this.setShowFilePreviewFlag.emit(false);
    this.manualFeedbackSelections = [];
    this.scale = 0.75;
    this.selectedZoomLevel = '100';
    this.selectedResult = null;
  }

  handleClickOutsideFilePreview(e) {
    if (!document.getElementById('filePreviewModal').contains(e.target)) {
      this.closeFilePreview();
    }
  }

  setManualFeedbackSelections(feedbacks) {
    this.manualFeedbackSelections = [...feedbacks]
  }

  saveManualFeedback() {
    let selectedFeedbacks =  [...this.manualFeedbackSelections];
    if (selectedFeedbacks.length > 0) {
      const payload = {
        explorationId: this.explorationQueryData.explorationId,
        queryIds: this.explorationQueryData.queries.map(query => query.queryId),
        chunkId: this.selectedResult.chunkId,
        semanticResultId: this.selectedResult.semanticResultId,
        selections: selectedFeedbacks
      }
    
      // TO DO: Save manual feedbacks to DB and close the preview
      this.apiService.post('feedback/modify', payload, '')
      .subscribe(
        event => {
          // re-fetch the search result and manual feedbacks 
          this.fetchResultsForSelectedQuery.emit();
          this.closeFilePreview();
        },
        error => {
          console.log('error occred', error);
        }
      );
    }
  }

  setDisableDoneButtonOnFilePreview(flagVal: boolean) {
    this.disableDoneButtonOnPreview = flagVal;
  }

  disablePDFPreviewControlButtons(flagVal: boolean) {
    this.pdfContolButtonsDisabled = flagVal;
  }

  public setScale(scale) {
    this.scale = scale;
  }

  public setSelectedZoomLevel(zoomLevel){
    this.selectedZoomLevel = zoomLevel
  }

  public zoomLevelChanged(zoomlevel) {
    if (this.pdfContolButtonsDisabled) {
      return
    }
    this.setSelectedZoomLevel(zoomlevel)
    this.setScale(0.75 * (zoomlevel / 100))
  }

  public changeScale(type){
    if (this.pdfContolButtonsDisabled) {
      return
    }
    /*
        Note:- below is a zoom mapping table (% to decimal value)
        100% => 0.75
        75% => 0.56
        50% => 0.38
        25% => 0.19
        */
    let changeFactor = this.scale >= 0.75 ? 0.25 : 0.19;
    let zoomVal;
    if (type === 'incr'){
      if ((Math.round((this.scale + Number.EPSILON) * 100) / 100) === 0.38){
        //when increasing from 50% to 75%
        changeFactor = 0.185;
      }
      zoomVal = Math.ceil((this.scale + changeFactor) * 133.33);
      this.setSelectedZoomLevel(zoomVal.toString())
      this.setScale(this.scale + changeFactor)
    } else if (type === 'decr' && this.scale > 0.1875){
      if (this.scale === 0.75){
        // this block is to achieve 50% and 75% zoom level
        changeFactor = 0.19;
      } else if ((Math.round((this.scale + Number.EPSILON) * 100) / 100) === 0.37) {
        // this block is to achieve 25% zoom level
        changeFactor = 0.185;
      }
      zoomVal = Math.ceil((this.scale - changeFactor) * 133.33);
      this.setSelectedZoomLevel(zoomVal.toString())
      this.setScale(this.scale - changeFactor)
    }
    if (this.zoomLevelsDDOptions.indexOf(zoomVal.toString()) == -1) {
      this.zoomLevelsDDOptions.push(zoomVal.toString())
      this.zoomLevelsDDOptions = this.zoomLevelsDDOptions.sort((a:any, b:any) => a - b);
    }
  }
}
