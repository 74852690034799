import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { TranslatePipe } from '../../../pipes/translate.pipe';

import { sortResultsBasedOnScore } from '../utils/utils';
import { feedbackValues } from '../utils/constants';

@Component({
  selector: 'app-results-container',
  templateUrl: './results-container.component.html',
  styleUrls: ['./results-container.component.scss']
})
export class ResultsContainerComponent implements OnInit, OnChanges {

  @Input() selectedQuery = null;
  @Input() results: Array<any> = [];
  @Output() setShowFilePreviewFlag = new EventEmitter();
  @Output() setSelectedResult = new EventEmitter();
  @Input() explorationQueryData = null;
  @Input() usecaseLevels: any = null;
  @Input() selectedResult: any = null;
  public level1_1: any = null;
  public level2_1: any = null;
  public robertaManualFeedback: any = {};
  
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private translate: TranslatePipe
  ) { }

  ngOnInit(): void {
    this.level1_1 = this.usecaseLevels.level1_1;
    this.level2_1 = this.usecaseLevels.level1_1?.level2_1;

    for (let queryData of this.results) {
      const useModelResult = queryData?.USEModel?.feedback?.selections || [];
      const robertaModelResult = queryData?.robertaModel?.feedback?.selections || [];

      for (let manualFeedbackEntry of useModelResult) {
        const matchedManualFeedback = robertaModelResult.find(data => (data.parentId === manualFeedbackEntry.id))
        if (matchedManualFeedback) {
          if (manualFeedbackEntry.implicit || (manualFeedbackEntry.implicit == 'True')) {
            queryData['answerFeedbackInAIRsult'] = true
          }
          this.robertaManualFeedback[manualFeedbackEntry.id] = matchedManualFeedback;
        }
      }
    }
  }

  ngOnChanges() { 
    if (this.usecaseLevels) {
      this.level1_1 = this.usecaseLevels.level1_1;
      this.level2_1 = this.usecaseLevels.level1_1?.level2_1;
    }
  }

  getButtonTitle(btnType: string, result: any): string {
    let btnLabel = '';
    const feedbackValue = result[this.level1_1.modelKey]?.feedback?.feedbackValue;
    let isModified = false;
    const level1Feedback = result[this.level1_1.modelKey]?.feedback;
    const level2Feedback = this.level2_1 && result[this.level2_1.modelKey]?.feedback;
    if (btnType == feedbackValues.MODIFY 
      && (
        level1Feedback?.selections.length > 0 
        || level2Feedback?.selections.length > 0
        )
    ) {
      isModified = true;
    }
    switch(btnType) {
      case feedbackValues.ACCEPT: 
        btnLabel = this.translate.transform(feedbackValue == feedbackValues.ACCEPT ? 'explorationDetails.accepted' : 'explorationDetails.accept');
        break;
      
      case feedbackValues.REJECT:
        btnLabel = this.translate.transform(feedbackValue == feedbackValues.REJECT ? 'explorationDetails.rejected' : 'explorationDetails.reject');
        break;
      
      case feedbackValues.MODIFY:
        btnLabel = this.translate.transform(isModified ? 'explorationDetails.modified' : 'explorationDetails.modify');
        break;
    }
    return btnLabel;
  }

  // Create/Update/Delete accepted/rejected feedbacks
  resultAcceptOrRejectClicked(result: any, feedbackValue: string) {
    if (result.USEModel.feedback?.selections.length > 0) {
      return;
    }
    if (!result.feedbackId) {
      const payload = this.getCreateFeedbackPayload({ 
                        feedback: feedbackValue,
                        result,
                        explorationId: this.explorationQueryData.explorationId,
                        queryIds: this.explorationQueryData.queries.map((query: any) => query.queryId)
                      });
      this.postFeedbackData(payload, result?.[this.level1_1.idName]);
    } else if (result[this.level1_1.modelKey]?.feedback?.feedbackValue == feedbackValue) {
        // Unaccepting/Unrejecting given feedback, delete this feedback
        const payload = {
          id: result.feedbackId,
          [this.level1_1.modelKey]: { id: result[this.level1_1.modelKey].feedback._id }
        };

        this.level2_1 && (payload[this.level2_1.modelKey] = { id: result[this.level2_1.modelKey].feedback._id });
        this.deleteFeedback(payload, result?.[this.level1_1.idName]);
    } else {
      // Rejecting/Accepting given feedback, update feedbackValue to reject
      const payload = {
        id: result.feedbackId,
        [this.level1_1.modelKey]: {
          id: result[this.level1_1.modelKey].feedback._id,
          feedbackValue: feedbackValue
        }
      }

      this.level2_1 && (payload[this.level2_1.modelKey] = {
                                        id: result[this.level2_1.modelKey].feedback._id,
                                        feedbackValue: feedbackValue
                                      });
      this.updateFeedback(payload, result?.[this.level1_1.idName]);
    }
  }

  resultModifyClicked(result: any) {    
    this.setSelectedResult.emit(result);
    this.setShowFilePreviewFlag.emit();
  }

  getCreateFeedbackPayload(payload: any) {
    const { feedback = feedbackValues.ACCEPT, result, explorationId, queryIds } = payload;
    const feedbackPayload: any = {
      explorationId,
      queryIds,
      [this.level1_1.modelKey]: {
        queryId: this.explorationQueryData.queries.find((query: any) => query.queryType == this.level1_1.feedbackType)?.queryId,
        chunkId: result.chunkId,
        [this.level1_1.idName]: result[this.level1_1.idName],
        answerId: "None",
        feedbackType: this.level1_1.feedbackType,
        feedbackValue: feedback,
        selections: []
      }
    }

    if (this.level2_1 && result[this.level2_1.modelKey]) {
      feedbackPayload[this.level2_1.modelKey] = {
        queryId: this.explorationQueryData.queries.find((query: any) => query.queryType == this.level2_1.feedbackType)?.queryId,
        chunkId: result.chunkId,
        [this.level1_1.idName]: result[this.level1_1.idName],
        [this.level2_1.idName]: result[this.level2_1.modelKey][this.level2_1.idName],
        feedbackType: this.level2_1.feedbackType,
        feedbackValue: feedback,
        selections: []
      }
    }

    return feedbackPayload;
  }

  // Create a new feedback record
  postFeedbackData(payload: any, resultId) {
    this.apiService.post('feedback', payload, '')
    .subscribe(
      event => {
        this.alertService.success(this.translate.transform('explorationDetails.feedbackSaveSuccess'));
        this.results.forEach((result) => {
          if (result[this.level1_1.idName] == resultId) {
            const score = payload[this.level1_1.modelKey].feedbackValue == feedbackValues.ACCEPT ? 100 : 0
            result.feedbackId = event.id;
            result[this.level1_1.modelKey].feedback = { _id: event[this.level1_1.modelKey].id, ...payload[this.level1_1.modelKey], updatedScore: score, modifiedDate: new Date() };
            (this.level2_1 && result[this.level2_1.modelKey]) && (result[this.level2_1.modelKey].feedback = { _id: event[this.level2_1.modelKey].id, ...payload[this.level2_1.modelKey], updatedScore: score, modifiedDate: new Date() });
          }
        });
        sortResultsBasedOnScore(this.results, this.level1_1.modelKey, this.level1_1.resultScoreKey);
      },
      error => {
        this.alertService.error(this.translate.transform('explorationDetails.feedbackSaveError'));
      }
    )
  }

  //Update a feedback record
  updateFeedback(payload: any, resultId) {
    this.apiService.post('feedback/update', payload, '')
    .subscribe(
      event => {
        this.alertService.success(this.translate.transform('explorationDetails.feedbackUpdateSuccess'));
        // Once feedback deleupdated successfully, update it in the result object as well
        this.results.forEach(result => {
          if (result[this.level1_1.idName] == resultId) {
            const score = payload[this.level1_1.modelKey].feedbackValue == feedbackValues.ACCEPT ? 100 : 0
            result[this.level1_1.modelKey].feedback = { ...result[this.level1_1.modelKey].feedback, updatedScore: score, feedbackValue: payload[this.level1_1.modelKey].feedbackValue, modifiedDate: new Date() };
            (this.level2_1 && result[this.level2_1.modelKey]) && (result[this.level2_1.modelKey].feedback = { ...result[this.level2_1.modelKey].feedback, updatedScore: score, feedbackValue: payload[this.level2_1.modelKey].feedbackValue, modifiedDate: new Date() });
          }
        });
        sortResultsBasedOnScore(this.results, this.level1_1.modelKey, this.level1_1.resultScoreKey);
      },
      error => {
        this.alertService.error(this.translate.transform('explorationDetails.feedbackUpdateError'));
      } 
    )
  }

  //Delete a feedback record
  deleteFeedback(payload: any, resultId) {
    this.apiService.post('feedback/delete', payload, '')
    .subscribe(
      event => {
        this.alertService.success(this.translate.transform('explorationDetails.feedbackDeleteSuccess'));
        // Once feedback deleted successfully, remove it from the result object as well
        this.results.forEach(result => {
          if (result[this.level1_1.idName] == resultId) {
            delete result.feedbackId;
            delete result[this.level1_1.modelKey].feedback;
            this.level2_1 && delete result[this.level2_1.modelKey].feedback;
          }
        });
        sortResultsBasedOnScore(this.results, this.level1_1.modelKey, this.level1_1.resultScoreKey);
      },
      error => {
        this.alertService.error(this.translate.transform('explorationDetails.feedbackDeleteError'));
      }
    )
  }
}
