import { OPEN_SQUARE_BRACKET } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { log } from 'console';
import { Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DocumentThumbnailsProvider } from '../document-thumbnails/document-thumbnails-provider';

@Injectable({
  providedIn: 'root'
})

/**
 * Handle document render component visualization
 */
export class DocumentRenderService {
  // Status observables
  private isSelectingChunkSub = new Subject<any>();
  private selectedChunkSub = new Subject<any>();
  private isLoadingChunksSub = new Subject<any>();
  private isSearchingChunksSub = new Subject<any>();
  private isLoadingImagesSub = new Subject<any>();
  private isTryingToLoadImagesSub = new Subject<any>();

  // DocumentId data
  private documentId: number;
  private mainDocumentId: number;
  private currentRenderedDocumentId: number;

  // Pages data and observables
  private pages: number = 1;
  private pagesSub = new Subject<any>();
  private currentPage: number = 1;
  private currentPageSub = new Subject<any>();
  private pageNumbers: number[];

  // Thumbnails provider and observables
  private thumbnailsProvider: DocumentThumbnailsProvider;
  private thumbnailsProviderSub = new Subject<DocumentThumbnailsProvider>();

  // Images data and observables
  private images: any = {};
  private imagesSub = new Subject<any>();
  private noImage: boolean = true;
  private triedToLoadImages: boolean = false;

  // Coordinates data and observables
  private coordinates: Array<any> = [];
  private coordinatesSub = new Subject<any>();

  // Chunks data and observables
  private chunks: any = {};
  private chunksSub = new Subject<any>();
  private activeChunks: Array<number> = [];
  private activeChunksSub = new Subject<any>();
  private markedChunks: Array<any> = [];
  private markedChunksDocs: any = {};
  private markedChunksSub = new Subject<any>();
  private markedChunksDocsSub = new Subject<any>();
  private noChunks: boolean = true;

  // Answer from OpenAI
  private openaiAnswerSub = new Subject<any>();

  // Warnings from OpenAI
  private openaiWarningsSub = new Subject<any>();

  // Scroll data and observables
  private imageWidth: number = 0;
  private containerWidth: number = 0;
  private scrollPositionSub = new Subject<any>();

  // Full screen mode
  private isFullScreenActive: boolean = false;
  private fullScreenSub = new Subject<any>();

  private searchSub = new Subject<any>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.setChunksCheck(false);
  }

  /**
   * Trigger thumbnails provider subscription
   */
  public triggerThumbnailsProvider() {
    this.thumbnailsProviderSub.next(this.thumbnailsProvider);
  }

  /**
   * Trigger images subscription
   */
  public triggerImages() {
    this.imagesSub.next({ images: this.images });
  }

  /**
   * Trigger chunks subscription
   */
  public triggerChunks() {
    this.chunksSub.next({ chunks: this.chunks });
  }

  /**
   * Trigger pages subscription
   */
  public triggerPages() {
    this.pagesSub.next({ pages: this.pages });
  }

  /**
   * Trigger current page subscription
   */
  public triggerCurrentPage() {
    this.currentPageSub.next({ pagenum: this.currentPage });
  }

  /**
   * Trigger all required subscription when component
   * is reloaded in a different tab (so you want to mantaining
   * previus loaded data)
   */
  public triggerAll() {
    this.triggerThumbnailsProvider();
    this.triggerImages();
    this.triggerPages();
    this.triggerCurrentPage();
    this.isLoadingChunksSub.next({ state: false });
    this.isSearchingChunksSub.next({ state: false });
    this.isLoadingImagesSub.next({ state: false });
    if (this.triedToLoadImages) {
      this.isTryingToLoadImagesSub.next({ state: false });
    }
    if (
      this.chunks[this.currentPage] &&
      this.chunks[this.currentPage].length === 0
    ) {
      this.setChunksCheck(true);
    }
  }

  /**
   * Empty stored images and trigger subscription
   */
  public emptyImages() {
    this.images = {};
    this.triedToLoadImages = false;
    this.triggerImages();
  }

  /**
   * Empty stored chunks and trigger subscription
   */
  public emptyChunks() {
    this.chunks = {};
    this.emptyHighlightedChunks();
  }

  /**
   * Empty highlighted chunks and trigger subscription
   */
  public emptyHighlightedChunks() {
    this.emptyActiveChunks();
    this.emptyMarkedChunks();
  }

  /**
   * Empty stored pages and trigger subscription
   */
  public emptyPages() {
    this.pages = 1;
    this.currentPage = 1;
    this.triggerPages();
    this.triggerCurrentPage();
  }

  /**
   * Empty active chunks and trigger subscription
   */
  public emptyActiveChunks() {
    this.activeChunks = [];
    this.activeChunksSub.next({ chunks: [] });
  }

  /**
   * Empty marked chunks and trigger subscription
   */
  public emptyMarkedChunks() {
    if (this.chunks[this.currentPage]) {
      this.chunks[this.currentPage].forEach(chunk => {
        this.hideSingleChunk(chunk);
      });
      this.updateDocumentChunks(
        this.chunks[this.currentPage].map(c => ({ ...c, fromExtraction: true }))
      );
    }
    this.clearMarkedChunks();
  }

  public clearMarkedChunks() {
    this.markedChunks = [];
    this.markedChunksDocs = {};
    this.markedChunksSub.next({ chunks: [] });
    this.markedChunksDocsSub.next({ chunks: {} });
  }
  /**
   * Empty all stored data and trigger subscriptions
   * Used when the component is reloaded with a different
   * data (p.e. you are changing documents)
   */
  public emptyAll() {
    this.documentId = undefined;
    this.emptyImages();
    this.emptyChunks();
    this.emptyPages();
    this.setScroll(0);
    this.emitSearch();
    this.setSelectedChunk({});
  }

  /**
   * Set current page
   * @param pagenum page number to set
   */
  public setCurrentPage(pagenum: number) {
    this.currentPage = pagenum;
    this.triggerCurrentPage();
  }

  /**
   * Set page images from blob
   * @param blobData image blob data
   * @param page image page
   */
  public setImagesFromBlob(blobData: any, page: number) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.images[page] = reader.result;
        this.getImageActualWidth(reader.result);
        this.triggerImages();
        this.setImageCheck(false);
        this.isTryingToLoadImagesSub.next({ state: false });
      },
      false
    );
    reader.readAsDataURL(blobData);
  }

  /**
   * Get image width to calcule aspect ratio
   * (Temporal until its passed througth endpoint)
   * @param readerResult blob reader result
   */
  public getImageActualWidth(readerResult) {
    const image = new Image();
    image.src = readerResult;
    image.onload = () => {
      this.imageWidth = image.width;
    };
  }

  /**
   * Set document id
   * @param id document id
   */
  public setDocumentId(id: number) {
    this.documentId = +id;
  }

  /**
   * Get document image id
   */
  public getDocumentId() {
    return +this.documentId;
  }

  /**
   * Set main document id
   * @param id document id
   */
  public setMainDocumentId(id: number) {
    this.mainDocumentId = +id;
  }

  /**
   * Get main document image id
   */
  public getMainDocumentId() {
    return +this.mainDocumentId;
  }

  /**
   * Set document chunks
   * @param chunks chunks to store (can be from multiple pages)
   */
  public setChunks(chunks: Array<any>) {
    const chunksPageGroups = chunks.reduce((rv, x) => {
      (rv[x.pagenum] = rv[x.pagenum] || []).push(x);
      return rv;
    }, {});

    Object.keys(chunksPageGroups).forEach(page => {
      this.setPageChunks(chunksPageGroups[page], +page);
    });
  }

  /**
   * Set document chunks by page (preventing duplicates)
   * @param chunks chunks to store
   * @param page chunks corresponding page
   */
  public setPageChunks(chunks: Array<any>, page: number) {
    const existingChunksIds = this.chunks.hasOwnProperty(page)
      ? this.chunks[page].map(c => c.chunkid)
      : [];
    const newChunks = this.mapChunksPosition(chunks).filter(
      c => existingChunksIds.indexOf(c.chunkid) === -1
    );
    this.chunks[page] = this.chunks.hasOwnProperty(page)
      ? this.chunks[page].concat(newChunks)
      : newChunks;
  }

  /**
   * Set document chunks (preventing duplicates)
   * @param chunks chunks to store
   */
  public updateDocumentChunks(chunks: Array<any>) {
    const currentChunksIds = Object.keys(this.chunks).reduce((acc, key) => {
      return acc.concat(this.chunks[key].map(c => c.chunkid));
    }, []);

    this.mapChunksPosition(chunks).forEach(chunk => {
      const chunkIdx = currentChunksIds.indexOf(chunk.chunkid);
      if (chunkIdx === -1) {
        // Chunk is new -> add to this.chunks
        if (this.chunks.hasOwnProperty(chunk.pagenumber)) {
          this.chunks[chunk.pagenumber].push(chunk);
        } else {
          this.chunks[chunk.pagenumber] = [chunk];
        }
      } else {
        // Chunk already exists -> update chunk
        const prevIdx = this.chunks[chunk.pagenumber].findIndex(
          c => c.chunkid === chunk.chunkid
        );
        this.chunks[chunk.pagenumber][prevIdx] = chunk;
      }
    });
  }

  public emitSearch() {
    this.searchSub.next();
  }

  /**
   * Set pages coordinates
   * @param pagesCoords page coordinates
   */
  public setCoordinates(pagesCoords: Array<any>) {
    this.coordinates = pagesCoords;
  }

  /**
   * Set pages
   * @param pages document total pages
   */
  public setPages(pages: number) {
    this.pages = pages;
    this.triggerPages();
  }

  /**
   * Set page numbers.
   * @param pageNumbers page numbers of pages in document.
   * If undefined, page numbers are assumed to be
   * 1 - num. pages.
   */
  public setPageNumbers(pageNumbers?: number[]) {
    this.pageNumbers = pageNumbers;
  }

  /**
   * Scroll image container to desired position
   * @param height container height (in pixels) to scroll
   */
  public setScroll(height: number) {
    const scrollTopPadding = 100;
    const finalScroll =
      height - scrollTopPadding >= 0 ? height - scrollTopPadding : 0;
    this.scrollPositionSub.next({ height: finalScroll });
  }

  /**
   * Scroll image container to desired position
   * @param height image height (in pixels) to scroll (it's adapted to image/container ascpect ratio)
   */
  public setScrollWithRatio(height: number, page: number = this.currentPage) {
    if (page === this.currentPage) {
      const actualHeight =
        this.containerWidth && this.imageWidth
          ? height * (this.containerWidth / this.imageWidth)
          : 0;
      this.setScroll(actualHeight);
    }
  }

  /**
   * Scroll image container to desired page
   * @param pagenum page number to scroll
   */
  public setScrollPage(pagenum: number) {
    const page = pagenum - 1 >= 0 ? pagenum - 1 : 0;
    this.setScrollWithRatio(this.coordinates[page].top_left[1]);
  }

  /**
   * Set image container actual size
   * @param width container width
   */
  public setContainerWidth(width: number) {
    this.containerWidth = width;
  }

  /**
   * Set full screen state
   * @param isFullScreen full screen state
   */
  public setFullScreen(isFullScreen: boolean) {
    this.isFullScreenActive = isFullScreen;
    this.fullScreenSub.next({ state: isFullScreen });
  }

  /**
   * Return full screen observable
   */
  public getFullScreen(): Observable<any> {
    return this.fullScreenSub.asObservable();
  }

  /**
   * Set active chunks
   * @param chunks chunk to set as active
   */
  public setActiveChunks(chunks: Array<any>) {
    this.activeChunks = chunks;
    this.activeChunksSub.next({ chunks });
  }

  /**
   * Set selecting chunk state
   * @param state selecting chunk state
   */
  public setIsSelectingChunk(state: boolean) {
    this.isSelectingChunkSub.next({ state: state });
  }

  /**
   * Set image container actual size
   * @param chunk chunk being selected
   */
  public setSelectedChunk(chunk: any) {
    this.selectedChunkSub.next({ chunk: chunk });
    this.setIsSelectingChunk(false);
    this.disableSelectingChunkMode();
  }

  /**
   * Return current page number
   */
  public watchSearch(): Observable<any> {
    return this.searchSub.asObservable();
  }

  /**
   * Return current page number
   */
  public getCurrentPage(): Observable<any> {
    return this.currentPageSub.asObservable();
  }

  /**
   * Return pages coordinates observable
   */
  public getCoordinates(): Observable<any> {
    return this.coordinatesSub.asObservable();
  }

  /**
   * Return pages number observable
   */
  public getPages(): Observable<any> {
    return this.pagesSub.asObservable();
  }

  /**
   * Return images container scroll position observable
   */
  public getScroll(): Observable<any> {
    return this.scrollPositionSub.asObservable();
  }

  /**
   * Return document images
   */
  public getImages(): Observable<any> {
    return this.imagesSub.asObservable();
  }


  /**
   * Return document thumbnails provider.
   */
  public getThumbnailsProvider(): Observable<any> {
    return this.thumbnailsProviderSub.asObservable();
  }

  /**
   * Return observer to watch if component is loading chunks
   */
  public getIsLoadingChunks(): Observable<any> {
    return this.isLoadingChunksSub.asObservable();
  }

  /**
   * Return observer to watch if component is loading images
   */
  public getIsLoadingImages(): Observable<any> {
    return this.isLoadingImagesSub.asObservable();
  }

  /**
   * Return observer to watch if component is trying to load images
   */
  public getIsTryingToLoadImages(): Observable<any> {
    return this.isTryingToLoadImagesSub.asObservable();
  }

  /**
   * Return observer to watch if component is searching
   * (waiting a request response)
   */
  public getIsSearchingChunks(): Observable<any> {
    return this.isSearchingChunksSub.asObservable();
  }

  /**
   * Return marked chunks
   */
  public getMarkedChunks(): Observable<any> {
    return this.markedChunksSub.asObservable();
  }

  /**
   * Return marked chunks by document
   */
  public getMarkedChunksDocs(): Observable<any> {
    return this.markedChunksDocsSub.asObservable();
  }

  /**
   * Return document images
   */
  public chunksWatcher(): Observable<any> {
    return this.chunksSub.asObservable();
  }

  /**
   * Return document chunks
   */
  public getChunks(): Observable<any> {
    return this.chunks;
  }

  /**
   * Return document chunks
   */
  public getActiveChunks(): Observable<any> {
    return this.activeChunksSub;
  }

  /**
   * Return selecting chunk state
   */
  public getIsSelectingChunk(): Observable<any> {
    return this.isSelectingChunkSub.asObservable();
  }

  /**
   * Return selecting chunk state
   */
  public getSelectedChunk(): Observable<any> {
    return this.selectedChunkSub.asObservable();
  }

  public getOpenaiAnswer(): Observable<any> {
    return this.openaiAnswerSub.asObservable();
  }

  public getOpenaiWarnings(): Observable<any> {
    return this.openaiWarningsSub.asObservable();
  }

  /**
   * Map chunks position to actual position
   * @param chunks chunks to parse position
   * @param padding highlight box padding space
   */
  public mapChunksPosition(chunks: Array<any>, padding: number = 1) {
    // TODO: Handle different pages height
    return chunks.map(chunk => ({
      ...chunk,
      xposabs: chunk.ypos, // TODO: Add page height
      top: `calc(${chunk.yposperc * 100}% - ${padding}px)`, // TODO: Add page height
      left: `calc(${chunk.xposperc * 100}% - ${padding}px)`,
      height: `calc(${chunk.heightperc * 100}% + ${padding * 2}px)`,
      width: `calc(${chunk.widthperc * 100}% + ${padding * 2}px)`
    }));
  }

  /**
   * Show chunks (multiple chunks)
   * @param chunkIds chunks ids to show
   */
  public showChunks(chunksIds: Array<number>) {
    this.setActiveChunks(chunksIds);
  }

  /**
   * Show chunk and hide other chunks
   * @param chunkId chunk id to show
   */
  public showSingleChunk(chunkId: number) {
    this.setActiveChunks([chunkId]);
  }

  /**
   * Show all chunks from the given page
   * @param page page to show its chunks
   */
  public showAllPageChunks(page: number = this.currentPage) {
    this.setActiveChunks(this.chunks[page].map(d => d.chunkid));
  }

  /**
   * Show all chunks
   */
  public showAllChunks() {
    let activeChunks = [];
    Object.keys(this.chunks).forEach(page => {
      activeChunks = activeChunks.concat(this.chunks[page].map(d => d.chunkid));
    });
    this.setActiveChunks(activeChunks);
  }

  /**
   * Hide selected chunk
   * @param chunk chunk to hide
   */
  public hideSingleChunk(chunk) {
    const idx = this.activeChunks.indexOf(chunk.chunkid);
    if (idx > -1) {
      const chunks = [...this.activeChunks];
      chunks.splice(idx, 1);
      this.setActiveChunks(chunks);
    }
  }

  /**
   * Hide all chunks
   */
  public hideAllChunks() {
    if (typeof this.chunks[this.currentPage] !== 'undefined') {
      this.updateDocumentChunks(
        this.chunks[this.currentPage].map(c => ({ ...c, fromExtraction: true }))
      );
    }
    this.emptyActiveChunks();
  }

  /**
   * Enable selecting chunk mode
   */
  public enableSelectingChunkMode() {
    this.setIsSelectingChunk(true);
    this.showAllPageChunks();
  }

  /**
   * Enable selecting chunk mode
   */
  public disableSelectingChunkMode() {
    this.setIsSelectingChunk(false);
    this.hideAllChunks();
  }

  /**
   * Get active chunks for the given page
   * @param pagenum page number
   */
  public getActiveChunksOnPage(pagenum: number) {
    return this.chunks.hasOwnProperty(pagenum)
      ? this.chunks[pagenum].filter(
        c => this.activeChunks.indexOf(c.chunkid) > -1
      )
      : [];
  }

  /**
   * Check if chunk is active
   * @param chunk chunk to check if is active
   */
  public isChunkActive(chunk) {
    return this.currentPage !== chunk.pagenumber
      ? false
      : this.activeChunks.indexOf(chunk.chunkid) > -1;
  }

  /**
   * Update document thumbnails provider.
   *
   * Start loading thumbnails.
   */
  public updateStartDocumentThumbnailsProvider(
    noThumbnailsIfOnePage: boolean = true
  ): void {
    if (!this.thumbnailsProvider
      || this.thumbnailsProvider.documentId != this.documentId) {

      if (this.thumbnailsProvider) {
        this.thumbnailsProvider.cancelLoading();
      }

      if (noThumbnailsIfOnePage && this.pages <= 1) {
        this.thumbnailsProvider = null;

      } else {
        this.thumbnailsProvider =
          new DocumentThumbnailsProvider(
            this.documentId,
            this.pages,
            this.apiService,
            this.pageNumbers
          );

        this.thumbnailsProvider.loadThumbnails();
      }
    }

    this.triggerThumbnailsProvider();
  }

  /**
   * Get document picture from database
   * @param page page number to load
   * If the requested image is from the main document
   * and is already set in image[page], It doesn't call to the api.
   * If image[page] is not set, calls to the api.
   * If the required image is from different document, calls to the api.
   */
  public getDocumentImagesFromDB(page: number = 1) {
    if (!this.images[page]) {
      this.isTryingToLoadImagesSub.next({ state: true });
    }

    return new Promise((resolve, reject) => {
      if (
        !this.currentRenderedDocumentId ||
        this.currentRenderedDocumentId !== this.documentId ||
        (this.currentRenderedDocumentId && !this.images[page])
      ) {
        this.isLoadingImagesSub.next({ state: true });
        this.apiService
          .get(
            `docpictures/${this.documentId}/`,
            { page },
            '',
            false,
            {
              responseType: 'blob'
            },
            '',
            false
          )
          .subscribe(
            data => {
              this.isLoadingImagesSub.next({ state: false });
              this.currentRenderedDocumentId = this.documentId;
              this.setImagesFromBlob(data, page);
              this.triedToLoadImages = true;
              resolve(this.images);
            },
            () => {
              this.setImageCheck(true);
              this.isLoadingImagesSub.next({ state: false });
              this.isTryingToLoadImagesSub.next({ state: false });
              this.triedToLoadImages = true;
              reject();
            }
          );
      } else {
        reject();
      }
    });
  }

  /**
   * Get if have image
   */
  public getImageCheck() {
    return this.noImage;
  }

  /**
   * Get if have chuncks
   */
  public setChunksCheck(value) {
    return (this.noChunks = value);
  }

  /**
   * Get if have image
   */
  public setImageCheck(value) {
    return (this.noImage = value);
  }

  /**
   * Get if have chunks
   */
  public getChunksCheck() {
    return this.noChunks;
  }

  /**
   * Get document chunks from database (by page)
   * @param page page number to load
   */
  public getDocumentChunksFromDB(page: number = 1) {
    this.isLoadingChunksSub.next({ state: true });
    this.apiService
      .get(
        `docchunks/`,
        { page, documentid: this.documentId },
        '',
        true,
        {},
        '',
        false
      )
      .subscribe(
        response => {
          console.log(response.data.chunks)
          this.setPageChunks(response.data.chunks, page);
          this.setChunksCheck(
            response.data.chunks === undefined ? true : false
          );
          this.chunksSub.next();
          this.isLoadingChunksSub.next({ state: false });
          if (this.getActiveChunksOnPage(page).length) {
            // If there are active chunks in this page, scroll to the first one
            this.setScrollWithRatio(
              this.getActiveChunksOnPage(page)[0].xposabs
            );
          }
          this.setChunksCheck(false);
        },
        () => {
          this.setChunksCheck(true);
          this.chunksSub.next();
          this.isLoadingChunksSub.next({ state: false });
        }
      );
  }

  /**
   * Search chunks from condition and show them, and
   * if chunks belongs to a different page, go to it
   * @param conditionid validation execution condition id
   */
  public getConditionChunksFromDB(conditionid: number) {
    this.hideAllChunks();
    this.isSearchingChunksSub.next({ state: true });
    this.apiService
      .get('docchunks/', { validationexecutionconditionsid: conditionid }, '')
      .subscribe(response => {
        const chunks = {};
        chunks[this.documentId] = response.data.chunks;
        this.markChunksFromMultipleDocuments(chunks);
        this.goToFirstChunksPage(response.data.chunks);
        this.isSearchingChunksSub.next({ state: false });
      });
  }

  // private  mockAnswer = {
  //   answer: "lorem ipsum dolor sit amet, consectetur adipiscingfe,lorem ipsum dolor sit amet, consectetur adipiscingfe,lorem ipsum dolor sit amet, consectetur adipiscingfe,vlorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfelorem ipsum dolor sit amet, consectetur adipiscingfe",
  //   page: 2,
  //   chunk_id: "0",
  //   confidence: [123.33, 12312.3312, 0.54]
  // }

  /**
   * Search chunks from document and show them, and
   * if chunks belongs to a different page, go to it
   * @param text text to search
   */
  public searchChunks(text: string, openai: boolean) {
    let url = openai ? "searchopenai/" : "searchdocument/"
    this.hideAllChunks();
    this.isSearchingChunksSub.next({ state: true });
    this.apiService
      .get(url, { documentid: this.documentId, search: text }, '')
      .subscribe(response => {
        if (openai) {
          this.showOpenaiAnswer(response.answer);
          this.checkOpenaiWarnings(response.confidence);
          this.isSearchingChunksSub.next({ state: false });
          this.goToPage(response.page);
          return;
        }
        const chunks = {};
        this.emptyHighlightedChunks();
        chunks[this.documentId] = response.findChunks;
        this.markChunksFromMultipleDocuments(chunks);
        this.goToFirstChunksPage(response.findChunks);
        this.isSearchingChunksSub.next({ state: false });
      });
  }

  /**
   * Remove duplicate chunks from chunks array
   * @param chunks chunks array to filter
   */
  public getUniqueChunks(chunks: []) {
    const uniqueChunks = [];
    const map = new Map();
    for (let i = 0; i < chunks.length; i += 1) {
      if (!map.has(chunks[i]['chunkid'])) {
        map.set(chunks[i]['chunkid'], true);
        uniqueChunks.push(chunks[i]);
      }
    }
    return uniqueChunks;
  }

  /**
   * Mark chunks to navigate trought them
   * (excluding repeated chunkids)
   * @param chunks chunks objects array to mark
   */
  public markDocumentChunks(chunks: []) {
    const uniqueChunks = this.getUniqueChunks(chunks);
    this.markedChunks = this.mapChunksPosition(uniqueChunks);
    this.showChunks(uniqueChunks.map(chunk => chunk.chunkid));
  }

  /**
   * Mark chunks from multiple documents to navigate trought them
   * @param multiDocChunks chunks by document objects to mark
   */
  public markChunksFromMultipleDocuments(multiDocChunks: {}) {
    this.markedChunksDocs = multiDocChunks;
    this.markedChunksDocsSub.next({ chunks: multiDocChunks });
    if (multiDocChunks.hasOwnProperty(this.documentId)) {
      this.markedChunksSub.next({ chunks: multiDocChunks[this.documentId] });
      this.markDocumentChunks(multiDocChunks[this.documentId]);
    } else {
      this.markedChunksSub.next({ chunks: [] });
      this.markDocumentChunks([]);
    }
  }

  /**
   * Navigate to the first page on the chunks array
   * @param chunks chunks objects array
   */
  public goToFirstChunksPage(chunks: []) {
    const matchedPages = chunks
      .map((c: any) => c.pagenumber)
      .sort((a, b) => a - b);
    this.goToPage(matchedPages[0]);
  }

  public showOpenaiAnswer(answer: String) {
    this.openaiAnswerSub.next({answer})
  }

  public checkOpenaiWarnings(confidence: Array<number>) {
    this.openaiWarningsSub.next({ confidence });
  }

  /**
   * Actions chain to navigate a new page
   * @param page page to navigate
   */
  public goToPage(page: number) {
    if (!page || page < 1 || page > this.pages || page === this.currentPage) {
      return;
    }
    this.setCurrentPage(page);

    // Load image if isn't loaded
    if (!this.images.hasOwnProperty(page)) {
      this.getDocumentImagesFromDB(page);
    }
    // Load chunks if aren't loaded
    if (!this.chunks[page]) {
      this.getDocumentChunksFromDB(page);
    }
  }

  /**
   * Get current active found chunk
   */
  public getMarkedChunkIndex() {
    return this.markedChunks.map(c => c.chunkid).indexOf(this.activeChunks[0]);
  }

  /**
   * Get next active found chunk
   * @param currentChunkIdx current chunk index in activeChunks array
   */
  public getNextMarkedChunk(currentChunkIdx: number) {
    if (this.activeChunks.length === 1) {
      return currentChunkIdx + 1 < this.markedChunks.length
        ? this.markedChunks[currentChunkIdx + 1]
        : this.markedChunks[0];
    }
    return this.markedChunks[0];
  }

  /**
   * Get previus active found chunk
   * @param currentChunkIdx current chunk index in activeChunks array
   */
  public getPrevMarkedChunk(currentChunkIdx: number) {
    if (this.activeChunks.length === 1) {
      return currentChunkIdx - 1 >= 0
        ? this.markedChunks[currentChunkIdx - 1]
        : this.markedChunks[this.markedChunks.length - 1];
    }
    return this.markedChunks[0];
  }

  /**
   * Show next or previus found chunk
   * @param asc next (true) or previus (false) chunk to show
   */
  public navigateMarkedChunks(asc: boolean = true) {
    const currentChunkIdx = this.getMarkedChunkIndex();
    const chunkToShow = asc
      ? this.getNextMarkedChunk(currentChunkIdx)
      : this.getPrevMarkedChunk(currentChunkIdx);
    this.hideAllChunks();
    if (chunkToShow.pagenumber !== this.currentPage) {
      this.goToPage(chunkToShow.pagenumber);
    }
    this.updateDocumentChunks(
      [chunkToShow].map(c => ({ ...c, fromExtraction: false }))
    );
    this.showSingleChunk(chunkToShow.chunkid);
    this.setScrollWithRatio(chunkToShow.xposabs, chunkToShow.pagenumber);
  }

  private groupChunksByDoc(chunks) {
    const chunksByDocuments = chunks.reduce((acc, o) => {
      if (acc.hasOwnProperty(o.documentid)) {
        acc[o.documentid].push(o);
      } else {
        acc[o.documentid] = [o];
      }
      return acc;
    }, {});
    return chunksByDocuments;
  }

  private getCurrentDocumentChunks(chunks, documentid) {
    const currentDocChunks = chunks.hasOwnProperty(documentid)
      ? chunks[`${documentid}`].sort((a, b) => a.ypos - b.ypos)
      : [];
    return currentDocChunks;
  }

  public goToMainDoc(valExeCondition, documentid) {
    const chunksByDocuments = this.groupChunksByDoc(valExeCondition.chunks);
    const currentDocChunks = this.getCurrentDocumentChunks(
      chunksByDocuments,
      documentid
    );
    const pagenum = currentDocChunks
      .map((c: any) => c.pagenumber)
      .sort((a, b) => a - b)[0];
    this.setDocumentId(+valExeCondition.documentId);
    this.getDocumentImagesFromDB(pagenum).then(
      () => {
        this.cleanNextDocHighlights(currentDocChunks);
      },
      error => {
        this.cleanNextDocHighlights(currentDocChunks);
      }
    );
  }

  private cleanNextDocHighlights(currentDocChunks) {
    this.updateDocumentChunks(currentDocChunks);
    this.goToFirstChunksPage(currentDocChunks);
    this.emptyHighlightedChunks();
    this.hideAllChunks();
  }
}
