import {
  Component,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { Reason } from 'src/app/models/close-reason.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { AlertService } from 'src/app/services/alert.service';
import { NgForm } from '@angular/forms';
/**
 * Enrichment upload modal
 */
@Component({
  selector: 'app-rename-modal',
  templateUrl: './rename-modal.component.html',
  styleUrls: ['./rename-modal.component.scss']
})
export class RenameModalComponent implements OnInit {
  public activeModal: any;
  public reason = Reason;
  public apiService: any;
  private translate: any;
  private alertService: any;
  private updatedName: string;
  data: any;
  private payload: any;
  errorMessage: string = "";
  sendingUpdate: boolean;
  sendButtonActive: boolean = false;

  constructor(
    activeModal: NgbActiveModal,
    apiService: ApiService,
    translate: TranslatePipe,
    alertService: AlertService,
  ) {
    this.activeModal = activeModal;
    this.apiService = apiService;
    this.translate = translate;
    this.alertService = alertService;
  }
  ngOnInit(): void {
    this.sendingUpdate = false;
  }
  /**
   *
   *On change handler
  */
  public onChange(e: any): void {
    if (e.target.value.length > 0) {
      this.sendButtonActive = true;
    } else {
      this.sendButtonActive = false;
    }
  }
  /**
   *
   *On SendData handler
  */
  public sendData(form: NgForm) {
    this.updatedName = form.value.updatedName;
    const useRegex = this.updatedName.match(/[^a-zA-Z0-9 -]/g)
    // check if an unwnated character is in the input field, return an error if true
    if (useRegex !== null) {
      this.errorMessage = `Invalid Characters  ${useRegex.toString().replace(/,/g, '')}`;
    } else {
      this.updatedName = this.updatedName.replace(/[^a-zA-Z0-9 -]/g, "");
      if (this.data.currentName.trim() == this.updatedName.trim()) {
        this.errorMessage = `Please use a different name`;
      } else {
        this.errorMessage = '';
        this.payload = {
          projectname: this.updatedName,
          analysisname: this.updatedName,
          projectid: this.data.projectid
        };
        this.sendingUpdate = true;
        this.apiService.put(this.data.endpoint, '', JSON.stringify(this.payload), false)
          .subscribe(
            (response: any) => {
              this.alertService.success(
                this.translate.transform('Successully  Updated'),
                false,
              );
              this.data.headTag.innerText = this.payload.projectname;
              this.activeModal.close(this.reason)
              this.sendingUpdate = false;
            },
            (error: any) => {
              this.alertService.error(
                this.translate.transform('Update Failed'),
                false
              );
              this.sendingUpdate = false;
              this.activeModal.close(this.reason)
            }
          );
      }


    }
  }

  /**
    *
    *On SendData project handler
   */
  public closeModal(): void {
    this.data.headTag.innerText = this.data.currentName;
    this.activeModal.close(this.reason)
  }
}


